import { recruiterNewsTypes } from "../../ActionTypes/recruiterNewsTypes";
import { FetchRecruiterNewsFailure, FetchRecruiterNewsFailurePayload, FetchRecruiterNewsRequest, FetchRecruiterNewsSuccess, FetchRecruiterNewsSuccessPayload } from "../../types/type";


export const fetchRecruiterNewsRequest = (url: any): FetchRecruiterNewsRequest => ({
  type: recruiterNewsTypes.FETCH_RECRUITER_NEWS_REQUEST,
  url
});

export const fetchRecruiterNewsSuccess = (
  payload: FetchRecruiterNewsSuccessPayload,
): FetchRecruiterNewsSuccess => ({
  type: recruiterNewsTypes.FETCH_RECRUITER_NEWS_SUCCESS,
  payload
});

export const fetchRecruiterNewsFailure = (
  payload: FetchRecruiterNewsFailurePayload
): FetchRecruiterNewsFailure => ({
  type: recruiterNewsTypes.FETCH_RECRUITER_NEWS_FAILURE,
  payload
});
