import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminApplicantsDetailRequest } from '../../../redax/actions/adminApplicantsDetailActions/adminApplicantsDetailActions';
import { fetchAdminApplicantsPostRequest } from '../../../redax/actions/adminApplicantsPostActions/adminApplicantsPostActions';

const AdminApplicantsDetail = () => {
  const dispatch = useDispatch();
  const adminApplicantsDetail = useSelector((state: RootState) => state.adminApplicantsDetail);
  const adminApplicantsPost = useSelector((state: RootState) => state.adminApplicantsPost);
  const [loadScreen, setLoadScreen] = useState(true);
  const [btn, setBtn] = useState(false);
  const [load, setLoad] = useState(false);
  const [applicantsList, setApplicantsList] = useState<any>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  


  useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthAdminToken')) {
        setLoad(true);
        dispatch(fetchAdminApplicantsDetailRequest(id));
      } 
  }, [dispatch]); 

  useEffect(() => {
      if (adminApplicantsDetail.pending && load) {
        setApplicantsList(adminApplicantsDetail.applicant);
        // setComapny(adminRecruitmentDetail.company);
        setLoadScreen(false);
        setLoad(false);
      } else if(adminApplicantsDetail.error != null) {
        setLoadScreen(false);
      }
  }, [adminApplicantsDetail]); 
  



  useEffect(() => {
    if (adminApplicantsPost.pending && btn) {
      navigate('/admin/applicants_list/')
    } 
  }, [adminApplicantsPost, btn]); 
  
  function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
  }

  function fetchDelete () {
    let str = "";
    if(applicantsList.deleted) {
      str = "復元してよろしいですか？"
    } else {
      str = "求職者を本当に削除しますか？"
    }
    if (!window.confirm(str)) { return }
    dispatch(fetchAdminApplicantsPostRequest('','DELETE', id)); 
    navigate('/admin/applicants_list', { state: { isDeletedDisplay: true }});
    setBtn(true)
  }

// function calcGender () {
//   switch (applicantsList.gender_id) {
//     case 1: return '男性'
//     case 2: return '女性'
//     case 3: return '回答しない'
//     default: return null
//   }
// }

function calculateAge(birthday:any) {
  const birthDate = new Date(birthday);
  const today = new Date();
  
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  // If the birthday hasn't occurred yet this year, subtract one from age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  
  return age;
}
  

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
              <h2 className="title">求職者詳細</h2>
          </div>  
          <div className='body openJobDetail'>
            <div className="">
                <div className="textWrapper">
                  <a onClick={() => navigate('/admin/applicants_list')} className='blueBtn'>＜ 一つ前に戻る</a>
                </div>
              </div>
              {applicantsList && applicantsList.length !== 0 ?
              <>
               <div className='step1 '>
                <div className='detailDflex'>
                  <h1>基本情報</h1>
                </div>
                <div className='bordered' style={{width: "100%"}}>                
                  <div style={{display:'flex'}}>
                    <div style={{width: "20%"}}>
                      <div className='imgBox'>
                        <img src={applicantsList.face_photo}></img>
                      </div>
                    </div>
                    <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                      <h4>氏名<span className='m-3'>{applicantsList.family_name} { applicantsList.first_name }</span></h4>
                      <h4>氏名フリガナ<span className='m-3'>{applicantsList.family_name_kana} { applicantsList.first_name_kana }</span></h4>
                      <h4>登録日 <span> {applicantsList.created_at ? formatDate(applicantsList.created_at) : <></>}</span> </h4>
                      <h4>年齢 <span> { calculateAge(applicantsList.birthday) }</span> </h4>
                    </div>
                    <div className='detailTextBox' style={{width: "40%", padding: "0px 6px 6px 6px"}}>
                      <h4>性別{applicantsList.gender_id  ? <span className='borders2 m-3'> {  applicantsList.gender_id == 1 ? '男' : applicantsList.gender_id == 2 ? '女'  : '回答しない'}</span> : <></>}</h4> 
                      <h4>住所<span className='m-3'>
                        {/* { applicantsList.postcode } <br /> */}
                        { applicantsList.prefecture_name }{ applicantsList.city_name }{ applicantsList.address }</span></h4>
                      <h4>メールアドレス<span className='m-3'>{applicantsList.email} </span></h4>
                      <h4>電話番号 <span> { applicantsList.mobile_number }</span> </h4>
                    </div>
                  </div>
                </div>
                <div className='mt20'>
                  <h1>学歴または学校に関する情報</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td><p>最終学歴</p></td>
                    <td>
                      {applicantsList.educational_background.final_education ? <p className='borders1'>{applicantsList.educational_background.final_education }</p> : <></>}
                    </td>
                  </tr>
                  <tr>
                    <td><p>卒業・卒業見込み年度</p></td>
                    <td>{applicantsList.educational_background.graduation_date ? formatDate(applicantsList.educational_background.graduation_date) : <></>}</td>
                  </tr>
                  <tr>
                    <td><p>就職カテゴリー</p></td>
                    <td>{ applicantsList.educational_background.new_graduate ? <p className='borders1'>新卒</p> : <p className='borders1'>中途</p> }</td>
                  </tr>
                </table>
                <div className='mt20'>
                  <h1>障害について</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  {applicantsList.handicap_qualifications.map((data: any, index:any) => (
                    <tr key={"handicap_qualifications_" + index}>
                      <td><p>障がいについて {index + 1} </p></td>
                      <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>障害区分/障害名</p></div>
                          <div className='wid50'>{data.handicap ?  <p className='blueText'>{ data.handicap }</p>: <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>障害者手帳の有無</p></div>
                          <div className='wid50'><p className='blueText'>{ data.disability_certificate_status } { data.disability_certificate }</p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>経緯と経過</p></div>
                          <div className='wid50'><p className='blueText'>{ data.process } </p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>特性と対策</p></div>
                          <div className='wid50'><p className='blueText'>{ data.characteristics_and_countermeasures } </p></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
                <div className='mt20'>
                  <h1>配慮事項</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td>
                      <p>就労上必須の配慮事項</p>
                    </td>
                    <td>
                      {applicantsList.consideration_conditions_required_exists ? <p className='blueText'>{ applicantsList.consideration_conditions_required_exists ? '有' : '無' }</p> :  <></>}
                    </td>
                  </tr>
                  <tr>
                    <td><p>あると嬉しい配慮事項</p></td>
                    <td>
                      {applicantsList.consideration_conditions_required.map((data: any, index:any) => (
                        <p key={index} className='borders1' style={{marginRight:'10px'}}>{data}</p>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td><p>配慮事項（その他）</p></td>
                    <td><p>{ applicantsList.consideration_condition_supplemental }</p></td>
                  </tr>
                </table>
                <div className='mt20'>
                  <h1>就労支援機関</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td><p>就労支援機関</p></td>
                    <td>{!applicantsList.employment_support_agency.support_agency_exists ? <p className='blueText'>無</p> :
                        <>
                              <div className='dFlex'>
                                <div className='wid50'><p>機関・企業名</p></div>
                                <div className='wid50'><p className='blueText'>{ applicantsList.employment_support_agency.organization }</p></div>
                              </div>
                              <div className='dFlex'>
                                <div className='wid50'><p>連絡先</p></div>
                                <div className='wid50'><p className='blueText'>{ applicantsList.employment_support_agency.contact_number }</p></div>
                              </div>
                              <div className='dFlex'>
                                <div className='wid50'><p>URL</p></div>
                                <div className='wid50'><p className='blueText'>{ applicantsList.employment_support_agency.url }</p></div>
                              </div>
                              <div className='dFlex'>
                                <div className='wid50'><p>エントリー企業との連携可否</p></div>
                                <div className='wid50'><p className='blueText'>{ applicantsList.employment_support_agency.can_contact ? '可' : '否' }</p></div>
                              </div>
                              <div className='dFlex'>
                                <div className='wid50'><p>利用期間</p></div>
                                <div className='wid50'>
                                  <p className='blueText'>
                                    {applicantsList.employment_support_agency.start_date || applicantsList.employment_support_agency.end_date
                                      ? `${applicantsList.employment_support_agency.start_date ? formatDate(applicantsList.employment_support_agency.start_date) : ''} 
                                          ${applicantsList.employment_support_agency.start_date ? '～' : ''} 
                                          ${applicantsList.employment_support_agency.end_date ? formatDate(applicantsList.employment_support_agency.end_date) : ''}`
                                      : ''}
                                  </p>
                                </div>

                              </div>
                              <div className='dFlex'>
                                <div className='wid50'><p>訓練内容（２００文字以内）</p></div>
                                <div className='wid50'><p className='blueText'>{applicantsList.employment_support_agency.training_content}</p></div>
                              </div>
                        </>}
                     </td>
                  </tr>
                  <tr>
                    <td><p>ナビゲーションブック</p></td>
                    <td>
                      {applicantsList.navigation_book ? 
                      <a href={applicantsList.navigation_book} target="_blank" className="btn link orange" style={{fontSize:'14px', background:'#ee7743', color:'#fff', display:'inline', borderRadius:'5px', padding:'5px 10px' }}>
                        ナビゲーションブック
                      </a>
                      : <></>}
                    
                    </td>
                  </tr>
                </table>
                <div className='mt20'><h1>職務経歴</h1></div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td>
                      <p>職歴数</p>
                    </td>
                    <td>
                      { applicantsList.job_career_count ? <p className='borders1'>{applicantsList.job_career_count}</p> : <></> }
                    </td>
                  </tr>
                  <tr>
                    <td><p>直近の職歴</p></td>
                    <td>
                      <div className='dFlex'>
                        <div className='wid50'><p>会社名</p></div>
                        <div className='wid50'><p>{ applicantsList.job_career_recent.company } </p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>就業開始年月</p></div>
                        <div className='wid50'><p>{applicantsList.job_career_recent.arrival_date &&  applicantsList.job_career_recent.arrival_date != "null-null" ?  formatDate(applicantsList.job_career_recent.arrival_date)  : <></>}</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>就業終了年月</p></div>
                        <div className='wid50'><p> {applicantsList.job_career_recent.departure_date &&  applicantsList.job_career_recent.departure_date != "null-null" ? formatDate(applicantsList.job_career_recent.departure_date) : <></>}</p></div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>業界</p></div>
                        <div className='wid50'>{applicantsList.job_career_recent.industry ? <p className='borders1'>{applicantsList.job_career_recent.industry}</p> :  <></> }</div>
                      </div>

                      <div className='dFlex'>
                        <div className='wid50'><p>職種</p></div>
                        <div className='wid50'>{applicantsList.job_career_recent.job_category ? <p className='borders1'>{applicantsList.job_career_recent.job_category}</p> :  <></> }</div>
                      </div>
                      <div className='dFlex'>
                        <div className='wid50'><p>業務内容</p></div>
                        <div className='wid50'>{applicantsList.job_career_recent.business_content ? <p>{applicantsList.job_career_recent.business_content}</p> :  <></> }</div>
                      </div>
                      
                    </td>
                  </tr>
                  {applicantsList.job_career_appeals && applicantsList.job_career_appeals.map((data: any, index:any) => (
                    <tr key={index}>
                      <td><p>直近の職歴 {index +1}</p></td>
                      <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>会社名</p></div>
                          <div className='wid50'><p>{ data.company } </p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>就業開始年月</p></div>
                          <div className='wid50'><p>{ data.arrival_date ? formatDate(data.arrival_date) : <></> }</p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>就業終了年月</p></div>
                          <div className='wid50'><p> {data.departure_date ? formatDate(data.departure_date): <></> }</p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>業界</p></div>
                          <div className='wid50'>{data.industry ? <p className='borders1'>{data.industry}</p> :  <></> }</div>
                        </div>

                        <div className='dFlex'>
                          <div className='wid50'><p>職種</p></div>
                          <div className='wid50'>{data.job_category ? <p className='borders1'>{data.job_category}</p> :  <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>業務内容</p></div>
                          <div className='wid50'>{data.business_content ? <p>{data.business_content}</p> :  <></> }</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <p>アピールしたい経験職種</p>
                    </td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>経験職種1</p></div>
                          <div className='wid50'>{applicantsList.experience_job_category.job_category_1 ? <p className='borders1'>{ applicantsList.experience_job_category.job_category_1 }</p> :  <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>経験職種2</p></div>
                          <div className='wid50'>{applicantsList.experience_job_category.job_category_2 ? <p className='borders1'>{ applicantsList.experience_job_category.job_category_2 }</p> :  <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>経験職種3</p></div>
                          <div className='wid50'>{applicantsList.experience_job_category.job_category_3 ? <p className='borders1'>{ applicantsList.experience_job_category.job_category_3 }</p> :  <></> }</div>
                        </div>
                    </td>
                  </tr>
                </table>
                <div className='mt20'>
                  <h1>スキル</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td>
                      <p>アピールしたいPCスキル</p>
                    </td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>Word経験</p></div>
                          <div className='wid50'><p className='blueText'>{ applicantsList.oa_skills.word_experience ? '有' : '無'  }</p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたいWordスキル</p></div>
                          <div className='wid50'>

                          {applicantsList.oa_skills.word && applicantsList.oa_skills.word.map((data: any, index:any) => (
                              <p className='borders1' style={{marginRight: '10px'}}>{data}</p>
                          ))}
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>Excel</p></div>
                          <div className='wid50'><p className='blueText'>{ applicantsList.oa_skills.excel_experience ? '有' : '無'  }</p></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたいExcelスキル</p></div>
                          <div className='wid50'>
                            {applicantsList.oa_skills.excel && applicantsList.oa_skills.excel.map((data: any, index:any) => (
                                <p className='borders1' style={{marginRight: '10px'}}>{data}</p>
                            ))}
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたいデザイン系・その他事務系スキル</p></div>
                          <div className='wid50'>
                            {applicantsList.oa_skills.design && applicantsList.oa_skills.design.map((data: any, index:any) => (
                                <p className='borders1' style={{marginRight: '10px'}}>{data}</p>
                            ))}
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたいプログラミング系スキル</p></div>
                          <div className='wid50'>
                            {applicantsList.oa_skills.program && applicantsList.oa_skills.program.map((data: any, index:any) => (
                                <p className='borders1' style={{marginRight: '10px'}}>{data}</p>
                            ))}
                          </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>アピールしたい資格</p>
                    </td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたい資格1</p></div>
                          <div className='wid50'> { applicantsList.qualification.qualification_1 ? <p className='borders1'>{ applicantsList.qualification.qualification_1 }</p> : <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたい資格2</p></div>
                          <div className='wid50'> { applicantsList.qualification.qualification_2 ? <p className='borders1'>{ applicantsList.qualification.qualification_2 }</p> : <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>アピールしたい資格3</p></div>
                          <div className='wid50'> { applicantsList.qualification.qualification_3 ? <p className='borders1'>{ applicantsList.qualification.qualification_3 }</p> : <></> }</div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>アピールしたい語学</p>
                    </td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>語学1/語学レベル</p></div>
                          <div className='wid50'> { applicantsList.language_study.language_1  ? <p className='borders1'>{ applicantsList.language_study.language_1 }</p>  : <></> } {applicantsList.language_study.level_1 ? <p className='borders1'> { applicantsList.language_study.level_1 }</p>: <></>}</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>語学2/語学レベル</p></div>
                          <div className='wid50'> { applicantsList.language_study.language_2 ? <p className='borders1'>{ applicantsList.language_study.language_2 } </p> : <></> } {applicantsList.language_study.level_2 ? <p className='borders1'> { applicantsList.language_study.level_2 }</p> : <></>}</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>語学3/語学レベル</p></div>
                          <div className='wid50'> { applicantsList.language_study.language_3 ? <p className='borders1'>{ applicantsList.language_study.language_3 }</p> : <></> }</div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>自己PR</p></td>
                    <td>
                      { applicantsList.self_pr.content }
                    </td>
                  </tr>
                </table>
                <div className='mt20'>
                  <h1>希望条件</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td><p>仕事の探し方</p></td>
                    <td>{ applicantsList.preferred.search_policy ?  <p className='borders1'>{applicantsList.preferred.search_policy}</p> : <></> }</td>
                  </tr>
                  <tr>
                    <td><p>働き方</p></td>
                    <td>
                      {applicantsList.work_style && applicantsList.work_style.map((data: any, index:any) => (
                          <p key={index} className='borders1' style={{margin: '5px'}}>{data}</p>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>就労時間</p>
                    </td>
                    <td> { applicantsList.preferred.working_hour ? <p className='borders1'>{ applicantsList.preferred.working_hour }</p> : <></> } </td>
                  </tr>
                  <tr>
                    <td><p>在宅・リモートワーク希望</p></td>
                    <td> { applicantsList.preferred.work_from_home ? <p className='borders1'>{ applicantsList.preferred.work_from_home }</p> : <></> } </td>
                  </tr>
                  <tr>
                    <td><p>勤務地</p></td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>勤務希望地1</p></div>
                          <div className='wid50'> { applicantsList.preferred.prefecture_1 ? <p className='borders1'>{ applicantsList.preferred.prefecture_1 }</p> : <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>勤務希望地2</p></div>
                          <div className='wid50'> { applicantsList.preferred.prefecture_2 ? <p className='borders1'>{ applicantsList.preferred.prefecture_2 }</p> : <></> }</div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>転勤の可否</p></div>
                          <div className='wid50'> { applicantsList.preferred.nationwide ? <p className='borders1'> 可</p> : <p className='borders1'>否</p> }</div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                        <td><p>雇用形態</p></td>
                        <td>
                          {applicantsList.employment_statuses && applicantsList.employment_statuses.map((data: any, index:any) => (
                              <p key={index} className='borders1' style={{marginRight: '10px'}}>{data}</p>
                          ))}
                        </td>
                  </tr>
                  <tr>
                        <td><p>通勤手段</p></td>
                        <td>
                          {applicantsList.preferred && applicantsList.preferred.commuting_methods && applicantsList.preferred.commuting_methods.map((data: any, index:any) => (
                              <p key={index} className='borders1' style={{marginRight: '10px'}}>{data}</p>
                          ))}
                        </td>
                  </tr>
                  <tr>
                        <td><p>業界</p></td>
                        <td>
                        {applicantsList.preferred.industry_1  ? <p className='borders1'>applicantsList.preferred.industry_1</p>:'' }  {applicantsList.preferred.industry_2  ? <p className='borders1'>applicantsList.preferred.industry_2</p>:'' }
                          
                        </td>
                  </tr>
                  <tr>
                        <td><p>職種</p></td>
                        <td>
                        {applicantsList.preferred.job_category_1  ?<p className='borders1'> applicantsList.preferred.job_category_1</p>:'' }  {applicantsList.preferred.job_category_2  ? <p className='borders1'>applicantsList.preferred.job_category_2</p>:'' }
                        </td>
                  </tr>
                  <tr>
                    <td><p>年収</p></td>
                    <td>
                      <p>{ applicantsList.preferred.annual_income }</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p>求職ステータス</p></td>
                    <td>
                        <div className='dFlex' style={{marginBottom:'10px'}}>
                          {/* <div className='wid50'><p className='blueText'>{ applicantsList.is_active ? '仕事を探している' : '仕事を探していない' }</p></div> */}
                          <div className='wid50'><p>{ applicantsList.is_active ? '仕事を探している' : '仕事を探していない' }</p></div>
                          <div className='wid50'></div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>入社希望月：</p></div>
                          <div className='wid50'>
                            { applicantsList.preferred.join_month  ? <p className='borders1'>{applicantsList.preferred.join_month}</p> : <></>}
                          </div>
                        </div>
                    </td>
                  </tr>
                </table>
                <div className='mt20'>
                  <h1>希望条件</h1>
                </div>
                <table style={{wordBreak:'break-word'}}>
                  <tr>
                    <td><p>企業に伝えたい項目</p></td>
                    <td>
                        <div className='dFlex'>
                          <div className='wid50'><p>伝えたい項目1</p></div>
                          <div className='wid50'>
                            { applicantsList.appeal_item.appeal_item_1 ? <p className='borders1'>{ applicantsList.appeal_item.appeal_item_1 }</p> : <></>} 
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>伝えたい項目2</p></div>
                          <div className='wid50'>
                          { applicantsList.appeal_item.appeal_item_2 ? <p className='borders1'>{ applicantsList.appeal_item.appeal_item_2 }</p> : <></>} 
                          </div>
                        </div>
                        <div className='dFlex'>
                          <div className='wid50'><p>伝えたい項目3</p></div>
                          <div className='wid50'>
                          { applicantsList.appeal_item.appeal_item_3 ? <p className='borders1'>{ applicantsList.appeal_item.appeal_item_3 }</p> : <></>} 
                          </div>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td><p>特記事項</p></td>
                    <td>
                      <p>{ applicantsList.special_content }</p>
                    </td>
                  </tr>
                </table>
            </div>
            <div  style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
                {
                  applicantsList.deleted ?
                  <>
                    <button className='deleteBtn'  style={{padding:'10px 100px', fontSize:'18px'}} onClick={()=> fetchDelete()}>削除済みから復旧する</button>
                  </>
                :
                  <>
                    <button className='deleteBtn'  style={{padding:'10px 100px', fontSize:'18px'}} onClick={()=> fetchDelete()}>削除</button>
                    <button className='createBtn' onClick={() =>navigate('/admin/applicants_list/' +id +'/edit')}  style={{padding:'10px 100px', fontSize:'18px'}}>編集</button>
                  </>
                }
                
              </div>
              
              </>
              : <></>}
              
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminApplicantsDetail;
