import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import AdminNav from '../AdminNav';
import '../adminTop/adminTop.css';
import AdminNav from '../adminTop/AdminNav';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchPostCodeRequest } from '../../redax/actions/postcodeActions/postcodeActions';
import Loader from '../loader/loader';
import { fetchAdminCompanyRegionsRequest } from '../../redax/actions/adminCompanyRegionsActions/adminCompanyRegionsActions';
import { fetchAdminCompanyNewRequest } from '../../redax/actions/adminCompanyNewActions/adminCompanyNewActions';


const AdminCompanyNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loginData = useSelector((state: RootState) => state.login);
  const postcodeData = useSelector((state: RootState) => state.postcode);
  const adminCompanyNew = useSelector((state: RootState) => state.adminCompanyNew);
  const adminCompanyRegions = useSelector((state: RootState) => state.adminCompanyRegions);
  const [loadScreen, setLoadScreen] = useState(false);
  const [logo, setLogo] = useState<any>(null);
  const [companyName, setCompanyName] = useState<any>('');
  const [companyNameKana, setCompanyNameKana] = useState<any>('');
  const [corporateNumber, setCorporateNumber] = useState<any>(null);
  const [url, setUrl] = useState<any>('');
  const [isEditCity, setIsEditCity] = useState<any>(false);
  const [locationCityName, setLocationCityName] = useState(String);
  const [locationPrefectureName, setLocationPrefectureName] = useState(String);
  const [industries, setIndustries] = useState<any[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<any>(null);
  const [foundedYear, setFoundedYear] = useState<any>(null);
  const [foundedMonth, setFoundedMonth] = useState<any>(null);
  const [numberOfEmployees, setNumberOfEmployees] = useState<any[]>([]);
  const [businessContent, setBusinessContent] = useState<any>(null);
  const [peopleWithDisabilities, setPeopleWithDisabilities] = useState<any>([{
    '身体障がい': null,
    '精神障がい': null,
    '発達障がい': null,
    '知的障がい': null,
    '難病:その他': null
  }]);
  const [selectedNumberOfEmployees, setSelectedNumberOfEmployees] = useState<any>(null);
  const [capital, setCapital] = useState<any>(null);
  const [sales, setSales] = useState<any>(null);
  const [stockListingsArr, setStockListingsArr] = useState<any[]>([]);
  const [stockListings, setStockListings] = useState<any>(null);
  const [cultureList, setCultureList] = useState<any[]>([]);
  const [corporateCultureIds, setCorporateCultureIds] = useState<any[]>([]);
  const [purposeOfPostingsIds, setPurposeOfPostingsIds] = useState<any[]>([]);

  const [images, setImages] = useState<any[]>([]);
  const [showImage, setShowImage] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const fileInputRefImage = useRef<HTMLInputElement | null>(null);
  const [locationRegionId, setLocationRegionId] = useState(null);
  const [locationPrefectureId, setLocationPrefectureId] = useState(null);
  const [locationCityId, setLocationCityId] = useState(null);
  const [locationNearestStation, setLocationNearestStation] = useState('');
  const [prefectureText, setPrefectureText] = useState('');
  const [areaText, setAreaText] = useState('');
  const [postCode, setPostCode] = useState<string | null>(null);
  const [locationAddress, setLocationAddress] = useState('');
  const [createBtn, setCreateBtn] = useState(false);
  const [purposeOfPostings, setPurposeOfPostings] = useState<any[]>([]);
  const [familyName, setFamilyName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [department, setDepartment] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>(''); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  







useEffect(() => {
    if(adminCompanyRegions.pending) {
        setIndustries(adminCompanyRegions.regions.industries);
        setNumberOfEmployees(adminCompanyRegions.regions.number_of_employees);
        setStockListingsArr(adminCompanyRegions.regions.stock_listings);
        setCultureList(adminCompanyRegions.regions.corporate_cultures);
        setPurposeOfPostings(adminCompanyRegions.regions.purpose_of_postings);

        const value = [];
        for (let i = 0; i < 5; i++) {
            const image = { priority: i + 1, comment: null ,image:null};
            value.push({ ...image });
          }
          setImages(value);
    }
}, [adminCompanyRegions]);

function setComapnyLogo() {
    const fileInput = fileInputRefImage.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const fileImg = fileInput.files[0];
      if (fileImg.type.startsWith('image/')) {
        const logoUrl = URL.createObjectURL(fileImg);
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = function () {
            setLogo(reader.result);
        };
      }
    }
  }

  const setImage = (index: number, event: any) => {
    const fileImg = event.target.files[0];

    if (fileImg.type.startsWith("image/")) {
      const updatedImages = [...images];
      const reader = new FileReader();
      reader.readAsDataURL(fileImg);
      reader.onload = function () {
        updatedImages[index].image = reader.result as string;
        updatedImages[index].url = reader.result as string;
        setImages(updatedImages);
      };
    }
  };

  useEffect(() => {
    dispatch(fetchAdminCompanyRegionsRequest())
}, [dispatch]);
  



  




  useEffect(() => {
    if (postcodeData.pending && Object.keys(postcodeData).length !== 0) {
        setLocationRegionId(postcodeData.postcode?.region?.id);
        setLocationPrefectureId(postcodeData.postcode?.prefecture?.id);      
        setLocationCityId(postcodeData.postcode?.city?.id);
        setLocationPrefectureName(postcodeData.postcode?.prefecture?.name);
        setLocationCityName(postcodeData.postcode?.city?.name);
        let textAreaKeyUp = postcodeData.postcode?.prefecture?.name + postcodeData.postcode?.city?.name;
        if(textAreaKeyUp) {
            setAreaText(textAreaKeyUp);
        }
    } else {
      setLocationRegionId(null);
      setLocationPrefectureId(null)
      setAreaText('');
      setLocationPrefectureName('');
      setLocationCityName('');
      setLocationCityId(null)
  } 
}, [postcodeData]);


function onKeyUpPostCode() {
    //   if ( postCode == null !== 7 ) { return }
      dispatch(fetchPostCodeRequest(postCode));
}

const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
        setPhoneNumber(value)
    }
};

  function validates() {
    let result = '';
    const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
    const passwordValidate = new RegExp('^(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,32}$');
    let mailformat = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(corporateNumber == null){
        result += '法人番号は13桁です\n'
    } else {
        if(corporateNumber.length !== 13){
            result += '法人番号が入力されていません\n'
        }
    }
    if(companyName == ""){
        result += '会社名が入力されていません\n'
    } else {
        if(companyName.length > 100) {
            result += '会社名が入力されていません\n'
        }
    }

    if(companyNameKana == "") {
        result += '採用担当者 担当者名（カナ）はカタカナで入力してください\n'
    } else {
        if(companyNameKana.length >100){
            result += '採用担当者 担当者名（カナ）が入力されていません\n'
        }
        if(!kanaValidate.test(companyNameKana)){
            result += '採用担当者 担当者名（カナ）が入力されていません\n'
        }

    }
    if(postCode == null){
        result += '郵便番号の形式が正しくありません\n'
    } else {
        if(postCode.length !== 7){
            result += '郵便番号が入力されていません\n'
        }
    }

    if(locationAddress == null){
        result += '住所が入力されていません\n'
    } else {
        if(locationAddress.length > 200){
            result += '住所は200文字までです\n'
        }
    }
    if(businessContent){

        if(businessContent.length > 200){
            result += '事業内容は200文字までです\n'
        }

    }
    if(numberOfEmployees){
        if(numberOfEmployees.length > 20){
            result += '従業員数は20文字までです\n'
        }
    }

    if(capital){
        if(capital.length> 20){
            result += '資本金は20文字までです\n'
        }
    }

    if(sales){
        if(sales.length> 20){
            result += '売上高は20文字までです\n'
        }
    }

    if(stockListings == null){
        result += '株式が選択されていません\n'
    }


    if(familyName == "") {
        result += '採用担当者 担当者名が入力されていません\n'
    } else {
        if(familyName.length >30){
            result += '採用担当者 担当者名が入力されていません\n'
        }
    }
    if(familyNameKana == "") {
        result += '採用担当者 担当者名（カナ）はカタカナで入力してください\n'
    } else {
        if(familyNameKana.length >30){
            result += '採用担当者 担当者名（カナ）が入力されていません\n'
        }
        if(!kanaValidate.test(familyNameKana)){
            result += '採用担当者 担当者名（カナ）が入力されていません\n'
        }

    }
    if(department == ''){
        result += '採用担当者 部署が入力されていません\n'
    } else {
        if(department.length >50){
            result += '採用担当者 部署が入力されていません\n'
        }
    }
    if(phoneNumber == ''){
        result += '採用担当者 電話番号が入力されていません\n'
    } else {
        if(phoneNumber.length < 10 ||  phoneNumber.length > 11 ){
            result += '採用担当者 電話番号が入力されていません\n'
        }
    }
    if(email == ''){
        result += '採用担当者 メールアドレスが正しくありません\n'
    }else {
        if(!mailformat.test(email)){
            result += '採用担当者 メールアドレスが正しくありません\n'
        }
    }
    if(password == ''){
        result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
    } else {
        if(password.length > 32 ||  password.length <= 8){
            result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
        }
        if(!passwordValidate.test(password)){
            result += '採用担当者 パスワードは8文字以上32文字以下で大小英数字を含めてください\n'
        }
    }
    return result
   
}
  
  function companyCreate () {
        const validatedText = validates(); 
        if (validatedText) {
            alert('未入力項目があります\n\n' + validatedText)
            return
        }
        let graduationDate;
        let founded 
        if (foundedYear && foundedMonth ) {
            founded = `${foundedYear}年${foundedMonth}月`
        }
        
        let company = {
            logo: logo,
            images: images,
            corporate_number: corporateNumber,
            name: companyName,
            name_kana: companyNameKana,
            url: url,
            postcode: postCode,
            prefecture_id: locationPrefectureId,
            city_id: locationCityId,
            address: locationAddress,
            industry_id: selectedIndustries,
            business_content: businessContent,
            founded: founded,
            number_of_employees: selectedNumberOfEmployees,
            capital: capital,
            sales: sales,
            stock_listing_id: stockListings,
            people_with_disabilities: peopleWithDisabilities,
            corporate_cultures: corporateCultureIds,
            main_recruiter: {
              family_name: familyName,
              family_name_kana: familyNameKana,
              department: department,
              phone_number: phoneNumber,
              email: email,
              password: password,
            }
          }
        dispatch(fetchAdminCompanyNewRequest(company,'POST',''));
        setCreateBtn(true);
  }
  useEffect(() => {
    if (adminCompanyNew.pending && createBtn) {
        navigate('/admin/companies');
    }
  }, [adminCompanyNew]);



  const handleCheckboxChange = (e: any) => {
    setIsEditCity(e.target.checked);
  };

  function changePeopleWithDisabilities(event:any) {
    const { name, value } = event.target;
    let peopleWithDisabilitiesData = [...peopleWithDisabilities];

    if (peopleWithDisabilitiesData[0]) {
        peopleWithDisabilitiesData[0][name] = value;
    }
    setPeopleWithDisabilities(peopleWithDisabilitiesData);
}

function onChangeCorporateCulture (e:any, index:any){
    let c = [];
    for(let i = 0; i < corporateCultureIds.length; i++) {
        c.push(corporateCultureIds[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setCorporateCultureIds(c);
  }

  function onChangePurposeOfPostings (e:any, index:any){
    let c = [];
    for(let i = 0; i < purposeOfPostingsIds.length; i++) {
        c.push(purposeOfPostingsIds[i]);
    }
    let chk = false;
    for(let i = 0; i < c.length; i++) {
        if(c[i] == e.target.value) {
            chk = true;
            index = i;
        }
    }
    if(!chk) {
        c.push(parseInt(e.target.value));
    } else {
        c.splice(index, 1);
    }
    setPurposeOfPostingsIds(c);
  }

  const handleCommentChange = (event: any, index: number) => {
    const text = event.target.value;
    const updatevalue = {
      comment: text,
    };
    const updatedComment = [...images];
    updatedComment[index] = {
      ...updatedComment[index],
      ...updatevalue,
    };
    setImages(updatedComment);
  };

  function delImage(event: any) {
    let c = [...images];
    c[event - 1].url = null;
    c[event - 1].comment = null;
    delete c[event - 1].image;
    setImages(c);

    // Null check before resetting the input value
    const fileInput = fileInputRefs.current[event - 1];
    if (fileInput) {
        fileInput.value = ""; // Reset the file input
    }
}

function otherImage (id: any) {
    let arr = [...showImage];
    arr[id + 1] = true;
    setShowImage(arr);
  }
  function otherImageDelete(id: number) {
    let arr = [...showImage];
    arr[id] = false;
    setShowImage(arr);
}

  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader /> : null}
      <section id='openjob'>
        <div className='adminPageWrapper'>
          <div className="header">
            <h2 className="title">企業新規作成</h2>
          </div>  
          <div className='body'>
            <div className="">
              <div className="textWrapper">
                <a onClick={() => navigate('/admin/companies')} className='blueBtn'>＜ 一つ前に戻る</a>
              </div>
            </div>
            <div className='step3'>
                <div>
                    <h1>会社ロゴ</h1>
                    <div className='bordered mb30'>
                        <div className='dFlex' style={{justifyContent:'center'}}>
                            <div className="photo">
                                <div className="img_box">
                                    <img src={logo == null ? '/assets/img/File.svg': logo} alt=""/>
                                </div>
                                <p className="btn">
                                    <label style={{background:'#00699f', color:'#fff', padding:'10px 40px ', borderRadius:'5px' , fontSize:'16px'}} ><input style={{display:'none'}} type="file" className="btn" ref={fileInputRefImage} onChange={setComapnyLogo}/>ファイルを選択 </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1>基本情報</h1>
                    <div className='bordered'>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>会社名 <span style={{ color: 'red' }}>※必須</span></label>
                                <input placeholder='' type="text" name="companyName" value={companyName || ''} onChange={(event) => setCompanyName(event.target.value)}/>
                            </div>
                            <div>
                                <label>会社名(フリガナ) <span style={{ color: 'red' }}>※必須</span> </label>
                                <input placeholder='' type="text" name="companyNameKana" value={companyNameKana || ''} onChange={(event) => setCompanyNameKana(event.target.value)}/>
                            </div>
                        </div>
                        <div>
                            <label>法人番号 <span style={{ color: 'red' }}>※必須 13桁</span> </label>
                            <input placeholder='' className='wid300' type="text" name="corporateNumber" value={corporateNumber || ''} onChange={(event) => setCorporateNumber(event.target.value)}/>
                        </div>

                        <div>
                            <label>URL</label>
                            <input placeholder='' className='wid300' type="text" name="url" value={url || ''} onChange={(event) => setUrl(event.target.value)}/>
                        </div>

                        <div>
                            <label>
                                郵便番号<span style={{ color: 'red' }}>※必須</span>
                            </label>
                            <div className='dFlex'>
                            <input type="number" maxLength={7} className='wid300' placeholder='(例)0000000' name="address" value={postCode || ''}  onChange={(e) => {if (e.target.value.length <= 7) { setPostCode(e.target.value);}}}/>
                            <button onClick={onKeyUpPostCode} className='createBtn' style={{marginBottom:'19px', marginLeft:'5px'}}>郵便番号検索</button>
                            </div>
                        </div>
                        <div>
                      <div className='dFlex'>
                      {!isEditCity? 
                        <div>
                          <label>
                          都道府県 市/区<span  style={{ color: 'red' }}>※必須</span>
                          </label>
                            <input type="text" placeholder='郵便番号で自動入力' name="prefecture" value={areaText} onChange={(e) => setAreaText(e.target.value)}  disabled/>
                        </div>
                         :<></>}
                         {isEditCity ? 
                            <div>
                                <label>
                                    都道府県 市/区<span  style={{ color: 'red' }}>※必須</span>
                                </label>
                            <div className='dFlex'>
                                <input v-if="isEditCity" type="text" name="prefecture" v-model="company.prefecture.name" value={locationPrefectureName} disabled/>
                                <input v-if="isEditCity" type="text" name="city" value={locationCityName} onChange={(e) => {setLocationCityName(e.target.value)}}/>
                            </div> 
                            </div>
                        : <></>}
                        <div style={{marginLeft:'20px'}}>
                          <label>
                          住所(それ以降)
                          </label>
                          <input  type="text" placeholder='(例)1-1-1 マンション名' value={locationAddress} onChange={(e)=>setLocationAddress(e.target.value)}/>
                        </div>
                      </div>
                      <div>
                      <label>
                        <input type="checkbox"  value={isEditCity} onChange={handleCheckboxChange}/>市区町村を修正する
                        </label>
                      </div>
                      <div>
                        <label>業種分類</label>
                        <select value={selectedIndustries || ''} onChange={(e)=>setSelectedIndustries(e.target.value)}>
                            <option value={""} selected disabled>選択してください</option>
                            {industries && industries.map((data) => (
                                <option key={data.id} value={data.id}>
                                    {data.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='dFlex'>
                            <div style={{marginRight: '5px'}}>
                                <label>設立年月</label>
                                <input placeholder='入力してください' type="text" name="year" value={foundedYear || ''} onChange={(e) => setFoundedYear(e.target.value)}/>
                            </div>
                            <p style={{margin: '10px 5px'}}>年</p>
                            <div style={{marginRight: '5px', marginTop:'20px'}}>
                                <select value={foundedMonth || ''} onChange={(e) => setFoundedMonth(e.target.value)}>
                                    <option value="" disabled>選択してください</option>
                                    {[...Array(12)].map((_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <p style={{margin: '10px 5px'}}>月</p>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>会社概要</h1>
                    <div className='bordered'>
                        <div>
                            <label>業務内容</label>
                            <textarea name="businessContent" id=""  onChange={(e)=> setBusinessContent(e.target.value)}></textarea>
                        </div>
                        <div>
                            <label>従業員数</label>
                            <div className='dFlex'>
                                <input type="number" onChange={(e)=> setSelectedNumberOfEmployees(e.target.value)} className='wid300' />
                                <p style={{padding:'10px 5px'}}>人</p>
                            </div>
                        </div>
                        <div className='dFlex'>
                            <div style={{marginRight:'20px'}}>
                                <label>資本金</label>
                                <input type="text" onChange={(e)=> setCapital(e.target.value)} />
                            </div>
                            <div>
                                <label>売上高</label>
                                <input type="text"  onChange={(e)=> setSales(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt20'>
                    <h1>障がい者雇用実績</h1>
                    <div className='bordered'>
                      <div>
                            <label>身体障がい</label>
                            <select onChange={changePeopleWithDisabilities} name='身体障がい'>
                                <option value={""}  selected>選択してください</option>
                                {numberOfEmployees && numberOfEmployees.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                      </div>
                      <div>
                            <label>精神障がい</label>
                            <select onChange={changePeopleWithDisabilities} name='精神障がい'>
                                <option value={""}  selected>選択してください</option>
                                {numberOfEmployees && numberOfEmployees.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                      </div>
                      <div>
                            <label>発達障がい</label>
                            <select onChange={changePeopleWithDisabilities} name='発達障がい' >
                                <option value={""}  selected>選択してください</option>
                                {numberOfEmployees && numberOfEmployees.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                      </div>
                      <div>
                            <label>知的障がい</label>
                            <select onChange={changePeopleWithDisabilities} name='知的障がい'>
                                <option value={""}  selected>選択してください</option>
                                {numberOfEmployees && numberOfEmployees.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                      </div>
                      <div>
                            <label>難病:その他</label>
                            <select onChange={changePeopleWithDisabilities} name='難病:その他'>
                                <option value={""}  selected>選択してください</option>
                                {numberOfEmployees && numberOfEmployees.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                      </div>
                    </div>
                </div>

                <div className='mt20'>
                    <h1>株式:社風</h1>
                    <div className='bordered'>
                        <div>
                            <label>株式 <span style={{color:'red'}}>※必須</span></label>
                            <select onChange={(e)=> setStockListings(e.target.value)}>
                                <option value={""}  selected>選択してください</option>
                                {stockListingsArr && stockListingsArr.map((data) => (
                                    <option key={data.id} value={data.id}>
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>社風</label>
                            <div style={{display:'ruby'}}>
                                {cultureList && cultureList.map((cultureList, index) => (
                                    <label key={cultureList.id} style={{width:'33%'}}>
                                        <input  type="checkbox" checked={corporateCultureIds.includes(cultureList.id) ? true : false} value={cultureList.id} onChange={(e) => onChangeCorporateCulture(e, index)}></input>
                                        {cultureList.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                        <h1>画像</h1>
                        <div className='bordered'>
                            <label htmlFor="">サムネイル画像 <span style={{color:'red'}}>※必須</span></label>
                            {images &&
                            images.map((i: any, index: any) => {
                            return (
                                <div key={index} className='adminImageBox'>
                                    {showImage[index] ? 
                                    <>
                                    <div key={"main" + index} className="image_dFlex">
                                            {/* <p className="advice_txt">
                                                {imagesAdvices[index]}
                                            </p> */}
                                            <div>
                                                {(i.url == null || i.url == '') ? <img src="/assets/img/no-image.jpg"alt=""/> : <img src={i.url}></img>}
                                            </div>
                                            <div>
                                            <label>
                                                <div>
                                                <input ref={(el) => (fileInputRefs.current[index] = el)}type="file" id="fileInput" accept="image/*" onChange={(event) => setImage(index, event)}/>
                                                <button
                                                    className='custom-file-upload'
                                                    onClick={() => {
                                                        if (fileInputRefs.current[index]) {
                                                        fileInputRefs.current[index]?.click();
                                                        }
                                                    }}
                                                    >
                                                    ファイルを選択
                                                    </button>
                                                </div>
                                            </label>
                                            {i.url != "" ?
                                                <> 
                                                    <div className='dFlex'>
                                                    <input
                                                        className="swComment"
                                                        type="text"
                                                        value={i.comment || ""}
                                                        placeholder="画像説明文を入力"
                                                        onChange={(event) => {
                                                        handleCommentChange(event, index);
                                                        }}
                                                    ></input>
                                                    <button className='fileDeleteBtn' onClick={(e) => delImage(i.priority)}>削除</button>
                                                    </div>
                                                </>
                                            :<></>}
                                            </div>
                                        </div>
                                        {index != 4 ?
                                         <div className='newImage' onClick={() =>otherImage(index)}>画像を複数追加</div>
                                        :<></>}
                                        {index != 0 ? <button className='deletBtnToggle'  onClick={() => otherImageDelete(index)}>削除</button> : <></>}
                                    </>
                                    : <></>}
                                </div>
                            );
                            })}
                        </div>
                    </div>
                    <div className='mt20'>
                        <h1>登録:求人掲載目的 <span style={{color:'red', fontWeight:'400'}}>※必須</span></h1>
                        <div className='bordered'>
                            <div>
                                <label htmlFor=""></label>
                                {purposeOfPostings && purposeOfPostings.map((purposeOfPostings, index) => (
                                    <label key={purposeOfPostings.id} style={{width:'33%'}}>
                                        <input  type="checkbox" checked={purposeOfPostingsIds.includes(purposeOfPostings.id) ? true : false} value={purposeOfPostings.id} onChange={(e) => onChangePurposeOfPostings(e, index)}></input>
                                        {purposeOfPostings.name}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='mt20'>
                        <h1>採用担当者</h1>
                        <div className='bordered'>
                        <div>
                            <label htmlFor="">担当者名</label>
                            <input type="text" className='wid300' value={familyName || ''} onChange={(e)=> setFamilyName(e.target.value)} placeholder='(例)山田 太郎' />

                            <label htmlFor="">担当者名(カナ)</label>
                            <input type="text" className='wid300' value={familyNameKana || ''} onChange={(e)=> setFamilyNameKana(e.target.value)} placeholder='(例)ヤマダ タロウ' />

                            <label htmlFor="">部署</label>
                            <input type="text" className='wid300'  value={department || ''} onChange={(e)=> setDepartment(e.target.value)} placeholder='入力してください' />

                            <label htmlFor="">電話番号</label>
                            <input type="tel" className='wid300' maxLength={11} placeholder='入力してください'  value={phoneNumber || ''} onChange={handlePhoneNumberChange} />

                            <label htmlFor="">メールアドレス</label>
                            <input type="text" className='wid300' value={email || ''} onChange={(e)=> setEmail(e.target.value)}  placeholder='入力してください' />

                            <label htmlFor="">パスワード</label>
                            <input type="password" className='wid300' value={password || ''} onChange={(e)=> setPassword(e.target.value)}  placeholder='入力してください' />
                        </div>
                        </div>
                    </div>
            </div>
        </div>
           
            <div style={{display:'flex',position:'relative', alignItems:'center', justifyContent:'center',marginTop:'20px'}}>
                <button className='createBtn' style={{padding:'5px 100px', fontSize:'18px'}} onClick={()=>companyCreate()}>新規作成</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminCompanyNew;