import { all } from '@redux-saga/core/effects';
import { fork } from 'redux-saga/effects';
import RecruitmentsSaga from './recruitmentsSaga/recruitmentsSaga';
import RegionsSaga from './regionsSaga/regionsSaga';
import ArticlesSaga from './articlesSaga/articlesSaga';
import RecruitmentsListSaga from './recruitmentsListSaga/recruitmentsListSaga';
import RecruitmentsSearchSaga from './recruitmentsSearchSaga/recruitmentsSearchSaga';
import RecruitmentsDetailSaga from './recruitmentsDetailSaga/recruitmentsDetailSaga';
import ColumnFetchListSaga from './columnFetchListSaga/columnFetchListSaga';
import LoginSaga from './loginSaga/loginSaga';
import ApplicantSaga from './applicantSaga/applicantSaga';
import CompaniesSaga from './companiesSaga/companiesSaga';
import RecruitmentsPublicListSaga from './recruitmentsPublicListSaga/recruitmentsPublicListSaga';
import ApplicantsSaga from './applicantsSaga/applicantsSaga';
import ApplicationsSaga from './applicationsSaga/applicationsSaga';
import MessagesSaga from './messagesSaga/messagesSaga';
import InterviewsSaga from './interviewsSaga/interviewsSaga';
import PostCodeSaga from './postcodeSaga/postcodeSaga';
import MessagesPostSaga from './messagesPostSaga/messagesPostSaga';
import ChangeUpdateStatusSaga from './changeUpdateStatusSaga/changeUpdateStatusSaga';
import EmailChangeSaga from './emailChangeSaga/emailChangeSaga';
import TopRecruiterSaga from './topRecruiterSaga/topRecruiterSaga';
import PasswordResetSaga from './passwordResetSaga/passwordResetSaga';
import ResetSaga from './resetSaga/resetSaga';
import signupSaga from './signupSaga/signupSaga';
import ArticlesRecruiterSaga from './articlesRecruiterSaga/articlesRecruiterSaga';
import ChangeUpdateApplicantsSaga from './changeUpdateApplicantsSaga/changeUpdateApplicantsSaga ';
import DownloadCareerSaga from './downloadCareerSaga/downloadCareerSaga';
import DownloadResumeSaga from './downloadResumeSaga/downloadResumeSaga';
import ArticlesImagesSaga from './articlesImagesSaga/articlesImagesSaga';
import RecruitmentsCompanySaga from './recruitmentsComapnySaga/recruitmentsComapnySaga';
import PreviewSaga from './previewSaga/previewSaga';
import stepSaga from './stepSaga/stepSaga';
import ColumnsSaga from './columnsSaga/columnsSaga';
import ColumnFetchItemSaga from './columnFetchItemSaga/columnFetchItemSaga';
import CountSaga from './countSaga/countSaga';
import PutRegionSaga from './putRegionSaga/putRegionSaga';
import ScoutsApplicantsSaga from './scoutsApplicantsSaga/scoutsApplicantsSaga';
import RecruitmentsApplicationSaga from './recruitmentsApplicationSaga/recruitmentsApplicationSaga';
import ApplicationCompanySaga from './applicationComapnySaga/applicationComapnySaga';
import InvitationSaga from './invitationSaga/invitationSaga';
import InitSaga from './initSaga/initSaga';
import DownloadApplicationCsvSaga from './downloadApplicationCsvSaga/downloadApplicationCsvSaga';
import ArticlesRecruiterDetailSaga from './articlesRecruiterDetailSaga/articlesRecruiterDetailSaga';
import RecruitmentsCopySaga from './recruitmentsCopySaga/recruitmentsCopySaga';
import RecruiterRecruitmentsDetailSaga from './recruiterRecruitmentsDetailSaga/recruiterRecruitmentsDetailSaga';
import RecruiterPricingSaga from './recruiterPricingSaga/recruiterPricingSaga';
import ArticlesSubmitSaga from './articlesSubmitSaga/articlesSubmitSaga';
import ArticlesDeleteSaga from './articlesDeleteSaga/articlesDeleteSaga';
import ArticlesCreateSaga from './articlesCreateSaga/articlesCreateSaga';
import ScoutsRecruitmentSaga from './scoutsRecruitmentSaga/scoutsRecruitmentSaga';
import ScoutsSaga from './scoutsSaga/scoutsSaga';
import ScoutsCompanySaga from './scoutsCompanySaga/scoutsCompanySaga';
import ArticlesImageSaga from './articlesImageSaga/articlesImageSaga';
import SavedConditionsSaga from './savedConditionsSaga/savedConditionsSaga';
import SavedConditionsDeleteSaga from './savedConditionsDeleteSaga/savedConditionsDeleteSaga';
import SavedConditionsCreateSaga from './savedConditionsCreateSaga/savedConditionsCreateSaga';
import RecruitersSaga from './recruitersSaga/recruitersSaga';
import SettingsSaga from './settingsSaga/settingsSaga';
import HandicapsSaga from './handicapsSaga/handicapsSaga';
import FavCountSaga from './favCountSaga/favCountSaga';
import RecruitmentsStepTwoSaga from './recruitmentsStepTwoSaga/recruitmentsStepTwoSaga';
import ScoutsCandidateSaga from './scoutsCandidateSaga/scoutsCandidateSaga';
import ScoutsSavedConditionsSaga from './scoutsSavedConditionsSaga/scoutsSavedConditionsSaga';
import RecruitmentsDeleteSaga from './recruitmentsDeleteSaga/recruitmentsDeleteSaga';
import RecruitmentsStatusChangeSaga from './recruitmentsStatusChangeSaga/recruitmentsStatusChangeSaga';
import ScoutsEntrySaga from './scoutsEntrySaga/scoutsEntrySaga';
import ApplicationCreateSaga from './applicationCreateSaga/applicationCreateSaga';
import ApplicantsPrSaga from './applicantsPrSaga/applicantsPrSaga';
import ApplicantDecideSaga from './applicantDecideSaga/applicantDecideSaga';
import HelloSaga from './helloSaga/helloSaga';
import ScoutAlertSaga from './scoutsAlertSaga/scoutsAlertSaga';
import OpenAiSaga from './openAiSaga/openAiSaga';
import AdminScoutMatchingJobsSaga from './adminScoutMatchingJobs/adminScoutMatchingJobs';
import AdminScoutApplicantsSaga from './adminScoutApplicantsSaga/adminScoutApplicnatsSaga';
import OpenAiGoogleSaga from './openGoogleAiSaga/openGoogleAiSaga';
import RecruiterPlanSaga from './recruiterPlanSaga/recruiterPlanSaga';
import RecruiterInvoiceSaga from './recruiterInvoiceSaga/recruiterInvoiceSaga';
import RecruiterInvoiceDetailSaga from './recruiterInvoiceDetailSaga/recruiterInvoiceDetailSaga';
import RecruiterPlanPostSaga from './recruiterPlanPostSaga/recruiterPlanPostSaga';
import RecruitmentsJobsSaga from './recruitmentsJobsSaga/recruitmentsJobs';
import AdminInvoiceSaga from './adminInvoiceSaga/adminInvoiceSaga';
import AdminCompanyListSaga from './adminCompanyListSaga/adminCompanyListSaga';
import AdminCompanyDetailSaga from './adminCompanyDetailSaga/adminCompanyDetailSaga';
import AdminInvoicePostSaga from './adminInvoicePostSaga/adminInvoicePostSaga';
import MasterInfoSaga from './masterInfoSaga/masterInfoSaga';
import recruiterReceiptPostSaga from './recruiterReceiptPostSaga/recruiterReceiptPostSaga';
import EmailChangeTokenSaga from './emailChangeTokenSaga/emailChangeTokenSaga';
import AdminRecruitmentListSaga from './adminRecruitmentListSaga/adminRecruitmentListSaga';
import AdminRecruitmentCsvSaga from './adminRecruitmentCsvSaga/adminRecruitmentCsvSaga';
import AdminApplicationsListSaga from './adminApplicationsListSaga/adminApplicationsListSaga';
import AdminNewsListSaga from './adminNewsListSaga/adminNewsListSaga';
import AdminScoutsCompanySaga from './adminScoutsComapnySaga/adminScoutsComapnySaga';
import AdminScoutsSaga from './adminScoutsSaga/adminScoutsSaga';
import AdminAdministratorsSaga from './adminAdministratorsSaga/adminAdministratorsSaga';
import AdminAdministratorsDeleteSaga from './adminAdministratorsDeleteSaga/adminAdministratorsDeleteSaga';
import AdminApplicantsListSaga from './adminApplicantsListSaga/adminApplicantsListSaga';
import AdminApplicantsCsvSaga from './adminApplicantsCsvSaga/adminApplicantsCsvSaga';
import AdminAdministratorsRegionsSaga from './adminAdministratorsRegionsSaga/adminAdministratorsRegionsSaga';
import AdminScoutsDetailSaga from './adminScoutsDetailSaga/adminScoutsDetailSaga';
import AdminNewsNewSaga from './adminNewsNewSaga/adminNewsNewSaga';
import AdminOpenJobRegionsSaga from './adminOpenJobRegionsSaga/adminOpenJobRegionsSaga';
import AdminCompanyAllListSaga from './adminCompanyAllListSaga/adminCompanyAllListSaga';
import AdminRecruitmentPostSaga from './adminRecruitmentPostSaga/adminRecruitmentPostSaga';
import AdminRecruitmentDetailSaga from './adminRecruitmentDetailSaga/adminRecruitmentDetailSaga';
import AdminRecruitmentEditSaga from './adminRecruitmentEditSaga/adminRecruitmentEditSaga';
import AdminRecruitmentCopySaga from './adminRecruitmentCopySaga/adminRecruitmentCopySaga';
import AdminRecruitmentJobOfferSaga from './adminRecruitmentJobOfferSaga/adminRecruitmentJobOfferSaga';
import AdminApplicantsRegionsSaga from './adminApplicantsRegionsSaga/adminApplicantsRegionsSaga';
import MatomeListSaga from './matomeListSaga/matomeListSaga';
import MatomeDetailSaga from './matomeDetailSaga/matomeDetailSaga';
import AdminApplicantsPostSaga from './adminApplicantsPostSaga/adminApplicantsPostSaga';
import AdminApplicantsDetailSaga from './adminApplicantsDetailSaga/adminApplicantsDetailSaga';
import AdminNewsCompanyDetailSaga from './adminNewsCompanyDetailSaga/adminNewsCompanyDetailSaga';
import AdminCompanyRecruiterSaga from './adminCompanyRecruiterSaga/adminCompanyRecruiterSaga';
import AdminCompanyRegionsSaga from './adminCompanyRegionsSaga/adminCompanyRegionsSaga';
import AdminCompanyNewSaga from './adminCompanyNewSaga/adminCompanyNewSaga';
import SummarySaga from './summarySaga/summarySaga';
import AdminRecommendJobsSaga from './adminRecommendJobs/adminRecommendJobs';
import AdminRecommendApplicantsSaga from './adminRecommendApplicantsSaga/adminRecommendApplicantsSaga';
import AdminInvoiceDetailSaga from './adminInvoiceDetailSaga/adminInvoiceDetailSaga';
import AdminInvoiceDetailPostSaga from './adminInvoiceDetailPostSaga/adminInvoiceDetailPostSaga';
import AdminApplicantsDeleteSaga from './adminApplicantsDeleteSaga/adminApplicantsDeleteSaga';
import AdminApplicationsStatusPostSaga from './adminApplicationsStatusPostSaga/adminApplicationsStatusPostSaga';
import RecruiterUpdateAllStatusSaga from './recruiterUpdateAllStatusSaga/recruiterUpdateAllStatusSaga';
import RecruiterNewsSaga from './recruiterNewsSaga/recruiterNewsSaga';

export function* rootSaga() {
  // yield all([fork(postsSaga)]);
  yield fork(RecruitmentsSaga);
  yield fork(RecruitmentsListSaga);
  yield fork(RecruitmentsSearchSaga);
  yield fork(RecruitmentsDetailSaga);
  yield fork(RecruitmentsPublicListSaga);
  yield fork(RecruitmentsApplicationSaga);
  yield fork(ColumnFetchListSaga);
  yield fork(ColumnFetchItemSaga);
  yield fork(RegionsSaga);
  yield fork(MasterInfoSaga);
  yield fork(ArticlesSaga);
  yield fork(LoginSaga);
  yield fork(ApplicantSaga);
  yield fork(CompaniesSaga);
  yield fork(ApplicantsSaga);
  yield fork(ApplicationsSaga);
  yield fork(MessagesSaga);
  yield fork(InterviewsSaga);
  yield fork(PostCodeSaga);
  yield fork(MessagesPostSaga);
  yield fork(ChangeUpdateStatusSaga);
  yield fork(EmailChangeSaga);
  yield fork(EmailChangeTokenSaga);
  yield fork(TopRecruiterSaga);
  yield fork(PasswordResetSaga);
  yield fork(ResetSaga);
  yield fork(signupSaga);
  yield fork(ArticlesRecruiterSaga);
  yield fork(ChangeUpdateApplicantsSaga);
  yield fork(DownloadCareerSaga);
  yield fork(DownloadResumeSaga);
  yield fork(ArticlesImagesSaga);
  yield fork(RecruitmentsCompanySaga);
  yield fork(PreviewSaga);
  yield fork(stepSaga);
  yield fork(ColumnsSaga);
  yield fork(CountSaga);  
  yield fork(PutRegionSaga);
  yield fork(ScoutsApplicantsSaga);
  yield fork(ApplicationCompanySaga);
  yield fork(InvitationSaga);
  yield fork(InitSaga);
  yield fork(DownloadApplicationCsvSaga);
  yield fork(ArticlesRecruiterDetailSaga);
  yield fork(RecruitmentsCopySaga);
  yield fork(RecruiterRecruitmentsDetailSaga);
  yield fork(RecruiterPricingSaga);
  yield fork(ArticlesSubmitSaga);
  yield fork(ArticlesDeleteSaga);
  yield fork(ArticlesCreateSaga);
  yield fork(ScoutsRecruitmentSaga);
  yield fork(ScoutsSaga);
  yield fork(ScoutsCompanySaga);
  yield fork(ArticlesImageSaga);
  yield fork(SavedConditionsDeleteSaga);
  yield fork(SavedConditionsSaga);
  yield fork(SavedConditionsCreateSaga);
  yield fork(RecruitersSaga);
  yield fork(SettingsSaga);
  yield fork(HandicapsSaga);
  yield fork(FavCountSaga);
  yield fork(RecruitmentsStepTwoSaga);
  yield fork(ScoutsCandidateSaga);
  yield fork(ScoutsSavedConditionsSaga);
  yield fork(RecruitmentsDeleteSaga);
  yield fork(RecruitmentsStatusChangeSaga);
  yield fork(ScoutsEntrySaga);
  yield fork(ApplicationCreateSaga);
  yield fork(ApplicantsPrSaga);
  yield fork(ApplicantDecideSaga);
  yield fork(HelloSaga);
  yield fork(OpenAiSaga);
  yield fork(ScoutAlertSaga);
  yield fork(AdminScoutMatchingJobsSaga);
  yield fork(AdminRecommendJobsSaga);
  yield fork(AdminScoutApplicantsSaga);
  yield fork(OpenAiGoogleSaga);
  yield fork(RecruiterPlanSaga);
  yield fork(RecruiterInvoiceSaga);
  yield fork(RecruiterInvoiceDetailSaga);
  yield fork(RecruiterPlanPostSaga);
  yield fork(RecruitmentsJobsSaga);
  yield fork(AdminInvoiceSaga);
  yield fork(AdminInvoiceDetailSaga);
  yield fork(AdminInvoiceDetailPostSaga);
  yield fork(AdminInvoicePostSaga);
  yield fork(AdminCompanyListSaga);
  yield fork(AdminCompanyRegionsSaga);
  yield fork(AdminRecruitmentListSaga);
  yield fork(AdminRecruitmentPostSaga);
  yield fork(AdminRecruitmentDetailSaga);
  yield fork(AdminRecruitmentJobOfferSaga);
  yield fork(AdminRecruitmentCopySaga);
  yield fork(AdminRecruitmentEditSaga);
  yield fork(AdminApplicantsListSaga);
  yield fork(AdminApplicationsListSaga);
  yield fork(AdminApplicationsStatusPostSaga);
  yield fork(AdminScoutsSaga);
  yield fork(AdminScoutsDetailSaga);
  yield fork(AdminScoutsCompanySaga);
  yield fork(AdminAdministratorsSaga);
  yield fork(AdminNewsListSaga);
  yield fork(AdminNewsNewSaga);
  yield fork(AdminRecruitmentCsvSaga);
  yield fork(AdminApplicantsCsvSaga);
  yield fork(AdminCompanyDetailSaga);
  yield fork(AdminCompanyRecruiterSaga);
  yield fork(AdminCompanyNewSaga);
  yield fork(AdminNewsCompanyDetailSaga);
  yield fork(AdminAdministratorsDeleteSaga);
  yield fork(AdminAdministratorsRegionsSaga);
  yield fork(MatomeListSaga);
  yield fork(MatomeDetailSaga);
  yield fork(AdminApplicantsRegionsSaga);
  yield fork(AdminOpenJobRegionsSaga);
  yield fork(AdminCompanyAllListSaga);
  yield fork(recruiterReceiptPostSaga);
  yield fork(AdminApplicantsPostSaga);
  yield fork(AdminApplicantsDeleteSaga);
  yield fork(AdminApplicantsDetailSaga);
  yield fork(SummarySaga);
  yield fork(AdminRecommendApplicantsSaga);
  yield fork(RecruiterUpdateAllStatusSaga);
  yield fork(RecruiterNewsSaga);
}
