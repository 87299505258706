import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchRecruitmentsDetailRequest } from "../../redax/actions/recruitmentsDetailActions/recruitmentsDetailActions";
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import './detail.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y, Navigation, Thumbs, Scrollbar } from "swiper/modules";
import RecruitmentCardList from "../../components/RecruitmentCardList/RecruitmentCardList";
import OtherColumnsOfCompany from "../../components/OtherColumnsOfCompany/OtherColumnsOfCompany";
import { fetchLoginRequest } from "../../redax/actions/loginActions/loginActions";
import { fetchFavCountRequest } from "../../redax/actions/favCountActions/favCountActions";
function Detail() {
  const { id } = useParams();
  let company = [];
  let recruitment: any= [];
  let recruitment_id = 0;
  let images = [] ;
  let companyImages = [];
  let period = [];
  let work_location = [];
  let work_time = [];
  let holidays = [];
  let annual_holiday = [];
  let vacations = [];
  let holidays_supplemental = [];
  let corporate_cultures =[];
  let salary = [];
  let retirement_money_exists = [];
  let foundedYear = [] ;
  let similar_recruitments = [];
  let allowances = [];
  let annual_number_of_bonus_exists = [];
  let people_with_disabilities_display: any[] = [];
  let company_recruitments = [];
  let work_environments = [];
  let master = [];
  let target = [];
  let postcode = '';
  let prefecture = '';
  let city = '';
  let address = '';
  let windowWidth = window.innerWidth;
  const [isLogin, setIsLogin] = useState(false);
  const [loginType, setLoginType] = useState('');
  const [load, setLoad] = useState(false);
  const [firstFav, setFirstFav] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [is_favorite, setIsFavorite] = useState(false);
  const [is_active, setIsActive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [CompanyFavCount, setCompanyFavCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    dispatch(fetchRecruitmentsDetailRequest(id));
    window.scrollTo(0,0);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchRegionsRequest());
  }, [dispatch]);

  const { recruitmentsDetail } = useSelector(
    (state: RootState) => state.recruitmentsDetail
  );
  const loginData = useSelector((state: RootState) => state.login);
  const favCount = useSelector((state: RootState) => state.favCount);

  function formatDisplay (value: any, char: any) {
    if (!value) { return null}
    return value.reduce((str: any, current: any, index: any) => {
      if (index == 0) {
        return str.concat(current.name)
      } else {
        return str.concat(char, current.name)
      }
    }, '')
  }

  useEffect(() => {}, [dispatch]);


  const handleBack = () => {
    window.history.back(); 
};

  useEffect(() => {
    if (loginData.pending) {
      if(loginData.login.authenticate.resource_type == 'Applicant') {
        setIsLogin(true);        
        setIsActive(loginData.login.is_active);
      }
      setLoginType(loginData.login.authenticate.resource_type);
    }
  }, [loginData.pending]);

  useEffect(() => {
    if (favCount.pending) {
      if(favCount.count.favorite_count != null && favCount.count.favorite_count != undefined) {
        setCompanyFavCount(favCount.count.favorite_count);
      }
      if(favCount.count.favorite != null && favCount.count.favorite != undefined) {
        setFavorite(favCount.count.favorite);
      }
    }
  }, [favCount.pending]);
  
  function fetchFavorite(fav: any) {
    dispatch(fetchFavCountRequest(recruitmentsDetail.recruitment.id, 'post', 'recruitment'));
    if(fav) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }

  const { regions  } = useSelector(
    (state: RootState) => state.regions
  );
  if(recruitmentsDetail.recruitment !== undefined) {
    // company = recruitmentsDetail.company;
    recruitment = recruitmentsDetail.recruitment;
    if(!firstFav) {
      setFirstFav(true);
      setIsFavorite(recruitmentsDetail.recruitment.is_favorite);
    }
  }
  const pageTitle = recruitment.job_title+'／'+recruitment.company+'｜パラちゃんねる';
  document.title = pageTitle;

  if(recruitment.work_location) {
    postcode = recruitment.work_location.postcode;
    prefecture = recruitment.work_location.prefecture;
    city = recruitment.work_location.city;
    address = recruitment.work_location.address;
  }

  if(recruitmentsDetail.company !== undefined) {
    company = recruitmentsDetail.company;
    if(!load && recruitmentsDetail.company) {
      setLoad(true);
      dispatch(fetchFavCountRequest(recruitmentsDetail.company.id, 'get', 'company'));
      if(localStorage.getItem('jobParachannelAuthToken')) {
        setTimeout( () => {
          dispatch(fetchFavCountRequest(recruitmentsDetail.company.id, 'check', 'company'));
        }, 1500);
      }
    }
    // recruitment = recruitmentsDetail.recruitment;
    // recruitment_id = recruitmentsDetail.recruitment.id;
  }
  if(company.founded !== undefined){
    foundedYear = company.founded;
  }

  if(recruitment.images !== undefined) {
    images = recruitment.images;
  }
  if(recruitment.period !== undefined) {
    period = recruitment.period;
  }
  if(recruitment.work_location !== undefined) {
    work_location = recruitment.work_location;
  }
  if( recruitment.work_time !== undefined) {
    work_time = recruitment.work_time;
  }

  if (work_time.holidays !== undefined) {
    holidays = work_time.holidays;
  }

  if(work_time.annual_holiday !== undefined) {
    annual_holiday = work_time.annual_holiday;
  }
  if (work_time.vacations !== undefined) {
    vacations = work_time.vacations;
  }
  if(work_time.holidays_supplemental !== undefined) {
    holidays_supplemental = work_time.holidays_supplemental;
  }
  if(recruitment.salary !== undefined) {
    salary = recruitment.salary;
  }
  if(salary.allowances !==undefined) {
    allowances = salary.allowances;
  }
  if(salary.annual_number_of_bonus_exists !== undefined) {
    annual_number_of_bonus_exists = salary.annual_number_of_bonus_exists
  }
  if(recruitment.retirement_money_exists !== undefined) {
    retirement_money_exists = recruitment.retirement_money_exists;
  }
  if(recruitment.target !== undefined) {
    target = recruitment.target;
  }
  if(company.people_with_disabilities_display !== undefined) {
    people_with_disabilities_display = Object.entries(company.people_with_disabilities_display);
  }
  if(company.images !== undefined){
    companyImages = company.images;
  }
  if(company.corporate_cultures !== undefined) {
    corporate_cultures = company.corporate_cultures;
  }
  if(recruitment.work_environments !== undefined) {
    work_environments = recruitment.work_environments;
  }
  if(recruitment.company_recruitments !== undefined) {
    company_recruitments = recruitment.company_recruitments;
  }
  if(recruitment.similar_recruitments !== undefined) {
    similar_recruitments = recruitment.similar_recruitments;
  }

  if(regions !== undefined) {
    master = regions;
  }

  function filterConsiderationConditions () {
    return function (typeId: any) {
      if (!recruitment.consideration_conditions) return []
      return recruitment.consideration_conditions.filter((v: { consideration_condition_type_id: any; }) => v.consideration_condition_type_id == typeId)
    }
  }
  function googleMapSrc () {
     const addr = postcode + '+' + prefecture + city + address
    // 個別対応(正常にgooglemapの値が取れない)
      if (recruitment.id == 22429) {
        const addr = "905-0422+沖縄県国頭郡今帰仁村崎山1330-1+今帰仁村字仲尾次334"
        return '//maps.google.co.jp/maps?&output=embed&q=' + addr + '&hl=ja&z=16&t=m'
      }
      return '//maps.google.co.jp/maps?&output=embed&q=' + addr + '&hl=ja&z=16&t=m'
  }


  const tabSwitching = (tabIndex: any) => {
    setActiveTab(tabIndex);
  };
  function updateFavCompany(fav: any) {
    dispatch(fetchFavCountRequest(recruitmentsDetail.company.id, 'post', 'company'));
    if(fav) {
      setFavorite(true);
      setCompanyFavCount(CompanyFavCount+1);
    } else {
      setFavorite(false);
      setCompanyFavCount(CompanyFavCount-1);
    }
  }

  function checkStatus () {
    if (isLogin && !is_active) {
      if (window.confirm('お仕事探しをお休み中です。再開しますか？')) {
        navigate('/mypage/settings/');
        return;
      }
      return;
    }
    return navigate('/recruitments/'+ recruitment.id +'/entry/'), window.location.reload();
  }

  return (
    <main id="jobs">
      <section className={`detail wrapper ${activeTab === 0 ? 'type1' : 'type2'}`}>

      <div className="cont_box">
        {activeTab == 0  ?<h2 className="ttl"> {recruitment.job_title}</h2> : <h2 className="ttl">どんな会社？</h2>}
        {activeTab == 0  ?
          <div>
            {images.length == 0 ? 
              (<p>画像はありません</p>)
              :
              <>
                <Swiper
                  className ="slider slider_for slider-header"
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[Navigation, A11y, Thumbs]}
                  navigation
                  onSlideChange={() => console.log('slide change')}
                >
                  { images.map((image: any, index: any) => {
                          return (
                          <SwiperSlide key={index} >
                            <li key={index} className= "swiperSlide" style={{ height:"500px"}}>
                              <img src={image.url} alt="image.comment" style={{ width:"fit-content"}}/>
                              <p className="caption">{ image.comment }</p>
                            </li>
                          </SwiperSlide>
                          );
                        })}
                </Swiper>
                <Swiper
                className ="slider slider_nav"
                // onSwiper={setThumbsSwiper}
                watchSlidesProgress
                slidesPerView={6}
                modules={[Thumbs]}
                // centeredSlides
                >
                  <div className="slick-list">
                  {images.map((image: any, index: any) => {
                          return (<>
                            <SwiperSlide key={index}>
                              <li key={index} className="swiperSlideNav">
                                <img src={image.url} alt="image.comment"  />
                              </li>
                            </SwiperSlide>
                          </>);
                      })}
                  </div>
                </Swiper>
              </>
            }
            {recruitment.corporate_culture_text ?
            <>
            <h3 className="marg">社風・職場の雰囲気</h3>
            <p>{ recruitment.corporate_culture_text }</p>
            </>
            : <>
            </>}

            {recruitment.business_flow ?
            <>
            <h3 className="marg">1日の業務の流れ</h3>
            <p>{ recruitment.business_flow }</p>
            </>
            : <></>}

            {recruitment.superior_message ?
            <>
            <h3 className="marg">先輩社員からのコメント</h3>
            <p>{ recruitment.superior_message }</p>
            </>
            : <></>}

            {recruitment.career_path ?
            <>
            <h3  className="marg">キャリアパス</h3>
            <p>{ recruitment.career_path }</p>
            </>
            : <></>}

            {recruitment.consideration_condition_text ?
            <>
            <h3  className="marg">現場での合理的配慮例</h3>
            <p>{ recruitment.consideration_condition_text }</p>
            </>
            : <></>}

            {recruitment.hr_message ?
            <>
            <h3  className="marg">採用担当メッセージ</h3>
            <p>{ recruitment.hr_message }</p>
            </>
            : <></>}
            {recruitment.movie_path ?
            <>
            <h3  className="marg">動画</h3>
            <p className="movie"><iframe data-v-47758f5f="" width="854" height="480" src= {recruitment.movie_path}  allow="autoplay; encrypted-media"></iframe></p>
            </>
            : <></>}
            <h3  className="marg">求める人物像</h3>
            <ul className="tag">
              {recruitment.compatibilities && recruitment.compatibilities.map((data: any, index: any) => (
                <li key={index}>{data}</li>
              ))}
            </ul>
            {/* {recruitment.consideration_conditions.length > 0 && (
              <div>
                <h3>合理的配慮</h3>
                {master.consideration_condition_types.map((type: any) => {
                  if (filteredConditions.length > 0) {
                    return (
                      <div key={type.id}>
                        <p className="tag_ttl">{type.name}</p>
                        <ul className="tag">
                          {filteredConditions.map((data:any) => (
                            <li key={data.id}>{data.name}</li>
                          ))}
                        </ul>
                      </div>
                    );
                  } else {
                    return null; // or any other fallback element if no conditions exist
                  }
                })}
              </div>
            )} */}
            {work_environments.length ?
            <>
            <h3  className="marg">職場環境</h3>
            <ul className="tag">
              {work_environments && work_environments.map((data: any, index: any) => (
                <li key={index}>{data}</li>
              ))}
            </ul>
            </>
            : <></>}
          <div>
            <h3 className="marg">勤務地 地図</h3>
            <iframe src= {googleMapSrc()}  className="map"></iframe>
          </div>
          <RecruitmentCardList recruitment={recruitment.company_recruitments} />
          {windowWidth > 768 ?
          <OtherColumnsOfCompany company={company} showMoreBtn = {true} />
          : <></>}
        </div>
       : activeTab == 1 ? (
        <>
          {companyImages.length == 0 ? (
            <p>画像はありません</p>
          ): (
            <>
            <Swiper
                  className ="slider slider_for"
                  // install Swiper modules
                  // thumbs={{ swiper: thumbsSwiper }}
                  modules={[Navigation, A11y]}
                  navigation
                  onSlideChange={() => console.log('slide change')}
                >
                  { companyImages.map((image: any, index: any) => {
                          return (
                          <SwiperSlide key={index} >
                            <li key={index} className= "swiperSlide"   style={{ height: windowWidth > 768 ? "500px"  : "400px" }} >
                              <img src={image.url} alt="image.comment" style={{ width:"auto",margin:'auto',height:'100%', objectFit: 'contain', maxWidth: '100%'}} />
                              <p className="caption">{ image.comment }</p>
                            </li>
                          </SwiperSlide>
                          );
                        })}
                </Swiper>
                <Swiper
                className ="slider slider_nav"
                slidesPerView={6}
                // onSwiper={setThumbsSwiper}
                // centeredSlides
                >
                  <div className="slick-list">
                  {companyImages.map((image: any, index: any) => {
                          return (
                            <SwiperSlide key={index} >
                              <li key={index} className="swiperSlideNav">
                                <img src={image.url} alt="image.comment"  />
                              </li>
                            </SwiperSlide>
                          );
                      })}
                  </div>
                </Swiper>
              </>

          )}
          {windowWidth >  769 ? (
            <>
            {corporate_cultures.length ? (
              <>
              <h3>社風</h3>
              <ul className="tag">
                {corporate_cultures.map((data: any) => (
                    <li key={data.id}>{data.name}</li>
                ))}
              </ul>
              </>
            ):<></>}
            <RecruitmentCardList recruitment={recruitment.company_recruitments} />
            <OtherColumnsOfCompany company={company} showMoreBtn = {true} />
            {/* <MoleculesRecruitmentCardList :recruitments="recruitment.company_recruitments" />

              <!-- コラム3件 -->
              <OrganismsColumnOtherColumnsOfCompany
                :company="company"
              /> */}
            </>
          ) : <></>}

          {/* <template v-if="windowWidth > 769">
          <template v-if="company.corporate_cultures.length">
            <!-- 社風タグ -->
            <h3>社風</h3>
            <ul class="tag">
              <li v-for="data in company.corporate_cultures" :key="data.id">
                {{ data.name }}
              </li>
            </ul>
          </template>
          <!-- この企業の求人 -->
          <MoleculesRecruitmentCardList :recruitments="recruitment.company_recruitments" />

          <!-- コラム3件 -->
          <OrganismsColumnOtherColumnsOfCompany
            :company="company"
          />
        </template> */}
       </>
       ): <></>
       }
      </div>
      <div className="sidebar">
        <ul className="tab_btn top">
          <li className={activeTab === 0 ? 'active' : ''}>
            <a href="" onClick={() => tabSwitching(0)}>採用情報</a>
          </li>
          <li className={activeTab === 1 ? 'active' : ''}>
            <a href="#" onClick={() => tabSwitching(1)}>会社概要</a>
          </li>
        </ul>
        {activeTab === 0 ?
        <div className="tab_box">
          <h3>{ recruitment.company }</h3>
          <dl>
            <dt>職種名</dt>
            <dd>{ recruitment.job_title }</dd>
            <dt>職種</dt>
            <dd>{ recruitment.job_category }</dd>
            <dt>業務内容</dt>
            <dd><span className="white_space">{ recruitment.business_content }</span></dd>
            {recruitment.required_experience != null && recruitment.required_experience != '' ?
            <>
              <dt>必要な経験・<br/>資格</dt>
              <dd><span className="white_space">{ recruitment.required_experience }</span></dd>
            </>: <></>}
            {recruitment.type_of_recruiting_company ?
            <>
            <dt>募集する<br/>企業の区分</dt>
            <dd>{ recruitment.type_of_recruiting_company }</dd>
            </>
            : <></>}
            <dt>雇用形態</dt>
            <dd>{ recruitment.employment_status }</dd>
            {recruitment.employment_status != '正社員' ?
            <>
            <dt>雇用期間</dt>
            <dd>
              <strong>雇用期間有無：</strong>{ period.contract_period_exists }<br/>
              {period.contract_period ?
              <span>
                <strong>契約期間：</strong>{ period.contract_period }<br/>
              </span>
              : <></>}
              
              <strong>契約更新：</strong>{ period.contract_renewal_exists }<br/>
              {period.contract_period_supplemental ?
              <span>
                <strong>その他補足：</strong>
                <br/>
                <span className="white_space">{ period.contract_period_supplemental }</span>
              </span>
              : <></>}
              
            </dd>
            </>
            :<></>}
            <dt>試用期間</dt>
            <dd>
              <strong>試用期間有無：</strong>{ period.trial_period_exists }<br/>
              {period.trial_period ?
              <span>
                <strong>試用期間：</strong>{ period.trial_period }<br/>
              </span>
              : <></>}

              {period.trial_period_supplemental ?
              <span>
                <strong>その他補足：</strong>
                <br/>
                <span className="white_space">{ period.trial_period_supplemental }</span>
              </span>
              : <></>}
            </dd>
            {recruitment.employment_status != '正社員' ?
              <>
              <dt>契約社員or<br/>正社員登用</dt>
              <dd>
                { period.full_time_employee_exists }
              </dd>
              </>
            : <></>}
            <dt>住所<br/>（勤務地）</dt>
            <dd>
              {work_location.work_from_home ?
              <span>
                <strong>働き方・テレワーク：</strong>
                { work_location.work_from_home }
                <br/>
              </span>
              : <></>}

              {work_location.department?
              <span>
                <strong>支店・部署名：</strong>
                { work_location.department }
                <br/>
              </span>
               : <></>}
              
              
              {work_location.work_from_home_id != 1 ?
              <>
                <strong>住所：</strong><br/>
                  〒{ work_location.postcode }<br/>
                  { work_location.prefecture }
                  { work_location.city }
                  { work_location.address }<br/>

                  {work_location.nearest_station ?
                  <span>
                    <strong>最寄り駅：</strong>
                    { work_location.nearest_station }
                    <br/>
                  </span>
                  : <></>}
                  {work_location.transportation ?
                  <span>
                    <strong>最寄り駅からの交通手段：</strong>
                    { work_location.transportation }
                    <br/>
                  </span>
                  : <></>}
                  {work_location.nationwide ?
                  <span>
                      <strong>転勤の有無：</strong>
                      { work_location.nationwide }
                      <br/>
                  </span>
                  : <></>}
              </>
              : <></>}
              {work_location.work_location_supplemental ?
              <span>
                <strong>勤務地補足：</strong>
                { work_location.work_location_supplemental }
                <br/>
              </span>
              : <></>}
              
            </dd>
            <dt>勤務時間</dt>
            <dd>
              <span>{ work_time.start_time1 } 〜 { work_time.end_time1 }</span><br/>
              {work_time.start_time2 ?
                <span>
                  { work_time.start_time2 } 〜 { work_time.end_time2 }<br/>
                </span>
              : <></>}

              {work_time.start_time3 ?
                <span>
                  { work_time.start_time3 } 〜 { work_time.end_time3 }<br/>
                </span>
              : <></>}
              {work_time.work_time_supplemental ?
              <span>
                <strong>勤務時間補足：</strong>
                <br/>
                <span className="white_space">{ work_time.work_time_supplemental }</span>
              </span>
              : <></>}
            </dd>
            <dt>休憩時間</dt>
            <dd>
              <strong>休憩時間：</strong>{ work_time.rest_time }<br/>
              {work_time.rest_time_supplemental ?
              <span>
                <strong>その他補足：</strong>
                <br/>
                <span className="white_space">{ work_time.rest_time_supplemental }</span>
              </span>
              : <></>}
            </dd>
            <dt>勤務日</dt>
            <dd>
              <strong>勤務日数：</strong>{ work_time.number_of_working_days }日／週<br/>
              {work_time.number_of_working_days_supplemental ?
              <span >
                <strong>勤務日数補足：</strong>
                <br/>
                <span className="white_space">{ work_time.number_of_working_days_supplemental }</span>
              </span>
              : <></>}
            </dd>
            <dt>残業</dt>
            <dd>
              <strong>残業有無：</strong>{ work_time.overtime_hours_exists }<br/>
              {work_time.overtime_hours_supplemental ?
              <span>
                <strong>残業時間補足：</strong>
                <br/>
                <span className="white_space">{ work_time.overtime_hours_supplemental }</span>
              </span>
              : <></>}
            </dd>
            {holidays.length !== 0 ||
              annual_holiday !== null ||
              vacations.length !== 0 ||
              holidays_supplemental !== '' ? (
              <>
                <dt>休日</dt>
                <dd>
                  {holidays.length !== 0 && (
                    <span>
                      <strong>休日：</strong>
                      {formatDisplay(holidays, ' ')}
                      <br />
                    </span>
                  )}
                  {annual_holiday && (
                    <span>
                      <strong>年間休日：</strong>
                      {annual_holiday}日
                      <br />
                    </span>
                  )}
                  {vacations.length !== 0 && (
                    <span>
                      <strong>休日タグ：</strong>
                      {vacations.map((data: any) => (
                        <span key={data.name}>{data.name}、</span>
                      ))}
                      <br />
                    </span>
                  )}
                  {holidays_supplemental && (
                    <span>
                      <strong>その他補足：</strong>
                      <br />
                      <span className="white_space">
                        {holidays_supplemental}
                      </span>
                    </span>
                  )}
                </dd>
              </>
            ) : <></>}
              <dt>給与・賞与</dt>
              <dd>
              <strong>給与方式：</strong>{ salary.salary_form }<br/>
              <strong>基本給：</strong>{ salary.lower_monthly_basic_salary } 〜 { salary.upper_monthly_basic_salary }円<br/>
              {allowances.length != 0 ? (
                <span>
                  <strong>各種手当：</strong><br/>
                  { formatDisplay(allowances, '、') }
                  <br/>
                </span>
              ): <></>}
              {salary.allowances_supplemental ? (
                <span>
                  <strong>各種手当補足：</strong>
                  <span className="white_space">{ salary.allowances_supplemental }</span>
                  <br/>
                </span>
              ) : <></>}
              <strong>賞与：</strong>{annual_number_of_bonus_exists }<br/>
              {salary.annual_number_of_bonus_exists && salary.annual_number_of_bonus ? (
                <span>
                  <strong>賞与補足：</strong>
                  <span className="white_space">{ salary.annual_number_of_bonus }</span>
                  <br/>
                </span>
              ):<></>}
              {salary.salary_form == '月給' ? (
                <span>
                  <strong>想定年収：</strong>{ salary.lower_estimated_annual_income } 〜 { salary.upper_estimated_annual_income }円<br/>
                </span>
              ) :<></>} 
              <strong>昇給：</strong>{salary.annual_number_of_raises_exists}<br/>
              ※評価・業績により変動します。<br/>
              {salary.annual_number_of_raises ? (
                <span>
                  <strong>昇給補足：</strong><br/>
                  <span className="white_space">{ salary.annual_number_of_raises }</span>
                </span>
              ) : <></>}  
            </dd>
            <dt>福利厚生</dt>
            <dd>
              <strong>社会保険：</strong>{ formatDisplay(recruitment.social_insurances, '、') }<br/>
              <strong>退職金制度：</strong>{ retirement_money_exists }<br/>
              {salary.annual_number_of_raises ? (
                <span>
                  <strong>定年：</strong>{ recruitment.retirement_age }
                </span>
              ) : <></>}

              {recruitment.benefit_supplemental ? (
                <span>
                  <strong>その他補足：</strong>
                  <br/>
                  <span className="white_space">{ recruitment.benefit_supplemental }</span>
                </span>
              ) : <></>}
            </dd>
            <dt>選考プロセス</dt>
            <dd>
            <strong>選考方法：</strong><br/>
            { formatDisplay(recruitment.selection_methods, '、') }<br/>
            {recruitment.selection_method_supplemental ? (
              <span v-show="recruitment.selection_method_supplemental">
                <strong>その他特記事項：</strong>
                <br/>
                <span className="white_space">{ recruitment.selection_method_supplemental }</span>
              </span>
            ) : <></>}
          </dd>
          <dt>想定採用人数</dt>
          <dd>{ target.number_of_employees }人</dd>
          </dl>
        </div>
        : 
        <div  className="tab_box">
          <div className="logo_box">
            <img src={company.logo}  alt="company.name"/>
            <h2>{ company.name }</h2>
          </div>
          {isLogin ? (
            <div className="follow_box">
              {favorite ?
              <a
              className="follow_btn"
               onClick={() => updateFavCompany(false)}
              >FOLLOWED</a>
              : <a
              className="follow_btn"
               onClick={() => updateFavCompany(true)}
              >＋ FOLLOW</a>}
              <p>{ CompanyFavCount }</p>
            </div>

          ) : 
          <div className="follow_box">
            <p>FOLLOW { CompanyFavCount }</p>
          </div>   
          }
          <dl>
            {company.url ?(
              <dt>URL</dt>
            ) :<></>}
            {company.url ?(
              <dd>
                <a href= {company.url} target="_blank">{ company.url }<span className="material-icons">launch</span></a>
              </dd>
            ) :<></>}
            <dt>住所<br/>（本社）</dt>
            <dd>〒 { company.postcode }<br/>
            { company.prefecture }{ company.city }{ company.address }</dd>
            {company.industry ? (
              <dt>業種分類</dt>
            ): <></>}
            {company.industry ? (
              <dd>{ company.industry }</dd>
            ): <></>}
            {company.business_content ? (
              <dt>事業内容</dt>
            ) : <></>}
            {company.business_content ? (
              <dd >
                <span className="white_space">{ company.business_content }</span>
              </dd>
            ) : <></>}
            {foundedYear.year ? (
              <dt>設立年月</dt>
            ):  <></>}
            {foundedYear.year ? (
             <dd>
             { foundedYear.year }年{foundedYear.month ? foundedYear.month + '月' : '' }
             </dd>
            ): <></>}
            {company.number_of_employees ? (
              <dt>従業員数</dt>
            ): <></>}
             {company.number_of_employees ? (
              <dd>{ company.number_of_employees }人</dd>
            ): <></>}
            {company.capital ? (
              <dt>資本金</dt>
            ) : <></>}
            {company.capital ? (
              <dd >{ company.capital }</dd>
            ) : <></>} 
            {company.sales ? (
              <dt>売上高</dt>
            ):<></>} 
            {company.sales ? (
              <dd >{ company.sales }</dd>
            ):<></>}
            <dt>株式</dt>
            <dd>{ company.stock_listing }</dd>
            <dt>障がい者<br/>雇用実績</dt>
            {company.people_with_disabilities_exists == '無' ? (
              <dd>
              { company.people_with_disabilities_exists }
            </dd>
            ) :
            <dd>
              {people_with_disabilities_display && people_with_disabilities_display.map((item: any) => (
                <>
                 {item[0]} : {item[1]}<br key={item[0]}></br>
                </>
              ))}
            </dd>
            }
          </dl>
        </div>
        }
        <ul className="tab_btn bottom">
          <li className={activeTab === 0 ? 'active' : ''}>
            <a href="" onClick={() => tabSwitching(0)}>採用情報</a>
          </li>
          <li className={activeTab === 1 ? 'active' : ''}>
            <a href="#" onClick={() => tabSwitching(1)}>会社概要</a>
          </li>
        </ul>
      </div>

      {windowWidth <= 768 && activeTab == 0 ? (
        <div className="cont_box">
          <RecruitmentCardList recruitment={recruitment.company_recruitments} />
          <OtherColumnsOfCompany company={company} showMoreBtn = {true} />
        </div>
      ):windowWidth <= 768 && activeTab == 1 ? (
        <div className="cont_box">
          {corporate_cultures.length ? (
            <>
            <h3>社風</h3>
            <ul className="tag">
              {corporate_cultures.map((data: any) => (
                <li key= {data.id}>
                  {data.name}
                </li>
              ))}
            </ul>
            </>
          ) : <></>}
          <div className="link_box link_box_cont_box">
            {recruitment.company_recruitments.length > 0 ? (
              <>
              <h3>この企業の求人</h3>
              <ul>
              {recruitment.company_recruitments.map((data: any) => (
                <li key= {data.id} >
                  <a href= {'/recruitments/' + data.id }>
                  <div className="img_box"><img src= {data.thumbnail} alt=""/></div>
                  <div className="text_box">
                    <p className="company">{data.company_name}</p>
                    <p>{data.job_title}</p>
                  </div>
                  </a>
                </li>
                ))}
              </ul>
              </>
            ) : <></>}
            <div className="etc_box"></div>
          </div>
          <RecruitmentCardList recruitment={recruitment.company_recruitments} />
          <OtherColumnsOfCompany company={company} showMoreBtn = {true} />
        </div>
      ) : <></>}
      

      {recruitment.status_id == 2 ? (
      <p className="entry_txt">
            エントリー後、10日間以内に求人企業からの返答がない場合は、<br className="pc_only"/>システムにより自動で不採用ステータスへと変更されます。
      </p>
      ) : 
      <p className="entry_txt">
        現在、応募受付を行っていない求人のためエントリーできません。
      </p>
      }

      <div className="btn_area">
        {isLogin && is_favorite ? (
          <a onClick={() => fetchFavorite(false)} className="btn type3 favarited not_height">
            お気に入りに登録済み
            <img src="/assets/img/jobs/icon10.svg" alt="" />
          </a>
        ) : isLogin && !is_favorite ? (
          <a onClick={() => fetchFavorite(true)} className="btn type3 favarite not_height">
            お気に入りに登録
            <img src="/assets/img/jobs/icon03.svg" alt="" />
          </a>
        ) : <></>}
        {!isLogin && recruitment.status_id == 2 && loginType == '' ? 
                <div onClick={() => handleBack()}  className="btn type3 favarited not_height">
                求人一覧へ
              </div>
        : ""}

        {isLogin && recruitment.already_applied ? (
          <a href="javascript:void(0)" className="btn type3 gray">
            エントリー済み
          </a>
        ) : isLogin && recruitment.status_id == 2 ? (
          <a href="javascript:void(0)" onClick={checkStatus} className="btn type3">
            エントリーする
            <img src="/assets/img/jobs/arrow03.svg" alt=""/>
          </a>
        ) : !isLogin && recruitment.status_id == 2 && loginType == '' ?
        (
          <a href={'/login/'} className="btn type3">
            エントリーする
            <img src="/assets/img/jobs/arrow03.svg" alt=""/>
          </a>
        ) : <></>}
        
      </div>
      {isLogin ? 
      <div className="btn_area" style={{margin: 0 }} onClick={() => handleBack()}>
        <a  className="btn type3 favarited not_height">
          求人一覧へ
        </a>
      </div>
      : <></>}
      



      <div className="link_box">
      {similar_recruitments.length ?
        <div className="etc_box">
          <h3 className="ttl">「{recruitment.job_title}」に似ている求人</h3>
            <ul>
            {similar_recruitments && similar_recruitments.map((data: any) => (
              <li key={data.id}>
                <a href={'/recruitments/' + data.id }>
                  <div className="img_box"><img src={data.thumbnail} alt=""/></div>
                  <div className="text_box">
                    <p className="company">{data.company_name}</p>
                    <p>{data.job_title}</p>
                  </div>
                </a>
              </li>
            ))}
            </ul>
        </div>
        : <></>}
      </div>
      </section>
    </main>
  );
}

export default Detail;
