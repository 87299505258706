import { recruiterNewsTypes } from "../../ActionTypes/recruiterNewsTypes";
import { RecruiterNewsActions, RecruiterNewsState } from "../../types/type";



const initialState: RecruiterNewsState = {
  pending: false,
  news: [],
  error: null,
};

export default (state = initialState, action: RecruiterNewsActions,) => {
  switch (action.type) {
    case recruiterNewsTypes.FETCH_RECRUITER_NEWS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case recruiterNewsTypes.FETCH_RECRUITER_NEWS_SUCCESS:
      return {
        ...state,
        pending: true,
        news: action.payload.news,
        error: null
      };
    case recruiterNewsTypes.FETCH_RECRUITER_NEWS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
