import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import dayjs from 'dayjs';
import Loader from '../loader/loader';
import { fetchAdminInvoiceDetailRequest } from '../../redax/actions/adminInvoiceDetailActions/adminInvoiceDetailActions';
import { fetchAdminInvoiceDetailPostRequest } from '../../redax/actions/adminInvoiceDetailPostActions/adminInvoiceDetailPostActions';


const AdminInvoicePlan = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const { id } = useParams();
    const adminInvoiceDetail = useSelector((state: RootState) => state.adminInvoiceDetail);
    const adminInvoiceDetailPost = useSelector((state: RootState) => state.adminInvoiceDetailPost);
    const [loadScreen, setLoadScreen] = useState(true);
    const [planName, setPlanName] = useState('');
    const [basePrice, setBasePrice] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [scoutAgency, setScoutAgency] = useState('');
    const [minGuarantee, setMinGuarantee] = useState('');
    const [optionName1, setOptionName1] = useState('');
    const [optionName2, setOptionName2] = useState('');
    const [price1, setPrice1] = useState<any>(0);
    const [price2, setPrice2] = useState<any>(0);
    const [price, setPrice] = useState<any>(0);
    const [price0, setPrice0] = useState<any>(0);
    const [comapanyName, setComapnyName] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [deadline, setDeadline] = useState('');
    const [receiptAddress, setReceiptAddress] = useState('');
    const [discount, setDiscount] = useState(Number);


    

    const [options, setOptions] = useState<any>([]);
    const [tmpOptions, setTmpOptions] = useState<any>([]);

    const [check, setCheck] = useState(false);


    
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
          navigate('/admin/login');
        } else {
          dispatch(fetchAdminInvoiceDetailRequest(id));
        //   setFirstLoad(true);
        }    
      }, [dispatch]);


      useEffect(() => {
        if (adminInvoiceDetail.pending) {
          if(adminInvoiceDetail.invoiceDetail.receipt_address != null &&  adminInvoiceDetail.invoiceDetail.receipt_address2 != null){
            const tmp = adminInvoiceDetail.invoiceDetail.receipt_address + " " + adminInvoiceDetail.invoiceDetail.receipt_address2;
            setReceiptAddress(tmp);
          }
            setLoadScreen(false);
            setDiscount(adminInvoiceDetail.invoiceDetail.discount || 0);
            setComapnyName(adminInvoiceDetail.invoiceDetail.company_name);
            setPlanName(adminInvoiceDetail.invoiceDetail.plan_name);
            setBasePrice(adminInvoiceDetail.invoiceDetail.base_price);
            setTotalPrice(adminInvoiceDetail.invoiceDetail.total_price);
            setOptions(adminInvoiceDetail.invoiceDetail.options);
            setCreatedAt(adminInvoiceDetail.invoiceDetail.created_at);
            const lastDay = getLastDayOfNextMonth(adminInvoiceDetail.invoiceDetail.created_at)
            setDeadline(lastDay);

        }  
      }, [adminInvoiceDetail]);


      function getLastDayOfNextMonth(createdAt: any) {
        const date = new Date(createdAt);
        date.setMonth(date.getMonth() + 2);
    
        date.setDate(1);
    
        date.setDate(0);
    
        const lastDayOfMonth = 
            date.getFullYear() + '/' + 
            (date.getMonth() + 1).toString().padStart(2, '0') + '/' + 
            date.getDate().toString().padStart(2, '0');
    
        return lastDayOfMonth;
    }



    function getLastDayOfMonth(deadline: string): string {
        const [year, month] = deadline.replace('年', '-').replace('月', '').split('-');
        const lastDay = new Date(Number(year), Number(month), 0);
        lastDay.setDate(lastDay.getDate() + 1);
        const formattedDate = lastDay.toISOString().split('T')[0].replace(/-/g, '/');
        return formattedDate;
    }
    


      useEffect(() => {
        if (adminInvoiceDetailPost.pending && check) {
            setLoadScreen(false);
                // navigate('/admin/invoice_check');
                window.location.href = '/admin/invoice_check';
        }  
      }, [adminInvoiceDetailPost]);

      useEffect(() => {
        if(options.length > 0 ){
            let tmp = [];
            for(let i = 0; i < options.length; i++){
                if(options[i].name == "スカウト代行"){
                    let str = options[i].name + '50件' + options[i].price.toLocaleString() + '円/月 (税別)';
                    setScoutAgency(str);
                    setPrice( options[i].price);
                } else if (options[i].name == "最低保証"){
                    let str1 = options[i].name + options[i].price.toLocaleString() + '円/月 (税別)';
                    setMinGuarantee(str1);
                    setPrice0( options[i].price);
                } else {
                    tmp.push(options[i]);
                }
            }
            setTmpOptions(tmp);
        }
      }, [options]);




 
    

      const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
        // console.log(windowSize.width);
        
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowSize]);

      const printRef:any = useRef(null)

      
  return (
    <main id='adminHome'>
        <AdminNav/>
        {loadScreen ? <Loader></Loader> : <></>}
        <section className='invoiceDetailHeight' style={{width:"100%", marginBottom:"20%"}}>
            <div style={{width:"100%",padding:"0px 4%", marginTop:"30px"}}>
            <div ref={printRef} id='board' style={windowSize.width > 768 ? {borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%", width:"80%", maxWidth: '675px', margin:"auto"} : {borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%"}}>
                <h1 className='adminH1' style={{textAlign:"center"}}>請求書</h1>
                <div style={{display:"flex", paddingBottom: "20px"}}>
                  <div style={{width:"60%"}}>
                    <br />
                    <h2 className='adminH3'>{receiptAddress === '' ? comapanyName + ' 御中' : receiptAddress}</h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <h2 className='adminH3'>発行日 :  {createdAt && createdAt.split('T')[0].split('-')[0] + '/' + createdAt.split('T')[0].split('-')[1] + '/' + createdAt.split('T')[0].split('-')[2]}</h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <h2 className='adminH3'>有効期限 : {deadline && deadline }</h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <p className='ap'>案件名 : パラちゃんねる</p>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <p className='ap'>下記のとおりご請求申し上げます。</p>
                    <br />
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                      <h2 className='adminH3'>合計金額</h2>
                      <h2 className='adminY' style={{margin: "-3px 15px"}}>¥ {( (Number(totalPrice) *  adminInvoiceDetail.invoiceDetail.tax_rate /100 + Number(totalPrice))).toLocaleString()}</h2>
                    </div>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                  </div>
                  {Number(createdAt.substring(0, 4)) >= 2025 ? (<>
                  <div style={{width:"35%", margin:"auto", background:"#ECF9FF",padding:"10px"}}>
                    <div style={windowSize.width > 768 ? {width:"100%", padding:"5%", display:"flex"} : {}}>
                      <img src="/assets/img/logo.png" style={windowSize.width > 768 ? {width:"53px"} : {width:"38px", height:"38px", float:"left"}} alt="" />
                      <div>
                        <p className='newP' style={{color:"black"}}>特定非営利活動法人<br/> こんぺいとう企画</p>
                      </div>
                    </div>
                    <div style={windowSize.width >= 768 ? {} : {}}>
                      <div>
                        <p className='newP' style={{color:"black"}}>〒874-0927</p>
                        <p className="newP" style={{color:"black"}}>大分県別府市弓ヶ浜町1番28号別府コージェマンション301号</p>
                        <br />
                      {/* <img src="/assets/img/mystamp.png" style={windowSize.width > 768 ? {width:"64px",height:"64px" , float:"right"} : {width:"40px",height:"40px" , float:"right"}} alt="" /> */}
                        {/* <p className="newP" style={{color:"black"}}>インボイス番号T8010001188958</p> */}
                      </div>
                    </div>
                  </div>
                  </>) : (<>
                    
                    <div style={{width:"35%", margin:"auto", background:"#ECF9FF",padding:"10px"}}>
                    <div style={windowSize.width > 768 ? {width:"100%", padding:"5%", display:"flex"} : {}}>
                      <img src="/assets/img/logo.png" style={windowSize.width > 768 ? {width:"53px"} : {width:"38px", height:"38px", float:"left"}} alt="" />
                      <div>
                        <p className='newP' style={{color:"black"}}>株式会社キャリアート</p>
                        <p className='newP' style={{color:"black"}}>〒102-0083</p>
                      </div>
                    </div>
                    <div style={windowSize.width >= 768 ? {} : {}}>
                      <div>
                        <p className="newP" style={{color:"black"}}> 東京都千代田区麹町4-8-19 キャトルセゾン麹町 5階</p>
                        <br />
                      <img src="/assets/img/mystamp.png" style={windowSize.width > 768 ? {width:"64px",height:"64px" , float:"right"} : {width:"40px",height:"40px" , float:"right"}} alt="" />
                        {/* <p className="newP">TEL 000-0000-0000</p> */}
                        <p className="newP" style={{color:"black"}}>インボイス番号T8010001188958</p>
                      </div>
                    </div>
                  </div>

                  </>)}
                </div>
                <br />
                <br />
                  <table className='' style={{ width: "100%"}}>
                    <thead className='adminNThead' style={{}}>
                      <tr style={{width:"100%" , wordBreak:"break-word", }}>
                        <th className='adminNTheadTh'>プラン内容</th>
                        {/* <th className='adminNTheadTh'>期間</th> */}
                        <th className='adminNTheadTh' style={{whiteSpace: "nowrap"}}>単位</th>
                        <th className='adminNTheadTh' style={{width:"20%"}}>金額</th>                         
                      </tr>
                    </thead>
                    <tbody>
                      <tr>                
                        <td className='tdN'>{planName}</td>
                        {/* <td className='tdN'></td> */}
                        {/* <td className='tdN'>{createdAt.split('T')[0].split('-')[0] + '年' + createdAt.split('T')[0].split('-')[1] + '月' + '年' + createdAt.split('T')[0].split('-')[2] + '日'}</td> */}
                        <td className='tdN'>1</td>
                        <td className='tdN'>¥ {basePrice.toLocaleString()}</td>
                      </tr> 
                      { options.map((e:any, index: React.Key | null | undefined) => (
                        <tr key={index}>                
                          <td className='tdN'>{e.name}</td>
                          {/* <td className='tdN'>{}</td> */}
                          <td className='tdN'>{e.quantity}</td>
                          <td className='tdN'>¥ {(e.price != 0 ? e.price : -e.discount).toLocaleString()}</td>
                        </tr> 
                        ))}
                        {discount < 0 ?
                        <tr>
                            <td className='tdN'>割引</td>
                            <td className='tdN'></td>
                            <td className='tdN'>¥{discount.toLocaleString()}</td>
                        </tr>
                         : <></>}
                      <tr>
                        {/* <td></td> */}
                        <td></td>
                        <td></td>
                        <td className='tdLastPrice' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>小計</p> <p className='nP'>¥ {totalPrice.toLocaleString()}</p></td>
                      </tr>
                      <tr>
                        {/* <td></td> */}
                        <td></td>
                        <td></td>
                        <td className='tdN' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>消費税</p><p className='nP'>¥ {(Number(totalPrice) *  adminInvoiceDetail.invoiceDetail.tax_rate /100 ).toLocaleString()} </p></td>
                      </tr>
                      <tr>
                        {/* <td></td> */}
                        <td></td>
                        <td></td>
                        <td className='tdLastTotalPrice' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>合計</p > <p className='nP'>¥ {( (Number(totalPrice) *  adminInvoiceDetail.invoiceDetail.tax_rate /100 + Number(totalPrice))).toLocaleString()}</p ></td>
                      </tr>
                      
                    </tbody>
                    {/* <div style={{position:"relative"}}> */}
                      <div style={{position:"relative", top:"-50px"}}>
                      <div style={{border:"1px solid gray", borderRadius:"10px", padding:"2%"}}>
                        <p style={{fontSize: "16px", fontWeight:"bold", marginBottom: "1px"}} >振込先情報</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>金融機関名 : 三菱UFJ銀行</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom:"1px"}}>支店名 : 目白支店（641）</p>
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>振込先 :カ）キャリアート</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{}}>口座番号 : （普通）0312469</p> 
                        <p style={{ color:"red"}}>お振込手数料は.御社負担にてお願い致します</p> 
                      </div>
                    </div>
                  </table>

                  <br />
                    {/* <textarea style={{height:"100px", width:"100%"}} placeholder='備考:必要であれば'  name="" id=""></textarea> */}
            </div>
            <br />
            <div style={{display:"flex" , justifyContent:"center", width:"100%", maxWidth: '675px', margin:"auto"}}>
              <button className='invoicePrintBtn' onClick={()=>navigate('/admin/invoice_detail/' + id)} style={{ width:"100%", marginBottom: '25px'}}>金額を変更</button>
            </div>
            <div style={{display:"flex" , justifyContent:"center", width:"100%", maxWidth: '675px', margin:"auto"}}>
              <button className='invoiceBackToListBtn' onClick={()=> navigate('/admin/invoice_check')} style={{ width:"100%"}}>完了</button>
            </div>
            <br/>
            {/* <div style={{display:"flex" , justifyContent:"center"}}>
              <button onClick={()=>{navigate("/recruiter/invoice")}} className='invoiceDetailBtn' style={{border: "1px solid #C4C4C4"}}>閉じる</button>
            </div> */}
            <br></br>
        </div>
      </section>
      {windowSize.width > 430 ? <div></div> : <div><br></br><br /><br /><br /><br /><br /><br /><br /><br /></div> }
    </main>
  )
}

export default AdminInvoicePlan
