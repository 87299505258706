import { RootState } from '../../redax/reducers/rootReducer';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruitmentsRequest } from "../../redax/actions/recruitmentsActions/recruitmentsActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from "swiper/modules";
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import './recruitmentsJobs.scss'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { fetchRecruitmentsJobsRequest } from '../../redax/actions/recruitmentsJobsActions/recruitmentsJobsActions';
import Loader from "../loader/loader";

function NewPage() {
    const dispatch = useDispatch();
    const { recruitments , pending} = useSelector(
      (state: RootState) => state.recruitments
    );
    const recruitmentsJobs = useSelector(
      (state: RootState) => state.recruitermentsJobs
    );

    const { regions  } = useSelector(
        (state: RootState) => state.regions
    );
    const loginData = useSelector((state: RootState) => state.login);

    const [ prefectureId , setprefectures] = useState('');
    const [selectJobCategoryTtypes , setjob_category_types] = useState('');
    const [selectEmploymentStatuses , setemployment_statuses] = useState('');
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [isLogin, setLogin] = useState(false);
    const navigate = useNavigate();
    const pageTitle = 'パラちゃんねる';
    const [numbers, setNumbers] = useState<any>([]);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);
    const [loadScreen, setLoadScreen] = useState(true);
    document.title = pageTitle;
    // let limit = 40;





  const pageDecriment = () => {
    if (page <= 1) {
      setPage(1);
      return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    fetchRecruitmentsJobsRequest(page)
  }

  const pageIncriment = () => {
    if (page >= pages) {
      setPage(pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    fetchRecruitmentsJobsRequest(page)
  }

  const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    fetchRecruitmentsJobsRequest(page)
  }


    useEffect(() => {
        if(localStorage.getItem('jobParachannelAuthToken')){
            dispatch(fetchLoginRequest('', '',''));
            if(loginData !== undefined){
              if(loginData.pending){
                setLogin(true);
              }
            }
        }
      }, [dispatch, localStorage.getItem('jobParachannelAuthToken')]);

    useEffect(() => {
        dispatch(fetchRecruitmentsRequest());
        dispatch(fetchRegionsRequest());
        
    }, [dispatch]);


    useEffect(() => {
        dispatch(fetchRecruitmentsJobsRequest(page));
    }, [dispatch,page]);
    const [jobsList1, setJobsList1] = useState([]);
    
    useEffect(() => {
        if(prefectureId !== '' || selectJobCategoryTtypes !== '' ||selectEmploymentStatuses !== '' ){
            setSearchDisabled(false);
        }
        if(recruitmentsJobs.pending){
            setLoadScreen(false);   
            setJobsList1(recruitmentsJobs.recruitments.recruitments);
            // let pageNumber = parseInt('' + recruitmentsJobs.recruitments.recruitments.length / limit);
            // if (recruitmentsJobs.recruitments.recruitments.length  % limit != 0) {
            //     pageNumber++;
            // }
            setPages(recruitmentsJobs.recruitments.pagenation.total_pages);
            // let c= [];            
            // for(let i = 1; i <= pages; i++) {
            //     c.push(i);
            // }
            // setNumbers(c);
            // if(recruitmentsJobs.recruitments.recruitments.length != 0) {
            //   setLoadScreen(false);      
            // } else { 
            //   setLoadScreen(true);            
            // }
        }
      },[prefectureId,selectJobCategoryTtypes,selectEmploymentStatuses,recruitmentsJobs.pending])

    let prefectures = [];
    let search_regions = [];
    let employment_statuses = [];
    let job_category_types = [];
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('consideration_conditions', 'true');

    if(regions.prefectures !== undefined){
        prefectures = regions.prefectures;
    }

    if(regions.job_category_types !== undefined){
        job_category_types = regions.job_category_types;
    }

    if(regions.employment_statuses !== undefined){
        employment_statuses = regions.employment_statuses;
    }

    if(regions.search_regions !== undefined){
        search_regions = regions.search_regions;
    }


    const replacedContent = (value: any) => {
        if (value == null) {
            return null
        }
        return value.replaceAll("\n", '</br>');
    }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowSize]);
      

    return (
        <main id="top">
          {loadScreen ? <Loader /> : <></>}
            <br />
            <section className="article wrapper">
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/premium.svg" alt=""></img>
                        <span>プレミアム求人 </span> <span style={{ fontWeight:'400'}}>（うち <span style={{color:'#00699F', fontWeight:'400'}}>{jobsList1.length > 0 ? jobsList1.length : '' } </span> 件を表示）</span>
                    </h2>
                    <br></br>
                    <div className="link_box_jobs">
                        {/* {Array.isArray(jobsList1) && jobsList1.slice((page - 1)*limit, limit + (page - 1)*limit).map((data:any) => ( */}
                        {windowSize.width <= 768 ? 
                          <ul style={{width: "100%", display: "flex", flexWrap: "wrap",}}>
                          {Array.isArray(jobsList1) && jobsList1.map((data: any) => {
                            if (data && data.lower_monthly_basic_salary !== undefined && data.upper_monthly_basic_salary !== undefined) {
                              return (
                              <li className="premium_jobs_wrapper" style={{width:  "100%", padding: "20px"}}>
                                  <a href={'/recruitments/'+ data.id }>
                                      <div className ="img_box relat">
                                        <div className="ribbon19-content">
                                          <span className="ribbon19">PREMIUM</span>
                                        </div>
                                        <img src= {data.thumbnail} ></img>
                                      </div>
                                      <h3 className="ttl_jobs2">{data.job_title} / {data.company_name}</h3>
                                      <p>{data.salary_form && data.salary_form} {data.lower_monthly_basic_salary && data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary && data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                      <p className="category">{data.employment_status}</p>
                                      <p className="new_graduate">{data.new_graduate}</p>
                                      <p className="job_address">{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                      {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                      {data.is_new_publish ? <p className="publish" style={{color:"#fff", padding:"3px 10px", background:"#f00", borderRadius:"5px", width: "30%"}}>新着求人</p>: <></>}
                                      {data.is_new_update ? <p  className="update" style={{color:"#fff", padding:"3px 10px", background:"#8cc63f", borderRadius:"5px"}}>最終更新：24時間以内</p>: <></>}
                                      {data.view_count ? 
                                            <div className='smallheart'>
                                                <img src="/assets/img/smallHeart.svg" alt="" /> 
                                                <p>{data.view_count}</p>
                                            </div> : <></>}
                                  </a>
                              </li>
                              );
                              }
                              return null;
                          })}
                          </ul>  
                        
                        : 
                        <ul style={{width: "100%", display: "flex", flexWrap: "wrap",}}>
                            {Array.isArray(jobsList1) && jobsList1.map((data: any) => {
                              if (data && data.lower_monthly_basic_salary !== undefined && data.upper_monthly_basic_salary !== undefined) {
                                return (
                                <li className="premium_jobs_wrapper" style={{width:  "32.5%", padding: "20px"}}>
                                    <a href={'/recruitments/'+ data.id }>
                                      <div className ="img_box relat">
                                        <div className="ribbon19-content">
                                          <span className="ribbon19">PREMIUM</span>
                                        </div>
                                        <img src= {data.thumbnail} ></img>
                                      </div>
                                        <h3 className="ttl_jobs2">{data.job_title} / {data.company_name}</h3>
                                        <p>{data.salary_form && data.salary_form} {data.lower_monthly_basic_salary && data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary && data.upper_monthly_basic_salary.toLocaleString() } 円 </p>
                                        
                                        <p className="category">{data.employment_status}</p>
                                        <p className="new_graduate">{data.new_graduate}</p>
                                        <p className="job_address">{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                        {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        {data.is_new_publish ? <p className="publish" style={{color:"#fff", padding:"3px 10px", background:"#f00", borderRadius:"5px", width: "27%"}}>新着求人</p>: <></>}
                                        {data.is_new_update ? <p  className="update" style={{color:"#fff", padding:"3px 10px", background:"#8cc63f", borderRadius:"5px"}}>最終更新：24時間以内</p>: <></>}
                                        {data.view_count ? 
                                            <div className='smallheart'>
                                                <img src="/assets/img/smallHeart.svg" alt="" /> 
                                                <p>{data.view_count}</p>
                                            </div> : <></>}
                                    </a>
                                </li>
                              );
                            }
                            return null;
                        })}
                        </ul>  
                        }
                                              
                        {/* :<></>} */}
                    </div>
                </div>
                <div>
                    <ul className="pagination_circle">
                        <li className={"prev " + (page == 1 ? "disabled" : "")}>
                        <a onClick={pageDecriment}>前へ</a>
                        </li>
                        {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                            <li key={p} className={page === p ? 'active' : ''}>
                            <a onClick={() => updatePage(p)}>
                                <span>{p}</span>
                            </a>
                            </li>
                        ))}
                        {/* {numbers.map((e:any) => (
                        <li className={page == e ? 'active' : ''}>
                        <a onClick={() => updatePage(e)}>
                            <span>{e}</span>
                        </a>
                        </li>
                        ))} */}
                        <li className={"next " + (page == pages ? "disabled" : "")}>
                        <a onClick={pageIncriment}>次へ</a>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
    );
}

export default NewPage
