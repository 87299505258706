import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import { fetchPaymentDetailRequest } from '../../../redax/actions/recruiterInvoiceDetailActions/recruiterPaymentDetailActions';
import { RootState } from '../../../redax/reducers/rootReducer';

const Paymentdetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const invoiceDetail = useSelector((state: RootState) => state.recruiterInvoiceDetail);
    const loginData = useSelector((state: RootState) => state.login);
    const currentPath = window.location.pathname;
    const id = currentPath.split('/').pop();
    const [detailData, setDetailData] = useState([]);
    const [optionsData, setOptionsData] = useState([]);
    const [basePrice, setBasePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [receipt_address, setReceipt_address] = useState("");
    const [receipt_address2, setReceipt_address2] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [planName, setPlanName] = useState("");
    const [period, setPeriod] = useState("");
    const [taxPrice, setTaxPrice] = useState(0);
    const [taxRate, setTaxRate] = useState(0);
    const [discount, setDiscount] = useState(0);

    
    const today = new Date();
    const targetDate = new Date('2025-01-01');
    
    useEffect(() => {
      if (localStorage.getItem('jobParachannelAuthToken')) {
        dispatch(fetchPaymentDetailRequest(id));
      } else {
        navigate('/recruiter_lp/');
      }
    }, [dispatch, navigate]);
    
    useEffect(() => {
      if (invoiceDetail && invoiceDetail.pending) {
        setDiscount(invoiceDetail.recruiterPaymentDetail.discount || 0);
        setDetailData(invoiceDetail.recruiterPaymentDetail || []);
        setCompanyName(invoiceDetail.recruiterPaymentDetail.company_name || []);
        setTotalPrice(invoiceDetail.recruiterPaymentDetail.total_price || 0);
        setPlanName(invoiceDetail.recruiterPaymentDetail.plan_name || []);
        setCreatedAt(invoiceDetail.recruiterPaymentDetail.created_at || []);
        setTaxPrice(invoiceDetail.recruiterPaymentDetail.tax_price || 0);
        setTaxRate(invoiceDetail.recruiterPaymentDetail.tax_rate || 0);
        setBasePrice(invoiceDetail.recruiterPaymentDetail.base_price || 0);
        setOptionsData(invoiceDetail.recruiterPaymentDetail.options || []);
        setPeriod(invoiceDetail.recruiterPaymentDetail.period);
        setReceipt_address(invoiceDetail.recruiterPaymentDetail.receipt_address ? invoiceDetail.recruiterPaymentDetail.receipt_address : loginData.login.receipt_address);
        setReceipt_address2(invoiceDetail.recruiterPaymentDetail.receipt_address2 ? invoiceDetail.recruiterPaymentDetail.receipt_address2 : loginData.login.receipt_address2);

      }
      console.log(basePrice)
    }, [invoiceDetail])

    const lastDayOfMonth = getLastDayOfNextMonth(createdAt);


    function getLastDayOfNextMonth(createdAt: any) {
      const date = new Date(createdAt);
      date.setMonth(date.getMonth() + 2);
  
      date.setDate(1);
  
      date.setDate(0);
  
      const lastDayOfMonth = 
          date.getFullYear() + '/' + 
          (date.getMonth() + 1).toString().padStart(2, '0') + '/' + 
          date.getDate().toString().padStart(2, '0');
  
      return lastDayOfMonth;
  }
    
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  
    useEffect(() => {
      // Attach the event listener on component mount
      window.addEventListener('resize', handleResize);
      // console.log(windowSize.width);
      
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [windowSize]);
    const printRef:any = useRef(null)
    
    function OnClcickPrint (){
      const content = printRef.current?.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = originalContents;
        window.location.reload()
    }
    
  return (
    <main id='company' style={windowSize.height < 780 ? {paddingBottom:"300px"} : {}}>
        <MypageSideBar resource={undefined} familyName={undefined} currentList={undefined}/>
        <section className='invoiceDetailHeight' style={{width:"100%", marginBottom:"20%"}}>
            <div style={{width:"100%",padding:"0px 4%",}}>
            <ul className ="bread_crumb" style={{marginTop:"30px"}}>
              <li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/icon.png" style={{width:"20px", marginRight:"10px"}} alt="" /><a href="/recruiter/plan" style={{textDecoration:"none", color:"black"}}>プラン内容</a></li>
              <li style={{display:"flex", alignItems:"center"}}><img src="/assets/img/yun.png" style={{width:"25px", marginRight:"10px"}} alt="" /><a href="/recruiter/newpage1" style={{color:"#00A0E9"}}>請求一覧</a></li>
            </ul>
            <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
            <br />
            <div ref={printRef} id='board' style={windowSize.width > 768 ? {borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%", width:"80%", margin:"auto"} : {borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%"}}>
                <h1 className='adminH1' style={{textAlign:"center"}}>請求書</h1>
                <div style={{display:"flex", paddingBottom: "20px"}}>
                  <div style={{width:"60%"}}>
                    <br />
                    <h2 className='adminH3'>{receipt_address === '' ? companyName + ' 御中' : receipt_address + ' ' + receipt_address2}</h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <h2 className='adminH3'>発行日 : {createdAt.split('T')[0].replace("-", "/").replace("-", "/")} </h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <h2 className='adminH3'>有効期限 :{lastDayOfMonth} </h2>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <p className='ap'>案件名 : パラちゃんねる</p>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                    <p className='ap'>下記のとおりご請求申し上げます。</p>
                    <br />
                    <div style={{display:"flex"}}>
                      <h2 className='adminH3'>合計金額</h2>
                      <h2 className='adminY' style={{margin: "-3px 15px"}}>¥ {taxPrice.toLocaleString()}</h2>
                    </div>
                    <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                  </div>
                  {Number(createdAt.substring(0, 4)) >= 2025 ? (<>
                  <div style={{width:"35%", margin:"auto", background:"#ECF9FF",padding:"10px"}}>
                    <div style={windowSize.width > 768 ? {width:"100%", padding:"5%", display:"flex"} : {}}>
                      <img src="/assets/img/logo.png" style={windowSize.width > 768 ? {width:"53px"} : {width:"38px", height:"38px", float:"left"}} alt="" />
                      <div>
                        <p className='newP' style={{color:"black"}}>特定非営利活動法人<br/> こんぺいとう企画</p>
                      </div>
                    </div>
                    <div style={windowSize.width >= 768 ? {} : {}}>
                      <div>
                        <p className='newP' style={{color:"black"}}>〒874-0927</p>
                        <p className="newP" style={{color:"black"}}>大分県別府市弓ヶ浜町1番28号別府コージェマンション301号</p>
                        <br />
                      {/* <img src="/assets/img/mystamp.png" style={windowSize.width > 768 ? {width:"64px",height:"64px" , float:"right"} : {width:"40px",height:"40px" , float:"right"}} alt="" /> */}
                        {/* <p className="newP" style={{color:"black"}}>インボイス番号T8010001188958</p> */}
                      </div>
                    </div>
                  </div>
                  </>) : (<>
                    
                    <div style={{width:"35%", margin:"auto", background:"#ECF9FF",padding:"10px"}}>
                    <div style={windowSize.width > 768 ? {width:"100%", padding:"5%", display:"flex"} : {}}>
                      <img src="/assets/img/logo.png" style={windowSize.width > 768 ? {width:"53px"} : {width:"38px", height:"38px", float:"left"}} alt="" />
                      <div>
                        <p className='newP' style={{color:"black"}}>株式会社キャリアート</p>
                        <p className='newP' style={{color:"black"}}>〒102-0083</p>
                      </div>
                    </div>
                    <div style={windowSize.width >= 768 ? {} : {}}>
                      <div>
                        <p className="newP" style={{color:"black"}}> 東京都千代田区麹町4-8-19 キャトルセゾン麹町 5階</p>
                        <br />
                      <img src="/assets/img/mystamp.png" style={windowSize.width > 768 ? {width:"64px",height:"64px" , float:"right"} : {width:"40px",height:"40px" , float:"right"}} alt="" />
                        {/* <p className="newP">TEL 000-0000-0000</p> */}
                        <p className="newP" style={{color:"black"}}>インボイス番号T8010001188958</p>
                      </div>
                    </div>
                  </div>

                  </>)}

                </div>
                <br />
                <br />
                  <table className='' style={{ width: "100%"}}>
                    <thead className='adminNThead' style={{}}>
                      <tr style={{width:"100%" , wordBreak:"break-word", }}>
                        <th className='adminNTheadTh'>プラン内容</th>
                        <th className='adminNTheadTh' style={{whiteSpace: "nowrap",width:"15%"}}>期間</th>
                        <th className='adminNTheadTh' style={{whiteSpace: "nowrap",width:"15%"}}>単位</th>
                        <th className='adminNTheadTh' style={{width:"20%"}}>金額</th>                         
                      </tr>
                    </thead>
                    <tbody>
                      <tr>                
                        <td className='tdN'>{planName}</td>
                        <td className='tdN'>{period}</td>
                        {/* <td className='tdN'>{createdAt.split('T')[0].split('-')[0] + '年' + createdAt.split('T')[0].split('-')[1] + '月' + '年' + createdAt.split('T')[0].split('-')[2] + '日'}</td> */}
                        <td className='tdN'>1</td>
                        <td className='tdN'>¥ {basePrice.toLocaleString()}</td>
                      </tr> 
                      { optionsData.map((e:any, index) => (
                        <tr key={index}>                
                          <td className='tdN'>{e.name}</td>
                          <td className='tdN'>{}</td>
                          <td className='tdN'>{e.quantity}</td>
                          <td className='tdN'>¥ {e.price.toLocaleString()}</td>
                        </tr> 
                        ))}
                        {discount < 0 ?
                        <tr>
                            <td className='tdN'>割引</td>
                            <td className='tdN'></td>
                            <td className='tdN'></td>
                            <td className='tdN'>¥{discount.toLocaleString()}</td>
                        </tr>
                         : <></>}
                      
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='tdLastPrice' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>小計</p> <p className='nP'>¥ {totalPrice.toLocaleString()}</p></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='tdN' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>消費税</p> <p className='nP'>¥ {(totalPrice * taxRate / 100).toLocaleString()}</p></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='tdLastTotalPrice' style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}><p className='nP'>合計</p > <p className='nP'>¥ {taxPrice.toLocaleString()}</p ></td>
                      </tr>
                    </tbody>
                    {/* <div style={{position:"relative"}}> */}
                      <div style={{position:"relative", top:"-50px"}}>
                      {Number(createdAt.substring(0, 4)) >= 2025 ? (<>
                        <div style={{border:"1px solid gray", borderRadius:"10px", padding:"2%"}}>
                        <p style={{fontSize: "16px", fontWeight:"bold", marginBottom: "1px"}} >振込先情報</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>金融機関名 : GMOあおぞらネット銀行</p>
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom:"1px"}}>支店名 : 法人第二営業部（102）</p>
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>振込先 : トクヒ）コンペイトウキカク シヨウガイシヤコヨウ</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{}}>口座番号 : （普通）2053578</p> 
                        <p style={{ color:"red"}}>お振込手数料は.御社負担にてお願い致します</p> 
                      </div>
                      </>) : (<>
                        <div style={{border:"1px solid gray", borderRadius:"10px", padding:"2%"}}>
                        <p style={{fontSize: "16px", fontWeight:"bold", marginBottom: "1px"}} >振込先情報</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>金融機関名 : 三菱UFJ銀行</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom:"1px"}}>支店名 : 目白支店（641）</p>
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{marginBottom: "1px"}}>振込先 :カ）キャリアート</p> 
                        <hr style={{height:"1px", width:"100%", background:"#C4C4C4"}}/>
                        <p style={{}}>口座番号 : （普通）0312469</p> 
                        <p style={{ color:"red"}}>お振込手数料は.御社負担にてお願い致します</p> 
                      </div>
                      </>)}
                    </div>
                  </table>

                  <br />
                    {/* <textarea style={{height:"100px", width:"100%"}} placeholder='備考:必要であれば'  name="" id=""></textarea> */}
            </div>
            <br />
            <div style={{display:"flex" , justifyContent:"center"}}>
              <button className='invoicePrintBtn' onClick={OnClcickPrint}>印刷</button>
            </div>
            <br/>
            {/* <div style={{display:"flex" , justifyContent:"center"}}>
              <button onClick={()=>{navigate("/recruiter/invoice")}} className='invoiceDetailBtn' style={{border: "1px solid #C4C4C4"}}>閉じる</button>
            </div> */}
            <br></br>
        </div>
      </section>
      {windowSize.width > 430 ? <div></div> : <div><br></br><br /><br /><br /><br /><br /><br /><br /><br /></div> }
        
    </main>
  )
}

export default Paymentdetail
