import { useNavigate } from "react-router-dom";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchCompaniesRequest } from "../../../redax/actions/companiesActions/companiesActions";
import { fetchPostCodeRequest } from "../../../redax/actions/postcodeActions/postcodeActions";
import { fetchRegionsRequest } from "../../../redax/actions/regionsActions/regionsActions";
import React from "react";
import './company.scss';
import Loader from "../../loader/loader";
import { BrowserView, MobileView, isBrowser, isDesktop, isMobile } from 'react-device-detect';

export default function RecruiterCompany() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginData = useSelector((state: RootState) => state.login);
    const { regions  } = useSelector( (state: RootState) => state.regions);
    const { companies } = useSelector((state: RootState) => state.companies);
    const postcode = useSelector((state: RootState) => state.postcode);
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [corporate_number, setCorprateNumber] = useState<string | null>(null);
    const [company_name, setCompanyName] = useState<any>(null);
    const [company_name_kana, setCompanyNameKana] = useState<any>(null);
    const [companyUrl, setCompanyUrl] = useState<any>(null);
    const [companyPostCode, setCompanyPostCode] = useState<any>(null);
    const [companyRegionId, setRegionId] = useState<any>(null);
    const [prefecture_id, setPrefectureId] = useState<any>(null);
    const [city_id, setCityId] = useState<any>(null);
    const [Address1, setAddress1] = useState<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [logoFile, setLogoFile] = useState(false);
    const [error, setError] = useState(false);
    const [errorCode, setErrorCode] = useState(0);
    const [famylyNameError, setFamylyNameError] = useState(false);
    const [departmentError, setDepartmentError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [logoError, setLogoError] = useState(false);
    const [departmentMaxLengthError, setDepartmentMaxLengthError] = useState(false);
    const [famylyNameMaxLengthError, setFamylyNameMaxLengthError] = useState(false);
    const [famylyNameKanaMaxLengthError, setFamylyNameKanaMaxLengthError] = useState(false);
    const [famylyNameKanaError, setFamylyNameKanaError] = useState(false);
    const [errorCompanyName, setErrorComapnyName] = useState(false);
    const [errorCompanyNameKana, setErrorComapnyNameKana] = useState(false);
    const [errorCompanyNameMaxlength, setErrorComapnyNameMaxLength] = useState(false);
    const [errorCompanyNameKanaMaxlength, setErrorComapnyNameKanaMaxLength] = useState(false);
    const [errorKatakanaChk, setErrorKatakanaChk] = useState(false);
    const [famylyNameKatakanaChk, setFamylyNameKatakanaChk] = useState(false);
    const [errorMaxLenght, setErrorMaxLenght] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [prefecture_name, setPrefectureName] = useState(String);
    const [address, setAddress] = useState(String);
    const [disabled_address, setDisabledAddress] = useState(String);
    const [city_name, setCityName] = useState(String);
    const [btn, setBtn] = useState(false);
    const [isEditCity, setIsEditCity] = useState<any>(false);
    const [isInputValid, setIsInputValid] = useState(true);
    const [industry, setIndustry] = useState<any>(null);
    const [businessContent, setBusinessContent] = useState('');
    const [industries, setIndustries] = useState<any[]>([]);
    const [stock_listings, setStockList] = useState<any[]>([]);
    const [number_of_employees, setNumberOfEmployeesArr] = useState<any[]>([]);
    const [corporate_cultures, setCorporateCultures] = useState<any[]>([]);
    const [corporate_cultures_list, setCorporateCulturesList] = useState<any[]>([]);
    const [purpose_of_postings, setPurposeOfPostings] = useState<any[]>([]);
    const [ corporate_cultures_list_chk, setCorporateCultureChk] = useState<boolean[]>([]);
    const [establishmentYear, setEstablishmentYear] = useState('');
    const [establishmentMonth, setEstablishmentMonth] = useState(undefined);
    const [numberOfEmployees, setNumberOfEmployees] = useState('');
    const [department, setDepartment] = useState('');
    const [capital, setCapital] = useState('');
    const [sales, setSales] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [stockListing, setStockListing] = useState<any>(null);
    let company: any = [];
    let mainRecruiter: any = [];
    const [companyLogo, setCompanyLogo] = useState<any>(null);
    const [check, setPeopleWithDisabilitiesExists] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue2, setSelectedValue2] = useState('');
    const [selectedValue3, setSelectedValue3] = useState('');
    const [selectedValue4, setSelectedValue4] = useState('');
    const [selectedValue5, setSelectedValue5] = useState('');
    const [mainFamilyName, setMainFamilyName] = useState('');
    const [recruiterEmail, setRecruiterEmail] = useState('');
    const [mainFamilyNameKana, setMainFamilyKanaName] = useState('');
    const [culuture_limit, setCulutureLimit] = useState(12);
    const [isMoreCuluture, setIsMoreCuluture] = useState('もっと見る');
    const [companyImages, setCompanyImages] = useState<any[]>([]);
    const [imageShow, setImageShow] = useState<boolean[]>([]);
    const [currentImageNumber, setCurrentImageNumber] = useState(0);
    const imagesAdvices = ['会社や職場の雰囲気が分かる写真', '', '', '', ''];
    const [errorPostcode, setErrorPostcode] = useState(false);
    const [errorPostcodeNumeric, setErrorPostcodeNumeric] = useState(false);
    const [errorPostcodeMaxLength, setErrorPostcodMaxLength] = useState(false);
    const [erroraddressMaxLength, setErroraddressLength] = useState(false);
    const [errorbusinesscontentMaxLength, setErrorbusinesscontentLength] = useState(false);
    const [errorCompanyfoundedMaxLength, setErrorCompanyfoundedMaxLength] = useState(false);
    const [errorCompanyfoundedMinLength, setErrorCompanyfoundedMinLength] = useState(false);
    const [errorCompanyfoundedNumeric, setErrorCompanyfoundedNumeric] = useState(false);
    const [errorNumberOfEmployeesNumeric, setErrorNumberOfEmployeesNumeric] = useState(false);
    const [errorNumberOfEmployeesMaxLength, setErrorNumberOfEmployeesMaxLength] = useState(false);
    const [errorCapitalMaxLength, setErrorCapitalMaxLength] = useState(false);
    const [errorSalesMaxLength, setErrorSalesMaxLength] = useState(false);
    const [errorPhoneNumberMaxLength, setErrorPhoneNumberMaxLength] = useState(false);
    const [errorPhoneNumberMinLength, setErrorPhoneNumberMinLength] = useState(false);
    const [errorPhoneNumberNumeric, setErrorPhoneNumberNumeric] = useState(false);
    const [selectedPurpose, setSelectedPurpose] = useState<boolean[]>([]);
    const [selectedPurposeLst, setSelectedPurposeLst] = useState<any[]>([]);
    const [consideration_conditions, setConsiderationConditions] = useState<any[]>([]);
    const [mainRecruiter2, setMainRecruiter2] = useState<any>(null);
    const [load, setLoad] = useState(false);
    const [putMethod, setPutMethod] = useState(false);
    const [loadScreen, setLoadScreen] = useState(true);
    const [errorCultures, setErrorCultures] = useState(false);
    const [errorPeople, setErrorPeople] = useState(false);
    const color = {
      color: 'red'
    };
    const colorMobile = {
      color: 'red',
      width: '100%',
      marginLeft: '0px'
    };
    const pageTitle = '会社概要編集｜パラちゃんねる';
    document.title = pageTitle;
    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);


      useEffect(() => {
        if (loginData.pending) {
          if(!loginData.login.is_init_complete) {
            // navigate('/recruiter/init/');
            window.location.href = '/recruiter/init/';
          }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            dispatch(fetchCompaniesRequest(loginData.login.company.id, 'get', null));            
            // dispatch(fetchC());
        }
      }, [loginData.pending]);

      useEffect(() => {
        if(!putMethod) {
          if(companies.company !== undefined){
            setLoadScreen(false);
            company = companies.company;
            mainRecruiter = companies.main_recruiter;
            setMainRecruiter2(companies.main_recruiter);
            setSelectedPurposeLst(mainRecruiter.purpose_of_postings);
            setMainFamilyName(mainRecruiter.family_name);
            setRecruiterEmail(mainRecruiter.email);
            setPhoneNumber(mainRecruiter.phone_number);
            setDepartment(mainRecruiter.department);
            setMainFamilyKanaName(mainRecruiter.family_name_kana);
            setCompanyLogo(company.logo);
            setCorprateNumber(company.corporate_number);
            setCompanyName(company.name);
            setCompanyNameKana(company.name_kana);
            setCompanyUrl(company.url);
            setCompanyPostCode(company.postcode);
            setAddress(company.address);
            setConsiderationConditions(company.consideration_conditions);
            setBusinessContent(company.business_content);
            setEstablishmentMonth(company.founded.month);
            setEstablishmentYear(company.founded.year);
            setNumberOfEmployees(company.number_of_employees);
            setCapital(company.capital);
            setSales(company.sales);
            setStockListing(company.stock_listing);
            setCorporateCultures(company.corporate_cultures);
            setPeopleWithDisabilitiesExists(company.people_with_disabilities_exists);
            setCompanyImages(company.images);
            setImageShow(company.image_show);
            setCurrentImageNumber(company.current_image_number);
            if(company.industry) {
              setIndustry(company.industry);
            }
            if(company.people_with_disabilities !== undefined) {
              for(let i = 0; i < company.people_with_disabilities.length; i++) {
                if(company.people_with_disabilities[i].name === '身体障がい') {
                  setSelectedValue(company.people_with_disabilities[i].number_of_employees);
                } 
                
                if(company.people_with_disabilities[i].name === '精神障がい') {
                  setSelectedValue2(company.people_with_disabilities[i].number_of_employees)
                }

                if(company.people_with_disabilities[i].name === '発達障がい') {
                  setSelectedValue3(company.people_with_disabilities[i].number_of_employees)
                }

                if(company.people_with_disabilities[i].name === '知的障がい') {
                  setSelectedValue4(company.people_with_disabilities[i].number_of_employees)
                }

                if(company.people_with_disabilities[i].name === '難病・その他') {
                  setSelectedValue5(company.people_with_disabilities[i].number_of_employees)
                }
              }
            }
            if(company.postcode !== null) {
              setBtn(true);
            }
            dispatch(fetchRegionsRequest());
          }
        } else {
          if(companies != undefined) {
            window.location.reload();
          } else {
            setErrorCode(1);
          }
        }
      }, [companies]);

      useEffect(() => {
        if(regions !== undefined){
          setIndustries(regions.industries);
          setStockList(regions.stock_listings);
          setNumberOfEmployeesArr(regions.number_of_employees);
          // setCorporateCulturesList(regions.corporate_cultures);
          setPurposeOfPostings(regions.purpose_of_postings);
          var corporateCultures = [];
          if(regions.corporate_cultures != undefined) {
            for(var i = 0; i < regions.corporate_cultures.length; i++){
              const cultures = {
                id : regions.corporate_cultures[i].id,
                name : regions.corporate_cultures[i].name,
                check : false
              }
              corporateCultures.push(cultures);
            }
          }
          setCorporateCulturesList(corporateCultures);
        }
      }, [regions]);

      useEffect (() => {
        if( purpose_of_postings !== undefined && !load) {
          let lst = [];
          let chk = false;
          for(let i = 0; i< purpose_of_postings.length; i++) {
            lst.push(false);
            chk = false;
            for(let j = 0; j < selectedPurposeLst.length; j++) {
              setLoad(true);
              if(purpose_of_postings[i].id === selectedPurposeLst[j].id) {
                chk = true;                  
              }
            }
            if(chk) {
              lst[i] = true;
            } else {
              lst[i] = false;
            }
          }
          if(selectedPurposeLst.length == 0 && purpose_of_postings.length > 0) {
            setLoad(true);
          }          
          setSelectedPurpose(lst);
        }
      },[selectedPurpose, purpose_of_postings, load])


      function delLogo () {
        setCompanyLogo(null);
      }

      function setLogo() {
        const fileInput = fileInputRef.current;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
          const fileImg = fileInput.files[0];
          if (fileImg.type.startsWith('image/')) {
            const logoUrl = URL.createObjectURL(fileImg);
            setLogoFile(true);
            const reader = new FileReader();
            reader.readAsDataURL(fileImg);
            reader.onload = function () {
              setCompanyLogo(reader.result);
              setLogoError(false);
            };
          }
        }
      }

      // function changeCorprateNumber(value: any) {
      //   setCorprateNumber(value);
      //   dataValidation();
      // }

      function getPostCode () {
        if(companyPostCode !== null){
          setBtn(true);
        }
      }


      const purposeChange = (event: React.ChangeEvent<HTMLInputElement>, index: any) => {
        const { value, checked } = event.target;
        let lst = [];
        for(let i = 0; i < selectedPurpose.length; i++) {
          lst.push(selectedPurpose[i]);
        }
        if(event.target.checked) {
          lst[index] = true;
        } else {
          lst[index] = false;
        }
        setSelectedPurpose(lst);
      };


      useEffect(() => {
        if(btn) {
            dispatch(fetchPostCodeRequest(companyPostCode));
            setBtn(false);
        }
    }, [postcode, btn]);

    useEffect(() => {
      if (postcode.pending) {
          if(postcode.postcode.prefecture !== undefined) {
              setPrefectureName(postcode.postcode.prefecture.name);
              setDisabledAddress(postcode.postcode.prefecture.name + postcode.postcode.city.name);
              setPrefectureId(postcode.postcode.prefecture.id);
              setRegionId(postcode.postcode.region.id);
          }
          if(postcode.postcode.city !== undefined){
              setCityName(postcode.postcode.city.name);
              setCityId(postcode.postcode.city.id);
          }
      }
  }, [postcode.pending]);
    
    const handleCheckboxChange = (e: any) => {
      setIsEditCity(e.target.checked);
    };

    const handleChange = (value: any) => {
      let dt = JSON.parse(value.target.value);
      setIndustry(dt);
    };
    const handleChanges = (event: any) => {
      const {name, value} = event.target;
      setBusinessContent(value);
      dataValidation(name, value);
    };

    const handleYearChange = (event: any) => {
      const {name, value } = event.target;
      setEstablishmentYear(value);
      dataValidation(name, value);
    };
  
    const handleMonthChange = (event: any) => {
      const { value } = event.target;
      setEstablishmentMonth(value);
    };
    const handleChange1 = (event: any) => {
      const { name, value } = event.target;
      setNumberOfEmployees(value);
      dataValidation(name, value);
    };
    const handleCapitalChange = (event: any) => {
      const {name, value } = event.target;
      setCapital(value);
      dataValidation(name, value);
    };
  
    const handleSalesChange = (event: any) => {
      const {name, value } = event.target;
      setSales(value);
      dataValidation(name, value);
    };

    function recruiterEmailChange (event: any) {
      const { value } = event.target;
      console.log(value);
      setRecruiterEmail(value);
    }
    const handleStockListingChange = (value: any) => {
      let dt = JSON.parse(value.target.value);
      setStockListing(dt);
    };
    const inputChange = (event:any) => {
      const { name, value } = event.target;
      if(name == 'corporate_number') {
        setCorprateNumber(value);
      }
      if(name === 'company_name') {
        setCompanyName(value);
      }
      if(name === 'company_name_furigana') {
        setCompanyNameKana(value);
      }
      if(name === 'url') {
        setCompanyUrl(value)
      }
      if(name === 'zip') {
        setCompanyPostCode(value)
      }
      if(name === 'address1') {
        setAddress(value)
      }
      dataValidation(name, value);
    };

    const handleCheckboxChange1 = (event:any) => {
      if(event.target.value == "実績有り"){
       setPeopleWithDisabilitiesExists(true);     
      } else {
        setPeopleWithDisabilitiesExists(false);
        setErrorPeople(false);
      }
    };

    const numberSelectChanges = (event: any, type: any) => {      
      if(type === '身体障がい') {
        console.log(event)
        setSelectedValue(event);
        if(event == '') {
          if((selectedValue2 == '' || selectedValue2 == null) && (selectedValue3 == '' || selectedValue3 == null) && (selectedValue4 == '' || selectedValue4 == null) && (selectedValue5 == '' || selectedValue5 == null)) {
            setErrorPeople(true);
          } else {
            setErrorPeople(false);
          }
        } else {
          setErrorPeople(false);
        }
      } 
      if (type === '精神障がい') {
        setSelectedValue2(event);
        if(event == '') {
          if((selectedValue == '' || selectedValue == null) && (selectedValue3 == '' || selectedValue3 == null) && (selectedValue4 == '' || selectedValue4 == null) && (selectedValue5 == '' || selectedValue5 == null)) {
            setErrorPeople(true);
          } else {
            setErrorPeople(false);
          }
        } else {
          setErrorPeople(false);
        }
      }
      if (type === '発達障がい') {
        setSelectedValue3(event);
        if(event == '') {
          if((selectedValue2 == '' || selectedValue2 == null) && (selectedValue == '' || selectedValue == null) && (selectedValue4 == '' || selectedValue4 == null) && (selectedValue5 == '' || selectedValue5 == null)) {
            setErrorPeople(true);
          } else {
            setErrorPeople(false);
          }
        } else {
          setErrorPeople(false);
        }
      }
      if (type === '知的障がい') {
        setSelectedValue4(event);
        if(event == '') {
          if((selectedValue2 == '' || selectedValue2 == null) && (selectedValue3 == '' || selectedValue3 == null) && (selectedValue == '' || selectedValue == null) && (selectedValue5 == '' || selectedValue5 == null)) {
            setErrorPeople(true);
          } else {
            setErrorPeople(false);
          }
        } else {
          setErrorPeople(false);
        }
      }
      if (type === '難病・その他') {
        setSelectedValue5(event);
        if(event == '') {
          if((selectedValue2 == '' || selectedValue2 == null) && (selectedValue3 == '' || selectedValue3 == null) && (selectedValue4 == '' || selectedValue4 == null) && (selectedValue == '' || selectedValue5 == null)) {
            setErrorPeople(true);
          } else {
            setErrorPeople(false);
          }
        } else {
          setErrorPeople(false);
        }
      }
    }


    useEffect(() => {
      if (corporate_cultures !== undefined && corporate_cultures.length > 0 && corporate_cultures_list !== undefined) {
        setCorporateCulturesList((prevList) =>
          prevList.map((item) => ({
            ...item,
            check: corporate_cultures.some((culture) => culture.id === item.id),
          }))
        );
      }
    }, [corporate_cultures, corporate_cultures_list]);
    
  
    const cultureChange = (event: any) => {
      let dt = JSON.parse(event.target.value);
      const isChecked = event.target.checked;
  
      if (isChecked) {
        setCorporateCultures((prevCultures) => [
          ...prevCultures,
          { id: dt.id, name: dt.name },
        ]);
      } else {
        setCorporateCultures((prevCultures) =>
          prevCultures.filter((culture) => culture.id !== dt.id)
        );
      }
      setErrorCultures(false);
      setCorporateCulturesList((prevList) =>
        prevList.map((item) =>
          item.id === dt.id ? { ...item, check: isChecked } : item
        )
      );
    };

    function familyNameChange (event: any) {
      const value = event.target.value;
      const name = event.target.name;
      setMainFamilyName(value);
      dataValidation(name, value);
    }
    function familyNameKanaChange(event: any) {
      const value = event.target.value;
      const name = event.target.name;
      setMainFamilyKanaName(value);
      dataValidation(name, value);
    }
    function departmentChange (event: any) {
      const {name, value} = event.target;
      setDepartment(value);
      dataValidation(name, value);
    }
    function phoneNumberChange ( event: any) {
      const {name, value} = event.target;
      setPhoneNumber(value);
      dataValidation(name, value);
    }
    

    function dataValidation (name: any, value: any) {
      let err = false;
      const kanaValidate = new RegExp('^([ァ-ン]|ー)+$');
      if(name == 'corporate_number') {
        if(value === '') {
          setError(true);
          setErrorMaxLenght(false);
          err = true;
        } else {
          setError(false);
          if(value.length < 13) {
            setErrorMaxLenght(true);
            err = true;
          } else {
            setErrorMaxLenght(false);
          }
          if(/^\d+$/.test(value)) {
            setErrorNumber(false);
          } else {
            setErrorNumber(true);
            err = true;
          }
        }
      }
      if (name === 'company_name') {
        if(value === '') {
          setErrorComapnyName(true);
          err = true;
        } else {
          setErrorComapnyName(false);
          if(value.length >100){
            setErrorComapnyNameMaxLength(true)
            err = true;
          } else {
            setErrorComapnyNameMaxLength(false)
          }
        }
      }
      if (name === 'company_name_furigana') {
        if(value === '') {
          setErrorComapnyNameKana(true);
          setErrorKatakanaChk(false);
          err = true;
        } else {
          setErrorComapnyNameKana(false);
          if(value.length >100){
            setErrorComapnyNameKanaMaxLength(true)
            err = true;
          } else {
            setErrorComapnyNameKanaMaxLength(false)
          }
          if(kanaValidate.test(value)) {
            setErrorKatakanaChk(false)
          } else {
            err = true;
            setErrorKatakanaChk(true)
          }
        }
      }

      if (name ==='family_name') {
        if(value === '') {
          setFamylyNameError(true);
          err = true
        } else {
          if(value.length > 30) {
            setFamylyNameMaxLengthError(true);
            err = true
          } else {
            setFamylyNameMaxLengthError(false);
          }
          setFamylyNameError(false);
        }
      }
      if (name ==='family_name_kana') {
        if(value === '') {
          setFamylyNameKanaError(true);
          err = true
        } else {
          if(value.length > 30) {
            setFamylyNameKanaMaxLengthError(true);
            err = true
          } else {
            setFamylyNameKanaMaxLengthError(false);
          }
          if(kanaValidate.test(value)) {
            setFamylyNameKatakanaChk(false)
          } else {
            err = true;
            setFamylyNameKatakanaChk(true)
          }
          setFamylyNameKanaError(false);
        }
      }
      if (name === 'department') {
        if (value === '') {
          err = true;
          setDepartmentError(true);
        } else {
          if(value.length > 50){
            setDepartmentMaxLengthError(true);
          } else {
            setDepartmentMaxLengthError(false);
          }
          setDepartmentError(false);
        }
      }
      if(name === 'phone_number') {
        if(value === '') {
          err = true;
          setPhoneNumberError(true);
        } else {
          if(value.length <= 11 ){
            setErrorPhoneNumberMaxLength(false);
          } else {
            setErrorPhoneNumberMaxLength(true);
          }
          if(value.length >= 10){
            setErrorPhoneNumberMinLength(false);
          } else {
            setErrorPhoneNumberMinLength(true);
          }
          if(!/[0-9]/.test(value)){
            setErrorPhoneNumberNumeric(true);
          } else {
            setErrorPhoneNumberNumeric(false);
          }
          setPhoneNumberError(false);
        }
      }
      if(name === 'zip') {
        if(value === '') {
          err = true;
          setErrorPostcode(true);
        } else {
          if(!/[0-9]/.test(value)){
            setErrorPostcodeNumeric(true);
          } else {
              setErrorPostcodeNumeric(false);
          }
          if(value.length === 7) {
            setErrorPostcodMaxLength(false);
            err = true
          } else {
            setErrorPostcodMaxLength(true);
          }
          setErrorPostcode(false);
        }
      }
      if(name === 'address1') {
        if(value === '') {
          err = true;
          setErrorPostcode(true);
        } else {
          if(value.length === 200) {
            setErroraddressLength(true);
            err = true
          } else {
            setErroraddressLength(false);
          }
          setErrorPostcode(false);
        }
      }
      if (name === 'detail') {
        if(value.length > 400){
          setErrorbusinesscontentLength(true);
        } else {
          setErrorbusinesscontentLength(false);
        }
      }
      if (name === 'establishment1') {
        if(value.length === 4){
          setErrorCompanyfoundedMaxLength(false);
        } else {
          setErrorCompanyfoundedMaxLength(true);
        }
        if(value.length === 4){
          setErrorCompanyfoundedMinLength(false);
        } else {
          setErrorCompanyfoundedMinLength(true);
        }
        if(!/[0-9]/.test(value)){
          setErrorCompanyfoundedNumeric(true);
        } else {
          setErrorCompanyfoundedNumeric(false);
        }
      }
      if (name === 'member') {
        if(value.length >20){
          setErrorNumberOfEmployeesMaxLength(true);
        } else {
          setErrorNumberOfEmployeesMaxLength(false);
        }
        if(!/[0-9]/.test(value)){
          setErrorNumberOfEmployeesNumeric(true);
        } else {
          setErrorNumberOfEmployeesNumeric(false);
        }
      }
      if (name === 'capital') {
        if(value.length >50){
          setErrorCapitalMaxLength(true);
        } else {
          setErrorCapitalMaxLength(false);
        }
      }
      if (name === 'amount_of_sales') {
        if(value.length >50){
          setErrorSalesMaxLength(true);
        } else {
          setErrorSalesMaxLength(false);
        }
      }
      return err;
    }
    const setImage = (index: any, event: any) => {
      const fileImg = event.target.files[0];
      if (fileImg.type.startsWith('image/')) {
        // setCompanyImages((prevCompany) => {
        //   const updatedImages = [...prevCompany];
        //   updatedImages[index].url = URL.createObjectURL(fileImg);
        //   return {
        //     ...prevCompany,
        //     updatedImages,
        //   };
        // });
        companyImages[index].url = URL.createObjectURL(fileImg);
        setImageShow((prevImageShow) => {
          const updatedImageShow = [...prevImageShow];
          updatedImageShow[index] = false;
          return updatedImageShow;
        });
  
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = function () {
          const changeImage = `changeImage${index + 1}`;
          // setCompanyImages((prevCompany) => ({
          //   ...prevCompany,
          //   [changeImage]: reader.result,
          // }));
          companyImages[index].url = reader.result;
        };
      }
    };
  
    const delImage = (priority: any) => {
      // setCompanyImages((prevCompany) => {
      //   const updatedImages = prevCompany.map((image: any) =>
      //     image.priority === priority ? { ...image, url: null, comment: null } : image
      //   );
      //   return {
      //     ...prevCompany,
      //     images: updatedImages,
      //   };
      // });
      const companyImage = companyImages.find(v => v.priority == priority)
      companyImage.url = null;
      companyImage.comment = null;
      setImageShow((prevImageShow) => {
        const updatedImageShow = [...prevImageShow];
        updatedImageShow[priority - 1] = true;
        return updatedImageShow;
      });
    };
  
    const dispImage = (index: any) => {
      setImageShow((prevImageShow) => {
        const updatedImageShow = [...prevImageShow];
        updatedImageShow[index] = true;
        return updatedImageShow;
      });
      setCurrentImageNumber(index + 1);
    };
    function logout() {
      if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
      }
      if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
      }
    }

    const isMoreCulutureList = () => {
      if (culuture_limit === 12) {
        let length = corporate_cultures_list.length;
        length -= 12;
        setCulutureLimit(culuture_limit + length);
        setIsMoreCuluture('表示を減らす');
      } else {
        setCulutureLimit(12);
        setIsMoreCuluture('もっと見る');
      }
    };


    const culture_list = () => {
      if (!corporate_cultures_list) {
        return [];
      }
      return corporate_cultures_list.slice(0, culuture_limit);
    };

    function join_founded () {
      const year = establishmentYear;
      const month = establishmentMonth;
      if (year) {
        if (month) {
          return year + '年' + month + '月'
        } else {
          return year + '年'
        }
      } else {
        return null
      }
    }

    function handleComment(e: any, id: any) {
      let c = [];
      for(let i = 0; i < companyImages.length; i++) {
        c.push(companyImages[i]);
      }
      c[id].comment = e.target.value;
      setCompanyImages(c);
    }

    function checkSelectedPurpose() {
      let chk = false;
      for(let i = 0; i < selectedPurpose.length; i++) {
        if(selectedPurpose[i]) {
          chk = true;
        }
      }
      return chk;
    }

    function send() {
      let chk = false;
      for(let i = 0; i < selectedPurpose.length; i++) {
        if(selectedPurpose[i]) {
          chk = true;
        }
      }
      if(companyLogo == null) {
        setLogoError(true);
      }
      if(check){
        if((selectedValue == '' || selectedValue == null) && (selectedValue2 == '' || selectedValue2 == null) && (selectedValue3 == '' || selectedValue3 == null) && (selectedValue4 == '' || selectedValue4 == null) && (selectedValue5 == '' || selectedValue5 == null)) {
          setErrorPeople(true);
        }
      }
      if(corporate_cultures.length == 0) {
        setErrorCultures(true);
      }
      if (!error && !errorCapitalMaxLength && !errorCompanyName && !errorCompanyNameKana && !errorCompanyNameKanaMaxlength
        && !errorCompanyNameMaxlength && !errorCompanyfoundedMaxLength && !errorCompanyfoundedMinLength && !errorCompanyfoundedNumeric
        && !errorKatakanaChk && !errorMaxLenght && !errorNumber && !errorNumberOfEmployeesMaxLength && !errorNumberOfEmployeesNumeric
        && !errorPhoneNumberMaxLength && !errorPhoneNumberMinLength && !errorPhoneNumberNumeric && !errorPostcode && !errorPostcodeMaxLength
        && !errorPostcodeNumeric && !errorSalesMaxLength && !erroraddressMaxLength && !errorbusinesscontentMaxLength && chk && companyLogo != null
        && corporate_cultures.length > 0 && !errorPeople && !famylyNameError && !phoneNumberError && !departmentMaxLengthError && !departmentError && !famylyNameKanaError) {
        setLoadScreen(true);
        let arry_disabilities = [];
        arry_disabilities.push({handicap_type_id: 1, number_of_employees_id: selectedValue == '' ? null : selectedValue});
        arry_disabilities.push({handicap_type_id: 2, number_of_employees_id: selectedValue2 == '' ? null : selectedValue2});
        arry_disabilities.push({handicap_type_id: 3, number_of_employees_id: selectedValue3 == '' ? null : selectedValue3});
        arry_disabilities.push({handicap_type_id: 4, number_of_employees_id: selectedValue4 == '' ? null : selectedValue4});
        arry_disabilities.push({handicap_type_id: 5, number_of_employees_id: selectedValue5 == '' ? null : selectedValue5});

        let array_corporate_cultures = []
        array_corporate_cultures = corporate_cultures.map((c) => ({corporate_culture_id: c.id}))

        let array_conditions: any[] = [];
        array_conditions = consideration_conditions.map((c: any) => ({consideration_condition_id: c.id}))

        let array_purpose_of_postings = [];
        for(let i = 0; i < selectedPurpose.length; i++) {
          if(selectedPurpose[i]) {
            array_purpose_of_postings.push({purpose_of_posting_id: purpose_of_postings[i].id});
          }
        }

        // Todo
        let images = []
        for (let i = 0; i < 5; i++) {
          images.push({
            priority: i + 1,
            comment: companyImages[i].comment,
            image: companyImages[i].url != null || companyImages[i].url != '' ? companyImages[i].url : ''
          })
        }
        const data = {
          logo: companyLogo,
          images: images,
          corporate_number: corporate_number,
          name: company_name,
          name_kana: company_name_kana,
          url: companyUrl,
          postcode: companyPostCode,
          region_id: companyRegionId,
          prefecture_id: prefecture_id,
          city_id: city_id,
          address: address,
          industry_id: industry ? industry.id : null,
          business_content: businessContent,
          founded: join_founded(),
          number_of_employees: numberOfEmployees,
          capital: capital,
          sales: sales,
          city_correction: '',
          stock_listing_id: stockListing.id,
          people_with_disabilities_exists: check,
          people_with_disabilities: arry_disabilities,
          consideration_conditions: array_conditions,
          corporate_cultures: array_corporate_cultures,
          main_recruiter: {
            id: mainRecruiter2.id,
            department: department,
            email: recruiterEmail,
            family_name: mainFamilyName,
            family_name_kana: mainFamilyNameKana,
            first_name: mainRecruiter2.first_name,
            first_name_kana: mainRecruiter2.first_name_kana,
            message: mainRecruiter2.message,
            phone_number: phoneNumber,
            purpose_of_postings: array_purpose_of_postings,
          }
        }
        dispatch(fetchCompaniesRequest(loginData.login.company.id, 'put', data));
        setPutMethod(true);
      } else {
        const element = document.querySelector('.error');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    }

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
          <ul className="bread_crumb">
            <li><a href="/">トップ</a></li>
            <li><a href="/recruiter/">企業様マイページ</a></li>
            <li><a href="#">会社概要</a></li>
          </ul>
            <div className="ttl_box">
                <h2 className="ttl">会社概要</h2>
                <ul>
                <li>
                    <a href="#" onClick={logout} >
                    <img src="/assets/img/company/icon02.svg" alt=""/>ログアウト </a>
                </li>
                </ul>
            </div>
            <form className="outline">
              <div className="cont2">
                <p className="btn_preview btn type3" v-show="show_preview"><a style={{textAlign: "center"}} href="/recruiter/preview/">プレビューで会社概要の見え方を確認する</a></p>
                {/* <h3 style="flex-wrap: wrap;"> */}
                <h3 style={{flexWrap: "wrap"}}>
                  企業ロゴ<span>必須</span>
                  {isDesktop ?
                  <p className="note" style ={color }>
                    ※ロゴ画像はno image画像を削除してご登録ください。
                  </p>
                  : 
                  <p className="note" style ={colorMobile }>
                    ※ロゴ画像はno image画像を削除してご登録ください。
                  </p>}
                </h3>
                <div>
                  <ul className="slider_edit logo_edit">
                  {companyLogo !== null ? (
                    <li className="img">
                      <img src={companyLogo || ''} />
                      <img
                        className="trash"
                        src="/assets/img/company/icon05.svg"
                        onClick={delLogo}
                      />
                    </li>
                  ) : (
                    <li>
                      <p className={ logoError ? 'error' : ''}>
                        <label>
                          <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            onChange={setLogo}
                          />
                          <img src="/assets/img/company/icon03.svg" className="add_icon" alt="" />
                        </label>
                      </p>
                    </li>
                  )}
                  </ul>
                </div>
                {logoError ?
                <div className="error_text">
                  <p>設定必須項目です</p>
                </div>
                : <></>}
              </div>
              <div className="cont2">
                <h3>法人番号<span>必須</span></h3>
                <div>
                  <input className={error || errorMaxLenght || errorNumber  ? 'error': ''} type="text" name="corporate_number" value={corporate_number || ''} onChange={inputChange}  placeholder="例）12345678901234（13桁）"/>
                  <p>※法人番号が分からない方は<a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" rel="noopener noreferrer">こちらのサイト</a>でお調べいただけます。</p>
                   {error || errorMaxLenght || errorNumber ?
                   <div  className="error_text">
                    {!error  ?<></> :
                      <p>入力必須項目です</p>
                    }
                    {errorMaxLenght ?
                      <p>法人番号は13桁で入力してください</p>
                    : <></>}
                     {errorNumber ? <p>法人番号は半角数字で入力してください</p> : <></>}
                   </div>
                   : <></>} 
                </div>
              </div>
              <div className="cont2">
                <h3>会社名<span>必須</span></h3>
                <div>
                  <input className={errorCompanyName || errorCompanyNameMaxlength  ? 'error': ''} type="text" name="company_name" value={company_name}  onChange={inputChange}/>
                  {errorCompanyName || errorCompanyNameMaxlength ?
                  <div className="error_text">
                    {errorCompanyName ? <p>入力必須項目です</p> : <></>}
                    {errorCompanyNameMaxlength ? <p>会社名は100文字以下で入力してください</p> : <></>}
                  </div>
                  : <></>}
                </div>
              </div>
              <div className="cont2">
                <h3>会社名（フリガナ）<span>必須</span></h3>
                <div>
                  <input type="text"  className={errorCompanyNameKana || errorCompanyNameKanaMaxlength || errorKatakanaChk  ? 'error': ''} name="company_name_furigana" value={company_name_kana} onChange={inputChange}/>
                    {errorCompanyNameKana || errorCompanyNameKanaMaxlength || errorKatakanaChk?
                    <div className="error_text">
                      {errorCompanyNameKana ?<p>入力必須項目です</p> : <></>}
                      {errorCompanyNameKanaMaxlength ? <p>会社名は100文字以下で入力してください</p> : <></>}
                      {errorKatakanaChk ? <p>会社名（フリガナ）は全角カタカナで入力してください</p> : <></>}
                    </div>
                    : <></>}
                    {/* <p v-if="!$v.company.name_kana.required">{{ err_required }}</p>
                    <p v-if="!$v.company.name_kana.maxLength">会社名は{{ $v.company.name_kana.$params.maxLength.max }}文字以下で入力してください</p>
                    <p v-if="!$v.company.name_kana.kanaValidate">会社名（フリガナ）は全角カタカナで入力してください</p> */}
                  
                </div>
              </div>
              <div className="cont2">
                <h3>ＵＲＬ</h3>
                <div>
                  <input type="text" name="url" value={companyUrl || ''} onChange={inputChange} />
                </div>
              </div>
              <div className="cont6">
                <h3>住所（本社）<span>必須</span></h3>
                <dl>
                  <dt>郵便番号</dt>
                  <dd>
                    <label>
                      <input type="text" name="zip" value={companyPostCode} onChange={inputChange} className={errorPostcode || errorPostcodeNumeric || errorPostcodeMaxLength ? 'error': ''} />
                      <button  type="button" className={`btn type3 ${disabled ? 'disabled' : ''}`} onClick={getPostCode}  >郵便番号から探す</button>
                    </label>
                  </dd>
                  <dt>都道府県<br/>市区町村<br/>字名</dt>
                  <dd className="city">
                    {!isEditCity ? <input v-if="!isEditCity" type="text" name="address0" value={disabled_address} v-model="disabled_address" disabled/> : <></>}
                    {/* <input v-if="!isEditCity" type="text" name="address0" v-model="disabled_address" disabled/> */}
                    {isEditCity ?<input v-if="isEditCity" type="text" name="prefecture" v-model="company.prefecture.name" value={prefecture_name} disabled/> : <></>}
                    {isEditCity ?<input v-if="isEditCity" type="text" name="city" value={city_name} onChange={(e) => {setCityName(e.target.value)}}/> : <></>}
                    <label>
                      <input type="checkbox"  value={isEditCity} onChange={handleCheckboxChange}/>市区町村を修正する
                    </label>
                  </dd>
                  <dt>住所<br/>(それ以降) </dt>
                  <dd><input type="text" name="address1" value={address} onChange={inputChange} className={errorPostcode || erroraddressMaxLength ? 'error': ''} /></dd>
                  {errorPostcode || errorPostcodeNumeric || errorPostcodeMaxLength ||erroraddressMaxLength ?
                   <div  className="error_text">
                    {!errorPostcode  ?<></> :
                      <p>入力必須項目です</p>
                    }
                    {errorMaxLenght ?
                      <p>郵便番号は7桁で入力してください</p>
                    : <></>}
                     {erroraddressMaxLength ? 
                     <p>住所(それ以降)は200文字以下で入力してください</p> : <></>}
                     {errorPostcodeNumeric ?
                      <p>郵便番号は数字のみで入力してください</p>
                    : <></>}
                   </div>
                   : <></>} 
                </dl>
              </div>
              <div className="cont2">
                <h3>業種分類</h3>
                <div className="select_cont">
                  <label>
                  <select name="genre" value={JSON.stringify(industry || '')} onChange={handleChange}>
                      <option value="null">--</option>
                      {industries &&
                        industries.map((data, index) => (
                          <option value={JSON.stringify(data)} key={index}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="cont5">
                <h3>事業内容</h3>
                <textarea
                  name="detail"
                  value={businessContent}
                  onChange={handleChanges}
                  className={errorbusinesscontentMaxLength ? 'error': ''}
                ></textarea>
                <div className='error_text'>
                  {errorbusinesscontentMaxLength   ?
                    <p>事業内容は400文字以下で入力してください</p>
                  : <></>}
                </div>
              </div>
              <div className="cont6">
              <h3>設立年月</h3>
              <div className="select_cont establishment_cont">
                <label>
                  <input
                    type="text"
                    name="establishment1"
                    value={establishmentYear}
                    onChange={handleYearChange}
                    className={errorCompanyfoundedMaxLength || errorCompanyfoundedMinLength || errorCompanyfoundedNumeric  ? 'error': ''}
                  />
                  <span>年</span>
                  <select
                    name="establishment2"
                    value={establishmentMonth}
                    onChange={handleMonthChange}
                  >
                    <option value="null">--</option>
                    {[...Array(12)].map((_, index) => (
                      <option value={index + 1} key={index}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <span>月</span>
                </label>
                {errorCompanyfoundedMaxLength || errorCompanyfoundedMinLength || errorCompanyfoundedNumeric?
                    <div className="error_text">
                      {errorCompanyfoundedMaxLength || errorCompanyfoundedMinLength ?<p>設立年は西暦4桁で入力してください</p> : <></>}
                      {/* {errorCompanyfoundedMinLength ? <p>設立年は数字のみで入力してください</p> : <></>} */}
                      {errorCompanyfoundedNumeric ? <p>設立年は半角数字で入力してください</p> : <></>}
                    </div>
                : <></>}
              </div>
            </div>
            <div className="cont2">
              <h3>従業員数</h3>
              <div className="member_cont">
                <input
                  type="text"
                  name="member"
                  value={numberOfEmployees}
                  onChange={handleChange1}
                  className={errorNumberOfEmployeesNumeric || errorNumberOfEmployeesMaxLength? 'error': ''}
                />
                人
              </div>
              {errorNumberOfEmployeesMaxLength || errorNumberOfEmployeesNumeric?
                <div className="error_text">
                  {errorNumberOfEmployeesMaxLength ?<p>従業員数は20文字以下で入力してください</p> : <></>}
                  {errorNumberOfEmployeesNumeric ? <p>従業員数は半角数字で入力してください</p> : <></>}
                </div>
              : <></>}
            </div>
            <div className="cont2">
              <h3>資本金</h3>
              <input
                type="text"
                name="capital"
                // className="small"
                className={`small ${errorCapitalMaxLength? 'error': ''}`}
                value={capital}
                onChange={handleCapitalChange}
                placeholder="例）1,000万円"
              />
              <div className="error_text">
                {errorCapitalMaxLength ?<p>資本金は50文字以下で入力してください</p> : <></>}
              </div>
            </div>
            <div className="cont2">
              <h3>売上高</h3>
              <input
                type="text"
                name="amount_of_sales"
                className={`small ${errorSalesMaxLength? 'error': ''}`}
                value={sales}
                onChange={handleSalesChange}
                placeholder="例）10億円"
              />
              <div className="error_text">
                {errorSalesMaxLength ?<p>売上高は50文字以下で入力してください</p> : <></>}
              </div>
            </div>
            <div className="cont2">
              <h3>株式</h3>
              <div className="select_cont">
                <label>
                  <select name="genre"  value={JSON.stringify(stockListing || '')}  onChange={handleStockListingChange}>
                    <option value="">--</option>
                    {stock_listings && stock_listings.map((data, index) => (
                      <option  value={JSON.stringify(data)} key={index}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div className="cont6">
            <h3>障がい者雇用実績<span>必須</span></h3>
            {errorPeople ?
              <div className="error_text">
                <p>選択必須項目です</p>
              </div>
              : <></>}
            <label className="c_label">
              <input
                type="radio"
                name="achievement"
                value="実績有り"
                checked={check}
                onChange={handleCheckboxChange1}
              />
              実績有り
            </label>
            <label>
              <input
                type="radio"
                name="achievement"
                value="実績なし"
                checked={!check}
                onChange={handleCheckboxChange1}
              />
              実績なし
            </label>              
              {check ?
              <dl className="disabled results">
                <dt>身体障がい</dt>
                <dd>
                  <div className="select_cont">
                    <label>
                      <select name="achievement" value={selectedValue}  onChange={(event) => numberSelectChanges(event.target.value, '身体障がい')}>
                        <option value=""></option>
                        {number_of_employees && number_of_employees.map((data: any) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </dd>
                <dt>精神障がい</dt>
                <dd>
                  <div className="select_cont">
                    <label>
                      <select name="achievement" value={selectedValue2}  onChange={(event) => numberSelectChanges(event.target.value, '精神障がい')}>
                        <option value=""></option>
                        {number_of_employees && number_of_employees.map((data: any) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </dd>
                <dt>発達障がい</dt>
                <dd>
                  <div className="select_cont">
                    <label>
                      <select name="achievement" value={selectedValue3}  onChange={(event) => numberSelectChanges(event.target.value, '発達障がい')}>
                        <option value=""></option>
                        {number_of_employees && number_of_employees.map((data: any) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </dd>
                <dt>知的障がい</dt>
                <dd>
                  <div className="select_cont">
                    <label>
                      <select name="achievement" value={selectedValue4}  onChange={(event) => numberSelectChanges(event.target.value, '知的障がい')}>
                        <option value=""></option>
                        {number_of_employees && number_of_employees.map((data: any) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </dd>
                <dt>難病・その他</dt>
                <dd>
                  <div className="select_cont">
                    <label>
                      <select name="achievement" value={selectedValue5}  onChange={(event) => numberSelectChanges(event.target.value, '難病・その他')}>
                        <option value=""></option>
                        {number_of_employees && number_of_employees.map((data: any) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </dd>
              </dl>
              : <></>}
            </div>
            <div className="cont3">
              <h3>
                社風<span>必須</span>
                <p className="note" style={color}>
                  ※複数回答あり
                </p>
              </h3>
              {errorCultures ?
              <div className="error_text">
                <p>選択必須項目です</p>
              </div>
              : <></>}
              {/* Display the selected corporate cultures */}
              {corporate_cultures.length !== 0 && (
                <ul className="select">
                  {corporate_cultures.map((data) => (
                    <li key={data.id}>{data.name}</li>
                  ))}
                </ul>
              )}
              <div>
              {culture_list() &&
                  culture_list().map((data) => (
                    <label key={data.id}>
                      <input
                        type="checkbox"
                        checked={data.check}
                        name="culture"
                        onChange={cultureChange}
                        value={JSON.stringify(data)}
                      />
                      {data.name}
                    </label>
                  ))}
              </div>
              {/* <button type="button" onClick={isMoreCulutureList} >もっと見る</button> */}
              <button type="button" onClick={isMoreCulutureList}>
                {isMoreCuluture}
              </button>
            </div>
            <div className="cont2">
              <h3>画像</h3>
              <ul className="slider_edit">
                {companyImages && companyImages.map((i: any, index: any) => (
                  <React.Fragment key={i.priority}>
                  {i.url ? (
                    <li className="img">
                      <img src={i.url} alt="" />
                      <input type="text" value={i.comment} placeholder="画像説明文" onChange={(e) => {handleComment(e, index)}} />
                      <img
                        className="trash"
                        src="/assets/img/company/icon05.svg"
                        onClick={() => delImage(i.priority)}
                        onKeyPress={(e) => e.key === 'Enter' && delImage(i.priority)}
                      />
                    </li>
                  ) : (
                    <>
                    {imageShow[index] ?
                      <li key={i.priority}>
                      <p>
                        <label>
                          <input type="file" accept="image/*" onChange={(e) => setImage(index, e)} />
                          <img src="/assets/img/company/icon03.svg" className="add_icon" alt="" />
                          <span>{imagesAdvices[index]}</span>
                        </label>
                      </p>
                    </li>
                     : <></>}
                    </>
                    
                  )}
                </React.Fragment>
                ))}
                {currentImageNumber < 5 ?
                  <li className="add_img" onClick={() => dispImage(currentImageNumber)}>
                    <p>
                      <img src="/assets/img/company/icon03.svg" className="add_icon" alt=""/>
                      <span>さらに画像を追加する</span>
                    </p>
                  </li>
                : <></>}

              </ul>
              <p className="note">※こちらの画像は求人票内にある会社概要ページのサムネイルとして表示されます。</p>
            </div>
            <div className="cont6">
              <h3>採用担当者情報<span>必須</span><p className="note" style={color}>※求人票には表示されない情報です。</p></h3>
              <dl className="disabled">
                <dt>ご担当者名</dt>
                <dd className="name_cont">
                  <label>
                    <span>ご担当者名</span>
                    <input
                      type="text"
                      name="family_name"
                      value={mainFamilyName}
                      onChange={familyNameChange}
                      className={famylyNameError || famylyNameMaxLengthError ? 'error' : ''}
                    />
                  </label>
                  <label>
                    <span>ご担当者名カナ</span>
                    <input
                      type="text"
                      name="family_name_kana"
                      value={mainFamilyNameKana}
                      onChange={(e) =>familyNameKanaChange(e)}
                      className={famylyNameKanaError || famylyNameKanaMaxLengthError || famylyNameKatakanaChk  ? 'error' : ''}
                    />
                  </label>
                  <div className="error_text">
                    <p>{famylyNameError && <span>ご担当者名は入力必須項目です</span>}</p>
                    <p>{famylyNameKanaError && <span>ご担当者名カナは入力必須項目です</span>}</p>
                    <p>{famylyNameMaxLengthError && <span>ご担当者名カナは文字以下で入力してください</span>}</p>
                    <p>{famylyNameKanaMaxLengthError && <span>ご担当者名カナはカタカナで入力してください</span>}</p>
                    <p>{famylyNameKatakanaChk && <span>ご担当者名カナはカタカナで入力してください</span>}</p>
                  </div>
                </dd>
                <dt>支店・部署名</dt>
                <dd>
                  <input type="text"
                    name="department"
                    value={department}
                    placeholder="例）本社、人事部など" 
                    onChange={departmentChange}
                    className={departmentError || departmentMaxLengthError  ? 'error' : ''} />
                    <div className="error_text">
                      <p>{departmentError ? <span >入力必須項目です</span> : <></>}</p>
                      <p>{departmentMaxLengthError ? <span>部署名は50文字以下で入力してください</span> : <></>}</p>

                    </div>
                </dd>
                <dt>電話番号</dt>
                <dd>
                  <input type="text"
                   name="phone_number"
                   value={phoneNumber}
                   onChange={phoneNumberChange}
                   className={phoneNumberError ||errorPhoneNumberMaxLength||errorPhoneNumberNumeric||errorPhoneNumberMinLength ? 'error' : ''} />
                   {phoneNumberError || errorPhoneNumberMaxLength || errorPhoneNumberMinLength|| errorPhoneNumberNumeric?
                    <div className="error_text">
                      {phoneNumberError ? <p>入力必須項目です</p> : <></>}
                      {errorPhoneNumberMaxLength || errorPhoneNumberMinLength ?<p>電話番号は10桁以上11桁以下で入力してください</p> : <></>}
                      {errorPhoneNumberNumeric ? <p>設立年は半角数字で入力してください</p> : <></>}
                    </div>
                : <></>}
                </dd>
                <dt>メールアドレス</dt>
                <dd>
                <input type="text" name="email" onChange={recruiterEmailChange} value={recruiterEmail}  disabled/>
                </dd>
                <dt>登録・求人掲載目的</dt>
                <dd className="checkbox_cont">
                {purpose_of_postings && purpose_of_postings.map((data: any, index: any) => (
                  <label key={data.id}>
                    <input
                      type="checkbox"
                      name="purpose"
                      value={data}
                      checked={selectedPurpose[index]}
                      onChange={(e) => purposeChange(e, index)}
                    />
                    {data.name}
                  </label>
                ))}
                {!checkSelectedPurpose() && <p className="error_text">選択必須項目です</p>}
                </dd>
              </dl>
            </div>
            {errorCode === 1 ? 
            <p>エラーが発生しました。恐れ入りますがもう一度やり直してください。</p>
            : <></>}
            <button type="button" className="btn type3" onClick={() => send()}>保存する</button>
            </form>
        </section>
      </main>
    );
}