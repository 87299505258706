
import { recruitmentsJobsTypes } from "../../ActionTypes/recruitmentsJobsTypes";
import {
  FetchRecruitmentsJobsFailure,
  FetchRecruitmentsJobsFailurePayload,
  FetchRecruitmentsJobsRequest,
  FetchRecruitmentsJobsSuccess,
  FetchRecruitmentsJobsSuccessPayload,
} from "../../types/type";

export const fetchRecruitmentsJobsRequest = (page:any): FetchRecruitmentsJobsRequest => ({
  type: recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_REQUEST,
  page,
});

export const fetchRecruitmentsJobsSuccess = (
  payload: FetchRecruitmentsJobsSuccessPayload,
): FetchRecruitmentsJobsSuccess => ({
  type: recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_SUCCESS,
  payload
});

export const fetchRecruitmentsJobsFailure = (
  payload: FetchRecruitmentsJobsFailurePayload
): FetchRecruitmentsJobsFailure => ({
  type: recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_FAILURE,
  payload
});
