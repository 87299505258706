import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruitmentsJobsTypes } from "../../ActionTypes/recruitmentsJobsTypes";
import { fetchRecruitmentsJobsSuccess, fetchRecruitmentsJobsFailure } from "../../actions/recruitmentsJobsActions/recruitmentsJobsActions";

// var numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

var getRecruitmentsJobs = (page:any) => {
  if(!localStorage.jobParachannelAuthToken) {
    return axios.get(API_BASE_ADDRESS + '/recruitments?paid_company_plan[]=2&paid_company_plan[]=3&per=30' + '&page=' + page, {headers: { Accept: 'application/json'}}); 
  } else {
    return axios.get(API_BASE_ADDRESS + '/recruitments?paid_company_plan[]=2&paid_company_plan[]=3&per=30' + '&page=' + page, {headers: { Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken}}); 
  }
}

function* fetchRecruitmentsJobsSaga(data:any) {
  try {
    const response: AxiosResponse = yield call(getRecruitmentsJobs, data.page);
    yield put(
      fetchRecruitmentsJobsSuccess({
        recruitments: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruitmentsJobsFailure({
        error: e.message
      })
    );
  }
}

function* RecruitmentsJobsSaga() {
  yield takeLatest(recruitmentsJobsTypes.FETCH_RECRUIMENTS_JOBS_REQUEST, fetchRecruitmentsJobsSaga);
}

export default RecruitmentsJobsSaga;
