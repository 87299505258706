import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruiterNewsTypes } from "../../ActionTypes/recruiterNewsTypes";
import { fetchRecruiterNewsFailure, fetchRecruiterNewsSuccess } from "../../actions/recruiterNewsActions/recruiterNewsActions";

const getRecruiterNews = ( url: any) => {

      return axios.get(API_BASE_ADDRESS + url, {
        headers: { Prefer: 'example=example-1', Accept: 'application/json', Authorization: localStorage.jobParachannelAuthToken }
      });
    }

function* fetchRecruiterNewsSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterNews, action.url);
    yield put(
      fetchRecruiterNewsSuccess({
        news: response.data
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterNewsFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterNewsSaga() {
  yield takeLatest(recruiterNewsTypes.FETCH_RECRUITER_NEWS_REQUEST, fetchRecruiterNewsSaga);
}
export default RecruiterNewsSaga;