import { useEffect, useRef, useState } from 'react';
import MypageSideBar from '../../../components/MypageSideBar/MypageSideBar';
import './profile.scss';
import ProfileTab from './tab';
import ProfileIndex from '.';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redax/reducers/rootReducer';
import { fetchApplicantsRequest } from '../../../redax/actions/applicantsActions/applicantsActions';
import { fetchRegionsRequest } from '../../../redax/actions/regionsActions/regionsActions';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Loader from '../../loader/loader';
import { fetchPutRegionRequest } from '../../../redax/actions/putRegionActions/putRegionActions';
import React from 'react';

export default function ProfilePage2() {
    let applicant: any = [];
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [authenticate, setAuthenticate] = useState(null);
    const [handicap_id, setHandicapId] = useState(null);
    const [disability_certificate_status_id, setDisabilityCertificateStatusId] = useState(null);
    const [disability_certificate_id, setDisabilityCertificateId] = useState(null);
    const [handicapTypeId, setHandicapTypeId] = useState(null);
    const [considerationConditionsMasterData, setConsiderationConditionsMasterData] = useState<any[]>([]);
    const [isModalHandicapQualifications, setIsModalHandicapQualifications] = useState(false);
    const [handicaps, setHandicaps] = useState<any[]>([]); 
    const [handicapTypes, setHandicapTypes] = useState<any[]>([]); 
    const [disabilityCertificateStatuses, setDisabilityCertificateStatuses] = useState<any[]>([]);
    const [disabilityCertificates, setDisabilityCertificates] = useState<any[]>([]);
    const [isModalHandicapQualificationsSlide, setIsModalHandicapQualificationsSlide] = useState(0);
    const [AreaOpenCharacter, setAreaOpenCharacter] = useState<any>([]);
    const [AreaOpenProcess, setAreaOpenProcess] = useState<any>([]);
    const [AreaOpenConsideration, setAreaOpenConsideration] = useState(false);
    const [AreaOpenConsiderationRequired, setAreaOpenConsiderationRequired] = useState(false);
    const [readOnlyProcess, setReadOnlyProcess] = useState(false);
    const [readOnlyCharacter, setReadOnlyCharacter] = useState(false);
    const [readOnlyConsideration, setReadOnlyConsideration] = useState(false);
    const [readOnlyConsiderationRequired, setReadOnlyConsiderationRequired] = useState(false);
    const [applicantHandicapQualifications, setApplicantHandicapQualifications] = useState<any[]>([]);
    const [applicantSupportAgencyExists, setApplicantSupportAgencyExists] = useState(false);
    const [applicantConsiderationConditionsRequiredExists, setApplicantConsiderationConditionsRequiredExists] = useState(false);
    const [applicantSupportAgencyExistsData, setApplicantSupportAgencyExistsData] = useState<any[]>([]);
    const [appEsaOrganization, setAppEsaOrganization] = useState('');
    const [appEsaContactNumber, setAppEsaContactNumber] = useState('');
    const [appEsaUrl, setAppEsaUrl] = useState('');
    const [appEsaCanContact, setAppEsaCanContact] = useState(false);
    const [appEsaStartDate, setAppEsaStartDate] = useState('');
    const [appEsaEndDate, setAppEsaEndDate] = useState('');
    const [appEsaTrainingContent, setAppTrainingContent] = useState('');
    const [isStartDateModal, setIsStartDateModal] = useState(false);
    const [isEndDateModal, setIsEndDateModal] = useState(false);
    const [minYear, setMinYear] = useState<any>(null);
    const [maxYear, setMaxYear] = useState<any>(null);
    const [year, setYear] = useState<any>(null);
    const [month, setMonth] = useState<any>(null);
    const [endYear, setEndYear] = useState<any>(null);
    const [endMonth, setEndMonth] = useState<any>(null);
    const [baseMonth, setBaseMonth] = useState<any>(null);
    const [baseMonthThanAgo, setBaseMonthThanAgo] = useState(true);
    const [onlyPast, setOnlyPast] = useState(false);
    const [startDateModalSlide, setStartDateModalSlide] = useState(0);
    const [endDateModalSlide, setEndDateModalSlide] = useState(0);
    const [considerationSlide, setConsiderationSlide] = useState(0);
    const [considerationRequiredSlide, setConsiderationRequiredSlide] = useState(0);
    const [selectedStartYear, setSelectedStartYear] = useState<any>(null);
    const [selectedEndYear, setSelectedEndYear] = useState<any>(null);
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState(''); 
    const [considerationConditionTypes, setConsiderationConditionTypes] = useState<any>(null); 
    const [navigation_book, setNavigationBook] = useState(null); 
    const [considerationConditionsModal, setConsiderationConditionsModal] = useState(false); 
    const [considerationConditionsRequiredModal, setConsiderationConditionsRequiredModal] = useState(false); 
    const [consideration_conditions_preferred, setConsiderationConditionsPreferred] = useState<any[]>([]);
    const [consideration_conditions_required, setConsiderationConditionsRequired] = useState<any[]>([]);
    const [consideration_conditions_requiredBeforedChecked, setConsiderationConditionsRequiredBeforedChecked] = useState<any[]>([]);
    const [consideration_conditions_preferredBeforedChecked, setConsiderationConditionsPreferredBeforedChecked] = useState<any[]>([]);
    const [consideration_condition_supplemental, setConsiderationConditionSupplemental] = useState<any>(null);
    const [consideration_condition_supplemental_preferred, setConsiderationConditionSupplementalPreferred] = useState('');
    const [edited,setEdited] = useState(false);
    const [considerationConditionSupplementalPreferredMaxlengthError, setConsiderationConditionSupplementalPreferredMaxLengthError] = useState(false);
    const [considerationConditionSupplementalPreferredMaxlengthCount, setConsiderationConditionSupplementalPreferredMaxLengthCount] = useState(0);
    const [considerationConditionSupplementalMaxlengthError, setConsiderationConditionSupplementalMaxLengthError] = useState(false);
    const [considerationConditionSupplementalMaxlengthCount, setConsiderationConditionSupplementalMaxLengthCount] = useState(0);
    const [trainingContentMaxlengthError, setTrainingContentMaxLengthError] = useState(false);
    const [trainingContentMaxlengthCount, setTrainingContentMaxLengthCount] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pdf, setPdf] = useState('');
    let textValueProcess ='経緯と経過（２００文字以内）';
    let textValueCharacter ='特性と対策（２００文字以内）';
    let currentSlideNumber = 0;
    let textValue = 'textValue';
    let dataSetNames = ['該当する障がい区分', '該当する障がい', '障がい者手帳の有無', '該当する障がい者手帳の種別'];  
    let dataSetNamesYear = ['年', '月'];
    const dispatch = useDispatch();   
    const navigate = useNavigate(); 
    const loginData = useSelector((state: RootState) => state.login);
    const applicants = useSelector((state: RootState) => state.applicants);
    const regions = useSelector((state: RootState) => state.regions);
    const [tempolary, settempolary] = useState(false);
    const putregion = useSelector((state: RootState) => state.putregion);
    const [loadScreen, setLoadScreen] = useState(true);
    const pageTitle = 'WEB履歴書（障害について）｜パラちゃんねる';
    document.title = pageTitle;
    const queryParams = new URLSearchParams(window.location.search);
    const refs = useRef<any>(null);
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthToken')) {
          navigate('/login');
        } 
      }, [navigate, dispatch]);

    useEffect(() => {
        dispatch(fetchRegionsRequest());
        if(queryParams.get('tab') != null || queryParams.get('next') != null) {
            setTimeout(() => {              
                refs.current?.scrollIntoView({behavior:"smooth", block:"start"})
            }, 0);
        }
    }, [dispatch]);

     useEffect(() => {
      if (loginData.pending) {
        if(!loginData.login.is_init_complete) {
          navigate('/mypage/init/');
        }
        setResourceId(loginData.login.resource.id);
        setFamylyName(loginData.login.resource.family_name);
        setAuthenticate(loginData.login.resource.id);
        dispatch(fetchApplicantsRequest(loginData.login.resource.id, 'get', 0));
      }
    }, [dispatch, loginData.pending]);

    useEffect(() => {
        if(!edited) return;

        window.addEventListener("beforeunload", confirmSave);

        return () => window.removeEventListener("beforeunload", confirmSave);

    },[edited]);

    function confirmSave (event: any) {
        event.preventDefault();
        if (edited) {
            event.returnValue = "編集中のデータは保存されていませんが、移動しますか？"
        }
    }

    useEffect(() => {
        if (applicants.pending) {
            applicant = applicants.applicants.applicant;            
            let support_agency = applicant.employment_support_agency;
            setApplicantSupportAgencyExists(support_agency.support_agency_exists);
            setApplicantSupportAgencyExistsData(support_agency.support_agency_exists);
            setApplicantHandicapQualifications(applicant.handicap_qualifications);
            setAppEsaOrganization(support_agency.organization);
            setAppEsaContactNumber(support_agency.contact_number);
            setAppEsaUrl(support_agency.url);
            setAppEsaCanContact(support_agency.can_contact);
            setSelectedStartYear(support_agency.start_date);
            setSelectedEndYear(support_agency.end_date);
            setAppTrainingContent(support_agency.training_content);
            setSelectedStartDate(support_agency.start_date);
            setSelectedEndDate(support_agency.end_date);
            let maxYear = dayjs().year();
            let minYear = maxYear - 40;
            if(loginData.login.resource.birthday) {
                minYear = parseInt(loginData.login.resource.birthday.substring(0, 4)) + 18;
            }

            setMaxYear(maxYear);
            setMinYear(minYear);
            setConsiderationConditionSupplemental(applicant.consideration_condition_supplemental);
            setConsiderationConditionSupplementalPreferred(applicant.consideration_conditions_preferred_supplemental);
            if (applicant.consideration_conditions_preferred !== null && applicant.consideration_conditions_preferred !== undefined) {
                setConsiderationConditionsPreferred(applicant.consideration_conditions_preferred);
                setConsiderationConditionsPreferredBeforedChecked(applicant.consideration_conditions_preferred);
            }
            if (applicant.consideration_conditions_required !== null && applicant.consideration_conditions_required !== undefined) {
                setConsiderationConditionsRequired(applicant.consideration_conditions_required);
                setConsiderationConditionsRequiredBeforedChecked(applicant.consideration_conditions_required);
            }
            let p = [];
            let c = [];
            for(let i = 0; i < applicant.handicap_qualifications.length; i++) {
                if(applicant.handicap_qualifications[i].process !== '' && applicant.handicap_qualifications[i].process !== null) {
                    p.push(true);
                } else {
                    p.push(false);
                } 
                if(applicant.handicap_qualifications[i].characteristics_and_countermeasures !== null && applicant.handicap_qualifications[i].characteristics_and_countermeasures !== '') {
                    c.push(true);
                } else {
                    c.push(false);
                }
                setAreaOpenProcess(p);
                setAreaOpenCharacter(c);
            }
            
            if (applicant.consideration_condition_supplemental !== null && applicant.consideration_condition_supplemental !== '') {
                setAreaOpenConsiderationRequired(true);
            } else {
                setAreaOpenConsiderationRequired(false);
            }
            if (applicant.consideration_condition_supplemental_preferred !== null && applicant.consideration_condition_supplemental_preferred !== '') {
                setAreaOpenConsideration(true);
            } else {
                setAreaOpenConsideration(false);
            }
            setApplicantConsiderationConditionsRequiredExists(applicant.consideration_conditions_required_exists);
            setNavigationBook(applicant.navigation_book)
            setLoadScreen(false);
        } else if(applicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [applicants]);

    useEffect(() => {
        if (regions.pending) {
            setHandicaps(regions.regions.handicaps);
            setHandicapTypes(regions.regions.handicap_types);
            setDisabilityCertificateStatuses(regions.regions.disability_certificate_statuses);
            setDisabilityCertificates(regions.regions.disability_certificates);
            setConsiderationConditionsMasterData(regions.regions.consideration_conditions);
            setConsiderationConditionTypes(regions.regions.consideration_condition_types);
        }
    }, [regions.pending]);

    const formatYM = (dateString : any) => {
        if (dateString === '9999-12') {
            return `終了月なし（継続中）`;
        } else if (dateString === null) {
            return ''
        } 
        const [year, month] = dateString.split('-').map((component: any) => parseInt(component));
        return `${year}年${month}月`;
    };

    const handleRadioChange = (event: any) => {
        setEdited(true);
        const value = event.target.value === 'true';
        setApplicantConsiderationConditionsRequiredExists(value);
    }

    const handleRadioChange2 = (event: any) => {
        setEdited(true);
        const value = event.target.value === 'true';
        setApplicantSupportAgencyExists(value);
        filteredYears();
    }

    const handleRadioChange3 = (event: any) => {
        setEdited(true);
        const value = event.target.value === 'true';
        setAppEsaCanContact(value);
    }

    function closeHandicapQualifications() {
        currentSlideNumber = 0;
        setIsModalHandicapQualifications(false);
    }

    function prev() {
        currentSlideNumber = -1;
        setCurrentSlide(0);
        setStartDateModalSlide(0);
        setEndDateModalSlide(0);
        setIsModalHandicapQualificationsSlide(-1);
    }

    function prevConsiderationRequired() {
        setConsiderationRequiredSlide(considerationRequiredSlide - 1);
    }

    function prevConsiderationPreferred() {
        setConsiderationSlide(considerationSlide - 1);
    }

    const completeDisabilityCertificateStatusId = (data: any ) => {
        currentSlideNumber = 3;
        setIsModalHandicapQualificationsSlide(3);
        setDisabilityCertificateStatusId(data);
        if (data !== 2) {
            const val = null;
            completeDisabilityCertificateId(val, data);
        }
    }
    const [updateflg, setUpdateflg] = useState(false);
    const [indexflg, setindexflg] = useState<number>(0);
    const handleFlgHandi = (index: number) => {
        setEdited(true);
        setindexflg(index);
        setUpdateflg(true);
        setIsModalHandicapQualifications(true);
    };

    const completeDisabilityCertificateId = (data: any, cfid: any ) => {
        if (cfid === 0 ) {
            cfid = disability_certificate_status_id;
        }
        currentSlideNumber = 0;
        setIsModalHandicapQualifications(false);
        setIsModalHandicapQualificationsSlide(0);
        setDisabilityCertificateId(data);

        if (updateflg) {
            handicapqualifications(data, indexflg, cfid);
        } else {
            const newHandi = {
                handicap_id: handicap_id,
                disability_certificate_status_id: cfid,
                disability_certificate_id: data,
                disability_certificate_acquisition_month: null,
                characteristics_and_countermeasures: "",
                process: ""
            };
            setApplicantHandicapQualifications([...applicantHandicapQualifications, newHandi]);
            setHandicapId(null);
            setHandicapTypeId(null);
            setDisabilityCertificateStatusId(null);
            setDisabilityCertificateId(null);
        }
    }

    const handicapqualifications = (data: any , index: any, cfid: any) => {

        if (cfid === 0 ) {
            cfid = disability_certificate_status_id;
        }
        const updatevalue = {
                handicap_id: handicap_id,
                disability_certificate_status_id: cfid,
                disability_certificate_id: data,
                disability_certificate_acquisition_month: null,
          };
        
          const updatedAppeals = [...applicantHandicapQualifications];
          updatedAppeals[index] = {
            ...updatedAppeals[index], 
            ...updatevalue, 
          };
          setApplicantHandicapQualifications(updatedAppeals);
          setHandicapId(null);
          setHandicapTypeId(null);
          setDisabilityCertificateStatusId(null);
          setDisabilityCertificateId(null);
          setUpdateflg(false);
          setindexflg(0);
    };

    const findValue = (value: any, masterName: string | number) => {
        const dataSet = regions.regions[masterName];
        if (dataSet === undefined) {
          return null;
        }
        return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value))?.name : '未登録';
    };
      
    const findValue2 = (value: any, masterName: string | number) => {
        const dataSet = regions.regions[masterName];
        if (dataSet === undefined) {
            return null;
        }
        return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value)).class_name : '未登録';
    };
    const findValue3 = (value: any) => {
        const dataSet = regions.regions.handicaps;
        if (dataSet === undefined) {
            return null;
        }
        return value && value !== undefined ? dataSet.find((v: { id: number; }) => v.id === Number(value)).handicap_type_id : '未登録';
    };

    function toggleOpenProcess (id: any) {
        setEdited(true);
        if (readOnlyProcess) { return }
        let c = [];
        for(let i = 0; i < AreaOpenProcess.length; i++) {
            c.push(AreaOpenProcess[i]);
        }
        c[id] = !c[id];
        setAreaOpenProcess(c);
    }

    function toggleOpenCharacter (id: any) {
        setEdited(true);
        if (readOnlyCharacter) { return }
        if (readOnlyProcess) { return }
        let c = [];
        for(let i = 0; i < AreaOpenCharacter.length; i++) {
            c.push(AreaOpenCharacter[i]);
        }
        c[id] = !c[id];
        setAreaOpenCharacter(c);
    }

    function toggleOpenConsideration () {
        setEdited(true);
        if (readOnlyConsideration) { return }
        setAreaOpenConsideration(!AreaOpenConsideration);
    }

    function toggleOpenConsiderationRequired () {
        setEdited(true);
        if (readOnlyConsiderationRequired) { return }
        setAreaOpenConsiderationRequired(!AreaOpenConsiderationRequired);
    }

    function openModal() {
        setEdited(true);
        setIsModalHandicapQualifications(true);
    }

    function deleteitem(e: any) {
        setEdited(true);
        let c = [];
        for(let i = 0; i < applicantHandicapQualifications.length; i++) {
            c.push(applicantHandicapQualifications[i]);
        }
        if(c[e]) {
            c.splice(e, 1);
        }
        setApplicantHandicapQualifications(c);
    }

    function onChangeESA(e: any) {
        setEdited(true);
        const {name, value} = e.target;
        if(name === 'organization') {            
            setAppEsaOrganization(value);
        } 
        if(name === 'contact_number') {            
            setAppEsaContactNumber(value);
        } 
        if(name === 'url') {            
            setAppEsaUrl(value);
        } 
    }

    function startDateModal() {
        setEdited(true);
        filteredYears();
        setIsStartDateModal(true);          
    }

    function endDateModal() {
        setEdited(true);
        if(selectedStartDate){
            filteredEndYears()
        }
        setIsEndDateModal(true);
    }

    const filteredYears = () => {
        let start = null;
        let end = null;
        // if (baseMonth) {
        //     start = minYear;
        //     end = Number(baseMonth.split('-')[0]);
        // } else {
           start = minYear;
           end = maxYear;
        // }
        const years = [];
        const count = end - start;
        for (let i = 0; i <= count; i++) {
          years.push(end - i);
        }
        setYear(years);
        filteredMonths();
      }


    function filteredEndYears () {
        let start = null;
        let end = null;
        
        if (baseMonthThanAgo) {
            start = Number(selectedStartYear.split('-')[0]);
            end = maxYear;
        } else {
            start = minYear;
            end = Number(selectedStartYear.split('-')[0]);
        }
        const years = [];
        const count = end - start;
        for (let i = 0; i <= count; i++) {
            years.push(end - i);
        }
        setEndYear(years);
        filteredEndMonths();
    }

    function filteredMonths () {
        const months = [];
        let start = 1;
        let end = 12;
        if (baseMonth) {
          if (year == dayjs().year()) {
            end = dayjs().month() + 1;
          } else if (baseMonth && year == baseMonth.split('-')[0]) {
            if (baseMonthThanAgo) {
              start = Number(baseMonth.split('-')[1]);
            } else {
              end = Number(baseMonth.split('-')[1]);
            }
          }
        }
        if (onlyPast && year == dayjs().year()) {
          end = dayjs().month() + 1;
        }
        for (let i = start; i <= end; i++) {
          months.push(i);
        }
        setMonth(months);  
    }

    function filteredEndMonths () {
        const months = [];
        let start = 1;
        let end = 12;
        if (baseMonth) {
          if (year == dayjs().year()) {
            end = dayjs().month() + 1;
          } else if (baseMonth && year == baseMonth.split('-')[0]) {
            if (baseMonthThanAgo) {
              start = Number(baseMonth.split('-')[1]);
            } else {
              end = Number(baseMonth.split('-')[1]);
            }
          }
        }
        if (onlyPast && year == dayjs().year()) {
          end = dayjs().month() + 1;
        }
        for (let i = start; i <= end; i++) {
          months.push(i);
        }
        setEndMonth(months);  
    }

    function closeStartDateModal () {
        currentSlideNumber = 0;
        setIsStartDateModal(false);
        setIsEndDateModal(false);
        setCurrentSlide(0);
    }

    function chooseStartYear(e: any) {
        setCurrentSlide(1);
        setStartDateModalSlide(1);
        setSelectedStartYear(e.target.value); 
    } 

    function chooseStartMonth(e: any) {
        setIsStartDateModal(false);
        if(selectedStartYear !== null && e.target.value !== null) {
            setSelectedStartDate(`${selectedStartYear}-${e.target.value}`);
            setBaseMonth(selectedStartYear+'-'+e.target.value);
        }
        setStartDateModalSlide(0);
        setCurrentSlide(0);
        filteredEndYears();      
    } 

    function closeEndDateModal () {
        setCurrentSlide(0);
        currentSlideNumber = 0;
        setIsEndDateModal(false);
    }

    function chooseEndYear(e: any) {
        if (e.target.value.includes('9999-12')) {
            setSelectedEndDate(e.target.value)
            setIsEndDateModal(false);
        } else {
            setCurrentSlide(1);
            setEndDateModalSlide(1);
            setSelectedEndYear(e.target.value);
        } 
    } 

    function chooseEndMonth(e: any) {
        setIsEndDateModal(false);
        if(selectedEndYear !== null && e.target.value !== null) {
            setSelectedEndDate(`${selectedEndYear}-${e.target.value}`);
        }
        setEndDateModalSlide(0);
        setCurrentSlide(0);
    } 

    const considerationConditionsModalToggle = () => {
        setConsiderationConditionsModal(!considerationConditionsModal);
    };

    const considerationConditionsModalNext = () => {
        if(considerationSlide == 0) {
            if(consideration_conditions_preferredBeforedChecked.length > 0) {
                if (!consideration_conditions_preferred.includes(consideration_conditions_preferredBeforedChecked)) {
                    setConsiderationConditionsPreferred(consideration_conditions_preferredBeforedChecked);
                } 
            }
            setConsiderationSlide(considerationSlide + 1);
        } else if(considerationSlide == 1) {
            if(consideration_conditions_preferredBeforedChecked.length > 0) {
                if (!consideration_conditions_preferred.includes(consideration_conditions_preferredBeforedChecked)) {
                    setConsiderationConditionsPreferred(consideration_conditions_preferredBeforedChecked);
                } 
            }
            setConsiderationSlide(considerationSlide + 1);
        } else if (considerationSlide == 2) {                    
            if(consideration_conditions_preferredBeforedChecked.length > 0) {
                if (!consideration_conditions_preferred.includes(consideration_conditions_preferredBeforedChecked)) {
                    setConsiderationConditionsPreferred(consideration_conditions_preferredBeforedChecked);
                } 
            }
            setConsiderationConditionsModal(false);
            setConsiderationSlide(0);
            // setConsiderationConditionsPreferredBeforedChecked([]);
        }
        consideration_conditions_preferredBeforedChecked.sort((a, b) => a - b);
        consideration_conditions_preferred.sort((a, b) => a - b);
    };
    const considerationRequiredModalToggle = () => {
        setEdited(true);
        setConsiderationConditionsRequiredModal(!considerationConditionsRequiredModal);
    };

    const considerationRequiredModalNext = () => {
        setEdited(true);  
        if (considerationRequiredSlide == 0) {            
            if(consideration_conditions_requiredBeforedChecked.length > 0) {
                if (!consideration_conditions_required.includes(consideration_conditions_requiredBeforedChecked)) {
                    setConsiderationConditionsRequired(consideration_conditions_requiredBeforedChecked);
                } 
            }
            setConsiderationRequiredSlide(considerationRequiredSlide + 1);
        } else if (considerationRequiredSlide == 1) {            
            if(consideration_conditions_requiredBeforedChecked.length > 0) {
                if (!consideration_conditions_required.includes(consideration_conditions_requiredBeforedChecked)) {
                    setConsiderationConditionsRequired(consideration_conditions_requiredBeforedChecked);
                } 
            }
            setConsiderationRequiredSlide(considerationRequiredSlide + 1);
        } else if (considerationRequiredSlide == 2) {
            if(consideration_conditions_requiredBeforedChecked.length > 0) {
                if (!consideration_conditions_required.includes(consideration_conditions_requiredBeforedChecked)) {
                    setConsiderationConditionsRequired(consideration_conditions_requiredBeforedChecked);
                } 
            }
            setConsiderationConditionsRequiredModal(false);
            setConsiderationRequiredSlide(0);
            // setConsiderationConditionsRequiredBeforedChecked([]);
        } 
        consideration_conditions_requiredBeforedChecked.sort((a, b) => a - b);
        consideration_conditions_required.sort((a, b) => a - b);
    };
   
    const handleInputBussinesContent = (event: any) => {
        if(event.target.value.length > 200) {
            setTrainingContentMaxLengthError(true);
            setTrainingContentMaxLengthCount(event.target.value.length - 200);
        } else {
            setTrainingContentMaxLengthError(false);            
        }
        setEdited(true);
        setAppTrainingContent(event.target.value);
    };
    const handleInputsupplment = (event: any) => {
        setEdited(true);
        setConsiderationConditionSupplemental(event.target.value);        
        if(event.target.value.length > 200) {
            setConsiderationConditionSupplementalMaxLengthError(true);
            setConsiderationConditionSupplementalMaxLengthCount(event.target.value.length - 200);
        } else {            
            setConsiderationConditionSupplementalMaxLengthError(false);
        }
    };
    const handleInputsupplmentPreferred = (event: any) => {
        setEdited(true);
        setConsiderationConditionSupplementalPreferred(event.target.value);
        if(event.target.value.length > 200) {
            setConsiderationConditionSupplementalPreferredMaxLengthError(true);
            setConsiderationConditionSupplementalPreferredMaxLengthCount(event.target.value.length - 200);
        } else {
            setConsiderationConditionSupplementalPreferredMaxLengthError(false);            
        }
    };

    const handleInputProcess = (event: any, index: number) => {               
        setEdited(true);
        const text = (event.target.value);
        const maxLenCount = (event.target.value.length);        
        let maxlenCheck = false;
        const updatevalue = {
            process: text,
            processMaxLength: maxlenCheck,
            processLengthCount: maxLenCount,
        }; 
        const updatedAppeals = [...applicantHandicapQualifications];
        updatedAppeals[index] = {
            ...updatedAppeals[index], 
            ...updatevalue, 
        };
        if(maxLenCount > 200) {
            maxlenCheck = true;
            const updatevalue2 = {
                process: text,
                processMaxLength: maxlenCheck,
                processLengthCount: maxLenCount,
            }; 
            updatedAppeals[index] = {
                ...updatedAppeals[index], 
                ...updatevalue2, 
            };
        }          
        setApplicantHandicapQualifications(updatedAppeals);
    };
    const handleInputCharacteristics = (event: any, index: number) => {
        setEdited(true);
        const maxLenCount = (event.target.value.length);        
        let maxlenCheck = false;
        const text = (event.target.value);
        const updatevalue = {
            characteristics_and_countermeasures: text,
            characterMaxLength: maxlenCheck,
            characterMaxLengthCount: maxLenCount
          };
        
        const updatedAppeals = [...applicantHandicapQualifications];
        updatedAppeals[index] = {
        ...updatedAppeals[index], 
        ...updatevalue, 
        };
        if(maxLenCount > 200) {
            maxlenCheck = true;
            const updatevalue2 = {
                characteristics_and_countermeasures: text,
                characterMaxLength: maxlenCheck,
                characterMaxLengthCount: maxLenCount,
            }; 
            updatedAppeals[index] = {
                ...updatedAppeals[index], 
                ...updatevalue2, 
            };
        } 
        setApplicantHandicapQualifications(updatedAppeals);
    };

    const handleConsiderationRequiredCheckbox = (e: any, id: any) => {
        const value = id;
        if (consideration_conditions_requiredBeforedChecked.includes(value)) {
            setConsiderationConditionsRequiredBeforedChecked(consideration_conditions_requiredBeforedChecked.filter(skill => skill !== value));
        } else {
            setConsiderationConditionsRequiredBeforedChecked([...consideration_conditions_requiredBeforedChecked, value]);
        }
    };
    const handleConsiderationPreferredCheckbox = (e: any, id: any) => {
        const value = id;
        if (consideration_conditions_preferredBeforedChecked.includes(value)) {
            setConsiderationConditionsPreferredBeforedChecked(consideration_conditions_preferredBeforedChecked.filter(skill => skill !== value));
        } else {
            setConsiderationConditionsPreferredBeforedChecked([...consideration_conditions_preferredBeforedChecked, value]);
        }
    };
    const filteredData = considerationConditionsMasterData.filter(data => {
        const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
        if (applicantHandicapQualifications.length == 0) {
          return data.consideration_condition_type_id == 1;
        } else {
            return (
                data.consideration_condition_type_id == 1 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
            );
        }
      });      
      const filteredData2 = considerationConditionsMasterData.filter(data => {
        const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
        if (applicantHandicapQualifications.length == 0) {
          return data.consideration_condition_type_id == 2;
        } else {
          return (
            data.consideration_condition_type_id == 2 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
            && !consideration_conditions_required.includes(data.id) && !consideration_conditions_preferred.includes(data.id)
         );
        }
      });
      const filteredData3 = considerationConditionsMasterData.filter(data => {
        const consideration_types = applicantHandicapQualifications.map((i) => findValue3(i.handicap_id));
        if (applicantHandicapQualifications.length == 0) {
          return data.consideration_condition_type_id == 3;
        } else {
          return (
            data.consideration_condition_type_id == 3 && data.handicap_type_ids.some((id:any) => consideration_types.includes(id))
            && !consideration_conditions_required.includes(data.id) && !consideration_conditions_preferred.includes(data.id)
         );
        }
      });

    const [fileName, setFileName] = useState<string>('');
    const [navigationBook, setNavigationBookUploaded] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEdited(true);
        const file = event.target.files?.[0];

        if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setNavigationBookUploaded(reader.result as string);
        };

        setFileName(file.name);
        }
    };
    
    useEffect(() => {
        if (putregion.pending) {
          if (localStorage.entryFlag) {
            if(window.confirm("エントリーページへ戻りますか？")) {
              let recruitment_id = localStorage.entryFlag;
              localStorage.removeItem('entryFlag');
              window.location.href ='/recruitments/' + recruitment_id + '/entry/';
              return;
            }
          }
     
          if (tempolary) {
              alert('保存しました');
              navigate('/mypage/');
              return;
          } 
        //   navigate('/mypage/profile3/');
        window.location.href = '/mypage/profile3/?next=true';
        } else if(putregion.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
      }, [putregion]);

    function submit (e: any, tempolary1 = false) {
        let error = false;
        for(let i = 0; i < applicantHandicapQualifications.length; i++) {
            if(applicantHandicapQualifications[i].processMaxLength){
                error = applicantHandicapQualifications[i].processMaxLength;
            }
            if(applicantHandicapQualifications[i].characterMaxLength){
                error = applicantHandicapQualifications[i].characterMaxLength;
            }
        }
        if(considerationConditionSupplementalMaxlengthError == true) {
            error = true;
        }
        if(considerationConditionSupplementalPreferredMaxlengthError == true) {
            error = true;
        }        
        if(!tempolary1) {
            e.preventDefault();
        }
        if (error) {
            window.alert('文字数がオーバーしています');
            return
        }
        if (applicantConsiderationConditionsRequiredExists && consideration_conditions_required.length === 0) {
            window.alert('就労上必須の配慮事項を選択してください')
            return
        }

        const data: { applicant: any } = {
          applicant: {
            handicap_qualifications: applicantHandicapQualifications,
            consideration_conditions_required_exists: applicantConsiderationConditionsRequiredExists,
            consideration_conditions_required: consideration_conditions_required,
            consideration_conditions_preferred: consideration_conditions_preferred,
            consideration_condition_supplemental: consideration_condition_supplemental,
            consideration_conditions_preferred_supplemental: consideration_condition_supplemental_preferred,
            navigation_book: navigationBook,
            employment_support_agency: {
                support_agency_exists: applicantSupportAgencyExists,
                organization: applicantSupportAgencyExists ? appEsaOrganization:null,
                contact_number: applicantSupportAgencyExists ? appEsaContactNumber:null,
                url: applicantSupportAgencyExists ? appEsaUrl:null,
                can_contact: applicantSupportAgencyExists ? appEsaCanContact:false,
                start_date:applicantSupportAgencyExists ? selectedStartDate:null,
                end_date: applicantSupportAgencyExists ? selectedEndDate:null,
                training_content:applicantSupportAgencyExists ? appEsaTrainingContent:null
            },
          }
        }
        if(navigationBook == null) {
            delete data.applicant.navigation_book;
        }
        setEdited(false);
        settempolary(tempolary1);
        setLoadScreen(true);
        dispatch(fetchPutRegionRequest(resourceId, data));
    }

    return (
      <main id="mypage">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="ApplicantList" />
        {loadScreen ? <Loader /> : <></>}
        <section id="mypage_inner">
            <ProfileIndex authenticate={authenticate} navigation_book_url={navigation_book}/>
            <ProfileTab ref={refs} index={2} />
            <form className="profile wrapper">
                <div className="cont1">
                    <h3 className="ttl">障がいについて</h3>
                    <p className="ex_info">※ <span className="required">必須</span>が付いている項目はエントリーに必要な情報です。 </p>
                    <div className="wrapper">
                        <table>
                            <tbody className="other care">
                                <tr>
                                    <th>障がいについて <span className="required">必須</span>
                                    </th>
                                    <td className="nest nest_mobile_handicap">
                                        {applicantHandicapQualifications.length !== 0 ?
                                        <div className="box">
                                            {applicantHandicapQualifications.map((i, index) => (
                                            <div className="nest_child">                                        
                                                <div className="nest_child">
                                                    <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                                        <div className="txt_cont" onClick={() => handleFlgHandi(index)}>                                                    
                                                            <p>
                                                            {findValue(findValue3(i.handicap_id), 'handicap_types')}&nbsp; {findValue(i.handicap_id, 'handicaps')} <br />
                                                            {i.disability_certificate_status_id === 2 &&  '障がい手帳あり'}{i.disability_certificate_status_id === 1 &&  '障がい手帳なし'}{i.disability_certificate_status_id === 3 && '障がい手帳申請中'}  {findValue(i.disability_certificate_status_id, 'disability_certificate_statuses')}<br />
                                                            {i.disability_certificate_status_id === 2 && findValue(i.disability_certificate_id, 'disability_certificates')}　{i.disability_certificate_status_id === 2 && findValue2(i.disability_certificate_id, 'disability_certificates')}
                                                        
                                                        </p>
                                                        </div>
                                                        <div className="icon_cont">
                                                            <span className="edit">
                                                                <img src="/assets/img/mypage/memo_icon.svg" alt="" onClick={() => handleFlgHandi(index)}/>
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>

                                            <div className="nest_child">
                                                <div className="textarea_cont">
                                                    <h4 className={AreaOpenProcess[index] ? "open acc_h4" : "acc_h4" && readOnlyProcess ? "" : "acc_h4"}>
                                                        <a onClick={() => toggleOpenProcess(index)}>
                                                            {textValueProcess}  <span className="any">任意</span>
                                                        </a>
                                                    </h4>
                                                    {readOnlyProcess ?
                                                        <div>
                                                            <p>{ textValue }</p>
                                                        </div>
                                                    :<></>} 
                                                    {AreaOpenProcess[index] ?
                                                        <div>
                                                            <textarea aria-colspan={30} aria-rowspan={10}
                                                                title="経緯と経過（２００文字以内）" 
                                                                key={`process` + index}
                                                                onChange={(event) => {handleInputProcess(event, index)}}
                                                                value={i.process}
                                                                placeholder="発症時期:〇〇年〇〇月ごろ〜
                                                                    経緯:
                                                                    通院:月○回
                                                                    服薬:朝・昼・夕各○回">
                                                            </textarea>
                                                        </div>                                                
                                                    : <></> }
                                                </div>
                                                {applicantHandicapQualifications[index].processMaxLength && applicantHandicapQualifications[index].processMaxLength == true ?
                                                    <p className="text_length_alert">{applicantHandicapQualifications[index].processLengthCount - 200}文字オーバー</p>
                                                : <></>}
                                            </div>
                                            <div className="nest_child long">
                                                <div className="textarea_cont">
                                                    <h4 className={AreaOpenCharacter[index] ? "open acc_h4" : "acc_h4" && readOnlyCharacter ? "" : "acc_h4"}>
                                                        <a className={readOnlyCharacter ? "fixed" : ""} title='特性と対策（２００文字以内）' onClick={() => toggleOpenCharacter(index)}>  
                                                            {textValueCharacter} <span className="any">任意</span>
                                                        </a>
                                                    </h4>  
                                                    {readOnlyCharacter ?
                                                        <div>
                                                            <p>{ textValue }</p>
                                                        </div>
                                                    :<></>}                                               
                                                    {AreaOpenCharacter[index] ?
                                                        <div>
                                                            <textarea aria-colspan={30} aria-rowspan={10} 
                                                            key={`characteristics_and_countermeasures` + index}
                                                            value={i.characteristics_and_countermeasures} 
                                                            onChange={(event) => {handleInputCharacteristics(event, index)}} placeholder="私には以下のような特性がございますが、それぞれの特性に対し自助努力を継続する事で業務遂行をしております。
                                                                特性（1）
                                                                特性（2）
                                                                特性（3）

                                                                自己対応（1）
                                                                自己対応（2）
                                                                自己対応（3）">
                                                            </textarea>
                                                        </div>                                               
                                                    : null }
                                                </div>
                                                {applicantHandicapQualifications[index].characterMaxLength && applicantHandicapQualifications[index].characterMaxLength == true ?
                                                    <p className="text_length_alert">{applicantHandicapQualifications[index].characterMaxLengthCount - 200}文字オーバー</p>
                                                : <></>}
                                            </div>
                                            <div className="nest_child acc_list_cont" key={index}>
                                                {applicantHandicapQualifications.length - 1 == index ?
                                                    <p className="acc_ttl">
                                                        <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={openModal}>種別を追加する</a>
                                                    </p>
                                                : null }
                                                <p className="acc_ttl del">
                                                    <a onClick={(e)=>deleteitem(index)}>種別を削除する</a>
                                                </p>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    :<></>} 
                                    {applicantHandicapQualifications.length === 0 ?
                                        <div className="box">
                                            <div className="nest_child acc_list_cont">
                                                <p className="acc_ttl fixed" onClick={openModal}>
                                                    <a>種別を追加する</a>
                                                </p>
                                            </div>
                                        </div>
                                    :<></>} 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="cont1">
                    <h3 className="ttl">配慮事項</h3>
                    <div className="wrapper">
                    <table>
                        <tbody className="other care">
                        <tr>
                            <th>就労上必須の配慮事項<br className="pc_only"/>
                            <span className="required">必須</span>
                            </th>
                            <td className="nest nest_mobile_other">
                                <div className="nest_child radio_cont" style={{borderBottom: "1px solid #9e9f9f", width: "100%"}}>
                                    <ul className="radio_list">
                                        <li>
                                            <label>
                                                <input type="radio" name="consideration_conditions_required_exists" value="true" onChange={handleRadioChange} checked={applicantConsiderationConditionsRequiredExists === true}/>
                                                <span className="m-form-radio-name">
                                                    <span className="m-form-radio-text">有</span>
                                                </span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" name="consideration_conditions_required_exists" value="false" onChange={handleRadioChange} checked={applicantConsiderationConditionsRequiredExists === false}/>
                                                <span className="m-form-radio-name">
                                                    <span className="m-form-radio-text">無</span>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>    
                                {/* BOXCONSIDERATIONCONDITIONS */}
                                { applicantConsiderationConditionsRequiredExists ?
                                    <div>
                                        {consideration_conditions_required.length === 0 ? 
                                            <div className="nest_child acc_list_cont">
                                                <p className="acc_ttl fixed" onClick={considerationRequiredModalToggle}>
                                                    <a title="ポップアップが立ち上がります。TABキーを押して選択してください。">就労上必須の配慮事項を追加する</a>
                                                </p>
                                            </div>
                                        :
                                        <div className="nest_child" style={{borderBottom: "1px solid #9e9f9f"}}>
                                            <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                                <div  className="txt_cont"  onClick={considerationRequiredModalToggle}>
                                                    <p>
                                                        <span>
                                                            {consideration_conditions_required.map((id, index) => (
                                                                <React.Fragment key={id}>
                                                                    {index > 0 && ' / '}
                                                                    {findValue(id, 'consideration_conditions')}
                                                                </React.Fragment>
                                                            ))}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="icon_cont">
                                                    <span className="edit">
                                                        <img src="/assets/img/mypage/memo_icon.svg" alt="" onClick={() => considerationRequiredModalToggle()}/>
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        }
                                        { consideration_conditions_required.length !== 0 &&
                                            <div className="nest_child acc_list_cont">
                                                <p className="acc_ttl del"><a onClick={() => {setEdited(true);setConsiderationConditionsRequired([]); setConsiderationConditionsRequiredBeforedChecked([])}}>就労上必須の配慮事項を削除する</a></p>
                                            </div>
                                        }    
                                        <div className="nest_child">
                                            <div className="textarea_cont">
                                                <h4 className={readOnlyConsiderationRequired ? "open acc_h4" : "acc_h4" && readOnlyConsiderationRequired ? "" : "acc_h4"}>
                                                    <a className={readOnlyConsiderationRequired ? "" : "fixed"} onClick={toggleOpenConsiderationRequired}> 配慮事項（その他）（２００文字以内） 
                                                        <span className="any">任意</span>
                                                    </a>
                                                </h4>
                                                {readOnlyConsiderationRequired ?
                                                    <div>
                                                        <p>{ textValue }</p>
                                                    </div>
                                                :<></>}  
                                                {AreaOpenConsiderationRequired ?
                                                    <div>
                                                        <textarea aria-colspan={30} aria-rowspan={10} 
                                                        placeholder="選択肢にない配慮事項がございましたら、ご自由にお書きください。" 
                                                        value={consideration_condition_supplemental} onChange={handleInputsupplment}></textarea>
                                                    </div>
                                                :<></>} 
                                                {considerationConditionSupplementalMaxlengthError == true ?
                                                    <p className="text_length_alert">{considerationConditionSupplementalMaxlengthCount}文字オーバー</p>
                                                : <></>}
                                            </div>
                                        </div>
                                    </div>
                                :<></>} 
                            </td>
                        </tr>
                        
                        <tr>
                            <th>あると嬉しい配慮事項 <br className="pc_only"/>
                                <span className="any">任意</span>
                            </th>
                            <td className="nest nest_mobile_other">
                                <div>
                                    {consideration_conditions_preferred.length === 0 ? 
                                        <div className="nest_child acc_list_cont">
                                            <p className="acc_ttl fixed" onClick={considerationConditionsModalToggle}>
                                                <a title="ポップアップが立ち上がります。TABキーを押して選択してください。">あると嬉しい配慮事項を追加する</a>
                                            </p>
                                        </div>
                                    :
                                    <div className="nest_child" style={{borderBottom: "1px solid #9e9f9f"}}>
                                        <a title="ポップアップが立ち上がります。TABキーを押して選択してください。" className="edit_box fixed">
                                            <div className="txt_cont" onClick={considerationConditionsModalToggle}>
                                                <p><span>{consideration_conditions_preferred.map((id, index) => (
                                                    <React.Fragment key={id}>
                                                        {index > 0 && ' / '}
                                                        {findValue(id, 'consideration_conditions')}
                                                    </React.Fragment>
                                                ))}</span></p>
                                            </div>
                                            <div className="icon_cont">
                                                <span className="edit">
                                                    <img src="/assets/img/mypage/memo_icon.svg" alt="" onClick={() => considerationConditionsModalToggle()}/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    }
                                </div>
                                { consideration_conditions_preferred.length !== 0 &&
                                    <div className="nest_child acc_list_cont">
                                        <p className="acc_ttl del"><a onClick={() => {setEdited(true);setConsiderationConditionsPreferred([]); setConsiderationConditionsPreferredBeforedChecked([]);}}>あると嬉しい配慮事項を削除する</a></p>
                                    </div>
                                }

                                <div className="nest_child">
                                    <div className="textarea_cont">
                                        <h4 className={AreaOpenConsideration ? "open acc_h4" : "acc_h4" && readOnlyConsideration ? "" : "acc_h4"}>
                                            <a className={readOnlyConsideration ? "" : "fixed"} onClick={toggleOpenConsideration}> 配慮事項（その他）（２００文字以内） <span className="any">任意</span>
                                            </a>
                                        </h4>
                                        {readOnlyConsideration ?
                                            <div>
                                                <p>{ textValue }</p>
                                            </div>
                                        :<></>}  
                                        {AreaOpenConsideration ?
                                            <div>
                                                <textarea aria-colspan={30} aria-rowspan={10} placeholder="選択肢にない配慮事項がございましたら、ご自由にお書きください。" 
                                                value={consideration_condition_supplemental_preferred} onChange={handleInputsupplmentPreferred}></textarea>
                                            </div>
                                        :<></>}                                         
                                        {considerationConditionSupplementalPreferredMaxlengthError == true ?
                                            <p className="text_length_alert">{considerationConditionSupplementalPreferredMaxlengthCount}文字オーバー</p>
                                        : <></>}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                
                <div className="cont1">
                    <h3 className="ttl">就労支援機関</h3>
                    <div className="wrapper">
                    <table>
                        <tbody className="other">
                        <tr>
                            <th>就労支援機関 <span className="any">任意</span>
                            </th>
                            <td className="nest">
                            <div className="nest_child radio_cont">
                                <ul className="radio_list">
                                <li>
                                    <label>
                                    <input type="radio" name="employment_support_agency" value="true" onChange={handleRadioChange2} checked={applicantSupportAgencyExists === true}/>
                                    <span className="m-form-radio-name">
                                        <span className="m-form-radio-text">有</span>
                                    </span>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                    <input type="radio" name="employment_support_agency" value="false" onChange={handleRadioChange2} checked={applicantSupportAgencyExists === false}/>
                                    <span className="m-form-radio-name">
                                        <span className="m-form-radio-text">無</span>
                                    </span>
                                    </label>
                                </li>
                                </ul>
                            </div>
                            {applicantSupportAgencyExists &&
                            <>
                                <div className="textarea_cont nest_child">
                                    <h4>機関・企業名</h4>
                                    <label>
                                        <input type="text" name="organization" placeholder="例）○○職業センター、○○福祉医療機構" value={appEsaOrganization} onChange={(e) => onChangeESA(e)} />
                                    </label>
                                </div><div className="textarea_cont nest_child">
                                    <h4>連絡先</h4>
                                    <label>
                                        <input type="text" name="contact_number" placeholder="0300000000" value={appEsaContactNumber} onChange={(e) => onChangeESA(e)} />
                                    </label>
                                </div><div className="textarea_cont nest_child">
                                    <h4>URL</h4>
                                    <label>
                                        <input type="text" name='url' placeholder="https://www.example.com" value={appEsaUrl} onChange={(e) => onChangeESA(e)} />
                                    </label>
                                </div><div className="nest_child radio_cont">
                                    <h4>エントリー企業との連携可否</h4>
                                    <ul className="radio_list">
                                        <li>
                                            <label>
                                                <input type="radio" name="" value="true" onChange={handleRadioChange3} checked={appEsaCanContact === true} />
                                                <span className="m-form-radio-name">
                                                    <span className="m-form-radio-text">可</span>
                                                </span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="radio" name="" value="false" onChange={handleRadioChange3} checked={appEsaCanContact === false} />
                                                <span className="m-form-radio-name">
                                                    <span className="m-form-radio-text">否</span>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div><div className="nest_child select_cont">
                                    <h4>利用期間 開始月</h4>
                                    <p className={selectedStartDate ? "comp select" : "select"}><a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={startDateModal}>
                                        {formatYM(selectedStartDate) || '選択してください'}
                                    </a></p>
                                </div><div className="nest_child select_cont">
                                    <h4>利用期間 終了月</h4>
                                    <p className={selectedEndDate ? "comp select" : "select"}><a title="ポップアップが立ち上がります。TABキーを押して選択してください。" onClick={endDateModal}>
                                        {formatYM(selectedEndDate) || '選択してください'}
                                    </a></p>
                                </div>
                                <div className="textarea_cont nest_child">
                                    <h4>訓練内容（２００文字以内）</h4> 
                                    <textarea cols={30} rows={10} placeholder="例）研修で学んだこと、グループワークなどで取り組んだ課題" value={appEsaTrainingContent} onChange={handleInputBussinesContent}></textarea> 
                                    {trainingContentMaxlengthError == true ?
                                        <p className="text_length_alert">{trainingContentMaxlengthCount}文字オーバー</p>
                                    : <></>}
                                </div></>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th>
                                ナビゲーションブック
                                <br className="pc_only" />
                                <span className="any">任意</span>
                            </th>
                            <td className="nest" >
                            <div className="upload_cont">
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                                    <label className="file_upload" htmlFor="file_upload">
                                    <p>
                                        <span className="text">ナビゲーションブックを<br />アップロードする</span>
                                        <span className="icon"><img src="/assets/img/mypage/upload_icon.svg" alt="" /></span>
                                    </p>
                                    <input type="file" id="file_upload" accept=".pdf" onChange={handleFileChange} />
                                    </label>
                                    {navigation_book ?
                                    <label style={{width:'40%', backgroundColor:'#ef8432',marginLeft:'5px', borderRadius:'6px', boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)'}}>
                                        <a style={{width:'100%', color:'white', padding:'0 20px'}} href={navigation_book} target='_blank'><p>アップロード済みのファイルを確認</p></a>
                                    </label>
                                    : <></>}
                                </div>
                                
                                <p className="file_name">{fileName || 'ファイルを選択してください'}</p>
                                <span className="support_txt">※対応ファイル： .pdf</span>
                            </div>
                            
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="btn_box">
                    <button type="button" onClick={(e) => submit(e, true)} className="btn type3">一時保存する</button> 
                    <button type="submit" onClick={(e) => submit(e, false)} className="btn type3 orange">保存して次へ         
                    <img src="/assets/img/mypage/arrow02.svg" alt=""/>
                </button>
                </div>
                </form>
            </section>

            {considerationConditionsRequiredModal &&
                <div role="dialog" className="modal_box">
                    <div className="modal_inner">
                        <h4>配慮事項</h4>
                        {considerationRequiredSlide > 0 ?
                            <p id="btn_prev" onClick={prevConsiderationRequired} className='prev'>戻る</p>
                        : null }
                        <form>
                            <h5 className="modal_ttl">就業に関する配慮を選択してください</h5>
                            <p className="note">※複数選択可能</p>
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                    <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                        <div data-index="0" aria-hidden="false" className="slick-slide slick-active slick-current" style={{outline: "none", width: "100%"}}>
                                            <div className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                            {considerationRequiredSlide == 0 &&
                                            filteredData
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                        <input type="checkbox" name="consideration_condition_type1" value={d.id} onClick={(e) => {handleConsiderationRequiredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                        <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            {considerationRequiredSlide == 1 &&
                                            filteredData2
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                    <input type="checkbox" name="consideration_condition_type2" value={d.id} onClick={(e) => {handleConsiderationRequiredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                    <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            {considerationRequiredSlide == 2 &&
                                            filteredData3
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                    <input type="checkbox" name="consideration_condition_type3" value={d.id} onClick={(e) => {handleConsiderationRequiredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_required.includes(d.id)}/>
                                                    <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p className="close"><span className="material-icons" onClick={considerationRequiredModalToggle}>close</span></p>
                        <p id="btn_next" onClick={considerationRequiredModalNext} >{considerationRequiredSlide == 2 ? '完了':'次へ'}</p>
                    </div>
                    <div className="overlay" onClick={considerationRequiredModalToggle}></div>
                </div>
            }
            {considerationConditionsModal &&
                <div role="dialog"  className="modal_box checkbox" >
                    <div className="modal_inner">
                        <h4 data-v-7074e5f8="">配慮事項</h4>
                        {considerationSlide > 0 ?
                            <p id="btn_prev" onClick={prevConsiderationPreferred} className='prev'>戻る</p>
                        : null }
                        <form data-v-7074e5f8="">
                            <h5 className="modal_ttl">就業に関する配慮を選択してください</h5>
                            <p className="note">※複数選択可能{applicantHandicapQualifications.some(i => (i.handicap_id))}</p>
                            <div dir="ltr" className="slider slick-slider slick-initialized">
                                <div className="slick-list">
                                    <div className="slick-track" style={{opacity: "1", transform: "translate3d(0px, 0px, 0px)", width: "100%"}}>
                                        <div data-index="0" aria-hidden="false" className="slick-slide slick-active slick-current" style={{outline: "none", width: "100%"}}>
                                            <div className="slider_item" style={{width: "100%", display: "inline-block"}}>
                                            {considerationSlide == 0 &&
                                            filteredData
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                        <input type="checkbox" name="consideration_condition_type1" value={d.id} onClick={(e) => { handleConsiderationPreferredCheckbox(e, d.id)}} defaultChecked={consideration_conditions_preferred.includes(d.id)}/>
                                                        <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            {considerationSlide == 1 &&
                                            filteredData2
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                        <input type="checkbox" name="consideration_condition_type2" value={d.id} onClick={(e) => {handleConsiderationPreferredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_preferred.includes(d.id)}/>
                                                        <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            {considerationSlide == 2 &&
                                            filteredData3
                                                .map((d, index) => (
                                                    <label className="column3" key={index}>
                                                        <input type="checkbox" name="consideration_condition_type3" value={d.id} onClick={(e) => {handleConsiderationPreferredCheckbox(e,d.id)}} defaultChecked={consideration_conditions_preferred.includes(d.id)}/>
                                                        <span>{d.name}</span>
                                                    </label>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p className="close"><span className="material-icons" onClick={considerationConditionsModalToggle}>close</span></p>
                        <p id="btn_next" onClick={considerationConditionsModalNext} >{considerationSlide == 2 ? '完了':'次へ'}</p>
                    </div>
                    <div className="overlay" onClick={considerationConditionsModalToggle}></div>
                </div>
            }

            {/* HANDICAP QUALIFICITIONS MODAL */}
            {isModalHandicapQualifications ?            
            <div className="modal_box">
                <div className="modal_inner">
                <h4>障がいについて</h4>
                {currentSlideNumber > 0 ?
                    <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                : null }
                <form>
                    <h5 className="modal_ttl">{ dataSetNames[currentSlideNumber] }を選択してください<span className="required">必須</span></h5>                    
                    { isModalHandicapQualificationsSlide === 0 ?
                        <div className="slider_item">
                            {handicapTypes.map((h: any) => (
                                <label key={h.id}>
                                    <input type="radio" name={h.name} v-model="handicapTypeId"  defaultChecked={h.id == handicapTypeId} value={h.id} onClick={() => {setHandicapTypeId(h.id); currentSlideNumber = 1; setIsModalHandicapQualificationsSlide(1);}}/>
                                    <span>{ h.name }</span>
                                </label>
                            ))} 
                        </div>
                    :<></>} 

                    { isModalHandicapQualificationsSlide === 1 ?
                        <div className="slider_item">
                            {handicaps.filter(data => data.handicap_type_id === handicapTypeId) 
                            .map((h: any, index: any) => (
                                <label key={index}>
                                    <input type="radio" name={h.name} v-model="handicapId" defaultChecked={h.id == handicap_id} value={h.id} onClick={() => {setHandicapId(h.id); currentSlideNumber = 2; setIsModalHandicapQualificationsSlide(2);}}/>
                                    <span>{ h.name }</span>
                                </label>
                            ))} 
                        </div>
                    :<></>} 
                    { isModalHandicapQualificationsSlide === 2 ?
                        <div className="slider_item">
                            {disabilityCertificateStatuses.map((dcs: any, index: any) => (
                                <label key={index}>
                                    <input type="radio" 
                                        name="disability_certificate_status" 
                                        v-model="disabilityCertificateStatusId"
                                        value={dcs.id} 
                                        defaultChecked={dcs.id == disability_certificate_status_id}
                                        onClick={() => {completeDisabilityCertificateStatusId(dcs.id)}} />
                                    <span>{ dcs.name }</span>
                                </label>
                            ))} 
                        </div>
                    :<></>} 
                    { isModalHandicapQualificationsSlide === 3 && disability_certificate_status_id === 2 ? 
                        <div className="slider_item">
                            {disabilityCertificates.filter(data => data.handicap_type_id === handicapTypeId) 
                            .map((h: any, index :any) => (
                                <label key={index}>
                                    <input type="radio" 
                                        name="disability_certificate" 
                                        defaultChecked={h.id == disability_certificate_id} 
                                        v-model="disabilityCertificateId" 
                                        value={h.id} 
                                        onClick={() => {completeDisabilityCertificateId(h.id, 0)}}/>
                                    <span>{ h.name + ' ' + h.class_name }</span>
                                </label>
                            ))} 
                        </div>
                    :<></>} 
                </form>
                <p className="close" onMouseUp={closeHandicapQualifications}><span className="material-icons">close</span></p>
                </div>
                <div className="overlay" onClick={closeHandicapQualifications}></div>
            </div>
            : null }
            
            {/* START DATE MODAL */}
            
            { isStartDateModal ? 
            <div className="modal_box">
                <div className="modal_inner">
                    <h4>利用期間の開始月</h4>
                    {currentSlide > 0 ?
                        <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                    : null }
                    <form>
                        <h5 className="modal_ttl">{ dataSetNamesYear[currentSlide] }を選択してください</h5>
                        <div className="slider">
                            { startDateModalSlide === 0 ? 
                                <div className="slider_item">
                                    {year && year.map((y: any) => (
                                        <label key={`year-${y}`}>
                                            <input
                                                type="radio"
                                                name="year"
                                                defaultChecked={y == year}
                                                value={y}
                                                checked={selectedStartDate && selectedStartDate.substring(0,4) == y ? true : false}
                                                onClick={chooseStartYear}
                                            />
                                            <span>{ y } 年</span>
                                        </label>
                                    ))}    
                                </div>
                            :<></>}
                            { startDateModalSlide === 1 ? 
                            <div className="slider_item">
                                {month && month.map((m: any) => (
                                    <label key={`month-${m}`}>
                                        <input
                                            type="radio"
                                            name="month"
                                            defaultChecked={m == month}
                                            value={m}
                                            checked={selectedStartDate && selectedStartDate.substring(5) == m ? true : false}
                                            onClick={chooseStartMonth}
                                        />
                                        <span>{ m } 月</span>
                                    </label>
                                ))}    
                            </div>
                            :<></>}
                        </div>
                    </form>
                    <p className="close" onMouseUp={closeStartDateModal}><span className="material-icons">close</span></p>
                 </div>
                <div className="overlay" onMouseUp={closeStartDateModal}></div>
            </div>
            :<></>}

            {/* END DATE MODAL */}
            
            { isEndDateModal ? 
            <div className="modal_box">
                <div className="modal_inner">
                    <h4>利用期間の開始月</h4>
                    {currentSlide > 0 ?
                        <p id="btn_prev" onClick={prev} className='prev'>戻る</p>
                    : null }
                    <form>
                        <h5 className="modal_ttl">{ dataSetNamesYear[currentSlide] }を選択してください</h5>
                        <div className="slider">
                            { endDateModalSlide === 0 ? 
                                <div className="slider_item">
                                    <label >
                                            <input
                                                type="radio"
                                                name="year"
                                                defaultChecked={!selectedEndDate ?? selectedEndDate.includes('9999-12')}
                                                value='9999-12'                                                
                                                onClick={chooseEndYear}
                                            />
                                            <span>終了月なし（継続中）</span>
                                        </label>
                                    {endYear.map((y: any) => (
                                        <label key={`year-${y}`}>
                                            <input
                                                type="radio"
                                                name="year"
                                                defaultChecked={y == year}
                                                value={y}
                                                checked={selectedEndDate && selectedEndDate.substring(0,4) == y ? true : false}
                                                onClick={chooseEndYear}
                                            />
                                            <span>{ y } 年</span>
                                        </label>
                                    ))}    
                                </div>
                            :<></>}
                            { endDateModalSlide === 1 ? 
                            <div className="slider_item">
                                {endMonth.map((m: any) => (
                                    <label key={`month-${m}`}>
                                        <input
                                            type="radio"
                                            name="month"
                                            defaultChecked={m == month}
                                            value={m}
                                            checked={selectedEndDate && selectedEndDate.substring(5) == m ? true : false}
                                            onClick={chooseEndMonth}
                                        />
                                        <span>{ m } 月</span>
                                    </label>
                                ))}    
                            </div>
                            :<></>}
                        </div>
                    </form>
                    <p className="close" onMouseUp={closeEndDateModal}><span className="material-icons">close</span></p>
                </div>
                <div className="overlay" onMouseUp={closeEndDateModal}></div>
            </div>
            :<></>}
      </main>
    );
}
