import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from '../AdminNav';
import './../adminTop.css';
import { RootState } from '../../../redax/reducers/rootReducer';
import Loader from '../../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { fetchAdminApplicantsListRequest } from '../../../redax/actions/adminAplicantsListActions/adminAplicantsListActions';
import { fetchAdminApplicantsCsvRequest } from '../../../redax/actions/adminApplicantsCsvActions/adminApplicantsCsvActions';
import { fetchAdminApplicantsPostRequest } from '../../../redax/actions/adminApplicantsPostActions/adminApplicantsPostActions';
import { fetchLoginRequest } from '../../../redax/actions/loginActions/loginActions';
import { fetchAdminApplicantsDeleteRequest } from '../../../redax/actions/adminApplicantsDeleteActions/adminApplicantsDeleteActions';


const AdminApplicantsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);   
  const params = location.state;
  const applicantsList = useSelector((state: RootState) => state.adminApplicantsList);
  const adminApplicantsCsv = useSelector((state: RootState) => state.adminApplicantsCsv);
  const adminApplicantsDelete = useSelector((state: RootState) => state.adminApplicantsDelete);
  const [loadScreen, setLoadScreen] = useState(true);
  const [isDisolayDeleted, setIsDisplayDeleted] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [applicantList, setApplicantsList] = useState<any>([]);
  const [keyword, setKeyword] = useState(''); 
  const [page, setPage] = useState(1);
  const [downCsvPath, setDownCsvPath] = useState('');
  const navigate = useNavigate();
  const [csvButtonChk, setCsvButtonChk] = useState(false);
  const [btn, setBtn] = useState(false);
  const [role, setRole] = useState(''); 

  useEffect(() => {
    if(localStorage.getItem('deletedList') == '1'){
      setIsDisplayDeleted(true);
      getApplicants(1,'','true');
    } else {
      setIsDisplayDeleted(false);
      getApplicants(1,'','');
    }
    if(queryParams.size == 0){
      setKeyword('');
    }
}, [dispatch]); 





const getApplicants = (page: any, keyword: any, deleted: any) => {
  setLoadScreen(true);
  const data = {
      "page": page,
      "keyword": keyword ? keyword : '',
      "deleted": deleted ? deleted : '',
  }
      dispatch(fetchAdminApplicantsListRequest(data));
      if(applicantsList.pagenation !== undefined) {
          setCurrentPage(applicantsList.pagenation.current_page);
          setTotalPages(applicantsList.pagenation.total_pages);
        }
}

useEffect(() => {
  if (adminApplicantsDelete.pending && btn) {
    // navigate('/admin/applicants_list/');
    window.location.reload();
  } 
}, [adminApplicantsDelete, btn]); 



useEffect(() => {
  if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
    navigate('/admin/login');
  } else {
    if(localStorage.getItem('load') == '1') {
      localStorage.setItem('load', '2');
      window.location.reload();
    }
    dispatch(fetchLoginRequest('', '',''));
  }
}, [navigate, dispatch]);


const loginData = useSelector((state: RootState) => state.login);


useEffect(() => {
  if (loginData.pending) {
    setRole(loginData.login.role_id);
  }
}, [loginData.pending]);


  useEffect(() => {
    if (applicantsList.pending) {
      setApplicantsList(applicantsList.applicants);
        setCurrentPage(applicantsList.pagenation.current_page);
        setTotalPages(applicantsList.pagenation.total_pages);
        setTotalCount(applicantsList.pagenation?.total_count || 1);
        setLoadScreen(false);
        // dispatch(fetchLoginRequest('', '',''));
    }
    // if(applicantsList.error !== null) {
    //   getApplicants(1,'','');
    //   setLoadScreen(false)
    // }
  }, [applicantsList]);



 useEffect(() => {
  if (adminApplicantsCsv.pending && csvButtonChk) {
    setDownCsvPath(adminApplicantsCsv.csv.file);
    if(downCsvPath !== '') {
        window.open(downCsvPath, '_blank', 'noreferrer');
        setCsvButtonChk(false)
    }
}
  }, [adminApplicantsCsv,downCsvPath]);

//   const getCompanies = (page:any) => {
//     setLoadScreen(true);
//     const data = {
//         "page": page,
//         "keyword": keyword ? keyword : '',
//     }
//     if(data != null) {
//         dispatch(fetchRecruitmentsListRequest(data));
//         if(companies.pagenation !== undefined) {
//             setCurrentPage(companies.pagenation.current_page);
//             setTotalPages(companies.pagenation.total_pages);
//           }
//     }
//     setLoadScreen(false)
// }


  const shaping_pagenation = () => {
    if (total_pages === null) return [1]; // Add a check for total_pages being null
    if (total_pages === 1) return [1]; // Add a check for total_pages being null
    
    if (total_pages <= 11) {
      return Array.from({ length: total_pages }, (_, index) => index + 1);
    } else if (current_page < 7) {
      return Array.from({ length: 11 }, (_, index) => index + 1);
    } else if (total_pages - current_page <= 5) {
      const diff_current_total = total_pages - current_page;
      const pagenation_start = current_page - (11 - (diff_current_total + 1));
      const pagenation_end = current_page + diff_current_total;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    } else {
      const pagenation_start = current_page - 5;
      const pagenation_end = current_page + 5;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    }
};

function formatDate (value: any) {
    return dayjs(value).locale(ja).format('YYYY.MM.DD (ddd) HH:mm');
}






const pageDecrement = () => {
  if (page <= 1) {
      setPage(1);
      return;
  }
  setPage((prevPage) => prevPage - 1);
  setLoadScreen(true);
  getApplicants(page-1,keyword,isDisolayDeleted)
}


const updatePage = (p: any) => {
  setPage(p);
  setLoadScreen(true);
  getApplicants(p,keyword,isDisolayDeleted)
}


const pageIncrement = () => {
  if (page >= total_pages) {
    setPage(total_pages);
    return;
  }
  setPage((prevPage) => prevPage + 1);
  setLoadScreen(true);
  getApplicants(page+1,keyword,isDisolayDeleted)
}


function handleClick() {
  getApplicants(1,keyword,'')
}


  function displayDeteled() {
    setLoadScreen(true);
    if(isDisolayDeleted){
        setIsDisplayDeleted(false);
        queryParams.delete('deleted');
        getApplicants(page,keyword,'')
        localStorage.setItem('deletedList', '0');
    } else {
        setIsDisplayDeleted(true);
        getApplicants(1,'','true')
        localStorage.setItem('deletedList', '1');
    }
  }
  function downloadCSV() {
    dispatch(fetchAdminApplicantsCsvRequest());
    setCsvButtonChk(true);
  }
  function formatBirthday (value: any) {
      return dayjs(value).format('YYYY年M月D日')
  }

  function handleInput(e: any) {  
    if(e.target.name == 'keyword') {
        setKeyword(e.target.value);
  }
  
}
  function fetchDelete(id: any) {
    let confirmationMessage = applicantsList.deleted
      ? "この求職者を削除済みから復旧させますか？"
      : "求職者を本当に削除しますか？";

    if (!window.confirm(confirmationMessage)) {
      return;
    }
    setBtn(true);
    // dispatch(fetchAdminApplicantsPostRequest('', 'DELETE', id));
    dispatch(fetchAdminApplicantsDeleteRequest(id));
  }



  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            {isDisolayDeleted ? <h2 className="title">求職者削除一覧 </h2> : <h2 className="title">求職者一覧 </h2> }
            <div className="inputBox">
              <label className="label1">
                <span>求職者名: </span>
                <input name="keyword" type="text" className="inputText" placeholder='求職者名で検索...' onChange={handleInput}/>
                <button className="inputBtn" onClick={handleClick}>検索</button>
                {/* <button onClick={reset}>Reset</button> */}
              </label>
            </div>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ &nbsp;  全{total_count}件</span>
                </div>
                <div>
                <div className='d-flex'>
                    <button  className='csvButton' onClick={downloadCSV}>CSVダウンロード</button>
                    {isDisolayDeleted ? <button  className='deleteBtn' onClick={displayDeteled}>戻る</button> : <button className='deleteBtn' onClick={displayDeteled} v-else><span className='d-flex'><img className='icomImg' src="/assets/img/deleteWhite.svg" alt="" /> 削除リスト</span> </button>}
                    <button className='createBtn' onClick={(e)=>{navigate("/admin/applicants_list/new")}}>新規作成</button>
                </div>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>画像</th>
                      <th>求職者名</th>
                      <th style={{whiteSpace:'nowrap'}}>就職カテゴリー</th>
                      <th>障害区分</th>
                      <th>生年月日 </th>
                      <th>住所</th>
                      <th style={{whiteSpace:'nowrap'}}>希望勤務地1/2</th>
                      <th>登録日/最終ログイン日</th>
                      <th className="text-center">-</th>
                      {isDisolayDeleted && role == '4' ? <th className="text-center"></th> : <></>}
                      

                    </tr>
                  </thead>
                  <tbody>
                  {applicantList.length != 0 && applicantList ?
                  <>
                  {applicantList.map((applicant: any, index: any) => (
                    <tr className='openTable' key={"applicant_" + index}>
                      <td>{index +1}</td>
                      <td className='recruitmentImg'><img src={applicant.face_photo} alt={applicant.face_photo}></img></td>
                      <td>{applicant.family_name} {applicant.first_name}</td>
                      <td>{applicant.new_graduate ? '新卒' : '中途'}</td>
                      <td>{applicant && applicant.handicap_type.map((handy:any, index: any)=> (
                        <>
                          <div key={"handicap_type_"+index}>
                            {handy}
                          </div>
                        </>
                      ))}</td>
                      <td>{formatBirthday(applicant.birthday)}</td>
                      <td>{ applicant.prefecture_name }{ applicant.city_name }{ applicant.address }</td>
                      <td>{ applicant.prefecture_1 ? applicant.prefecture_1 : '設定無し' } /{ applicant.prefecture_2 ? applicant.prefecture_2 : '設定無し' }</td>
                      <td>{ formatDate(applicant.created_at) }/ {applicant.last_login_at ? formatDate(applicant.last_login_at) : 'No Login'}</td>                      
                      <td><button className='btn openBtn' onClick={(e) => {navigate('/admin/applicants_list/' + applicant.id)}}>詳細を見る</button></td> 
                      {isDisolayDeleted && role == '4' ? <td><button className='deleteBtn'  onClick={() => fetchDelete(applicant.id)}>削除</button></td>   : <></>}

                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminApplicantsList;
