import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import { fetchAdminInvoiceRequest } from '../../redax/actions/adminInvoiceActions/adminInvoiceActions';
import dayjs from 'dayjs';
import { fetchAdminInvoicePostRequest } from '../../redax/actions/adminInvoicePostActions/adminInvoicePostActions';
import Loader from '../loader/loader';

const AdminInvoiceCheck = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const [invoicesList, setInvoicesList] = useState<any>([]);
    const [years, setYears] = useState<any[]>([]);
    const [months, setMonths] = useState<any[]>([]); 
    const [days, setDays] = useState<any[]>([]); 
    const [depositYear, setDepositYear] = useState<any>(null);
    const [depositMonth, setDepositMonth] = useState<any>(null);
    const [depositDate, setDepositDate] = useState<any>();
    let monthly = 13;     
    let nowDate = dayjs().format('YYYY-MM-DD');
    const invoices = useSelector((state:RootState) => state.adminInvoice);
    const postInvoices = useSelector((state:RootState) => state.adminInvoicePost);
    let yyyy = nowDate.substring(0,4);
    let mm = nowDate.length == 10 ? nowDate.substring(5,7) : nowDate.substring(6,7);

    let arr : any = [];
    const [loadScreen, setLoadScreen] = useState(true);
    const [putResponse, setPutResponse] = useState(false);
    useEffect(() => {
        setDepositYear(nowDate.substring(0,4));
        setDepositDate(nowDate.substring(8,10));
        if(nowDate.length == 10){
            setDepositMonth(nowDate.substring(5,7));
        } else {
            setDepositMonth(nowDate.substring(6,7));
        }
        const data = {
            "year": Number(yyyy),
            "month": Number(mm)
        }
        if(data != null) {
            dispatch(fetchAdminInvoiceRequest(data));
        }
    }, [dispatch]); 

    useEffect(() => {
        if(invoices.pending) {
            setLoadScreen(false);
            setInvoicesList(invoices.invoiceList.invoices);
        } else if(invoices.error != null) {
            navigate('/error?code=500');
        }
        getYears();
    }, [invoices]);

    const getYears = () => {
        const goBackYears = 5;
        const currentYear = dayjs().year();
        let startYear = (currentYear + 3) - goBackYears;
        const yearsCal = [];
        for (let i = 1; i <= goBackYears; i++) {
            yearsCal.push(startYear);
            startYear++;
        }
        setYears(yearsCal);
        const monthAll = [];
        for (let i = 1; i < monthly; i++) {
            monthAll.push(i);
        }
        setMonths(monthAll);
    };
    
    const findValueYear = (value: any) => {
        return value && value !== undefined ? value+"年度" : value+'年';
    };

    const findValueMonth = (value: any) => {
        return value && value !== undefined ? value+"月" : value+'月';
    };

    function putlist() {
        setLoadScreen(true);
        let checkIds = [];
        let unCheckIds = [];
        for(let i = 0; i < invoicesList.length; i++) {
            if(invoicesList[i].is_paid == true) {
                checkIds.push(invoicesList[i].invoice_id);
            } else {
                unCheckIds.push(invoicesList[i].invoice_id);
            }
        }
        const params = {
            "invoices_ids": checkIds,
            "uncheck_invoice_ids": unCheckIds
        }
        setPutResponse(true);
        dispatch(fetchAdminInvoicePostRequest(params));
    }

    useEffect(() => {
        if(postInvoices.pending && putResponse) {
            // localStorage.setItem('adminInvoiceYear', depositYear);
            // localStorage.setItem('adminInvoiceMonth', depositMonth);
            window.location.reload();   
            setPutResponse(false);
            setLoadScreen(false);    
            alert('入金チェックを更新しました。');   
        } else if(postInvoices.error != null) {
            navigate('/error?code=500');
        }    
    });

    function allCheck() {
        var isAllCheck = true;
        for(let i = 0; i < invoicesList.length; i++) {
            arr.push(invoicesList[i]);
        }
        for(let ch = 0; ch < arr.length; ch++) {
            arr[ch].is_paid = isAllCheck;
        }
        isAllCheck = false;
        setInvoicesList(arr);
    }

    function getlist(e: any) {  
        let y, m;
        if(e.target.name == 'year') {
            setDepositYear(e.target.value);
            y = e.target.value;
        }
        if(e.target.name == 'month') {
            setDepositMonth(e.target.value);
            m = e.target.value;
        }
        if(y == null) {
            y = depositYear;
        } else if(m == null) {
            m = depositMonth;
        }
        const data = {
            year: y,
            month: m
        }
        if(data != null) {
            dispatch(fetchAdminInvoiceRequest(data));
        } 
    }

    function checkboxHandler(e:any) {
        const { value, checked } = e.target;
        let arr1 = [];
        for(let i = 0; i < invoicesList.length; i++) {
            arr1.push(invoicesList[i]);
        }
        for(let ch = 0; ch < arr1.length; ch++) {
            if(arr1[ch].invoice_id == value) {
                arr1[ch].is_paid = checked;
            }
        }
        setInvoicesList(arr1);
    }


  return (
    <main id='adminHome'>
        <AdminNav/>
        {loadScreen ? <Loader></Loader> : <></>}
        <section className='sectionScouts' style={{width:"100%"}}>
            <br />
            <div style={{width:"100%",padding:"0px 4%"}}>
                <h1 className='adminH1'>入金管理</h1>
                <hr style={{height:"2px", width:"100%", background:"#00A0E9"}}/>
                <br />
                <div style={{borderRadius:"5px", border:"#C4C4C4 solid 1px", padding:"2%", width:"100%", margin:"auto", display:"flex", justifyContent:"space-between"}}>
                    <div style={{display:"flex" , justifyContent:"space-evenly"}}>
                        <h3 className='adminH2' style={{margin:"0", marginRight:"20px"}}>入金月</h3>                        
                        <select className='adminNSelect' name="year" id="" onChange={(e) => (getlist(e))}>
                            <option value=""  disabled>選択してください</option>
                            {years.map((option: any, index: any) => (
                                <option key={index} value={option} selected={option == depositYear} >
                                    {findValueYear(option)}
                                </option>
                            ))}
                        </select> 
                        <select className='adminNSelect' name="month" id="" onChange={(e) => (getlist(e))}>
                            <option className='adminPopupOption' value="" disabled>選択してください</option>
                            {months.map((option: any, index: any) => (
                                <option key={index} value={option} selected={option == depositMonth}>
                                    {findValueMonth(option)}
                                </option>
                            ))}
                        </select> 
                    </div>
                    <div>
                        <button className='nBtn' onClick={allCheck}>全チェック</button>
                    </div>
                </div>
                <h1 className='adminH1' style={{margin:"10px 0"}}>{depositYear + '年'+ depositMonth + '月'}</h1>
                <table className='adminTable' style={{width: "100%",}}>
                    <thead className='adminThead' style={{ width: "100%", position: "sticky", top: "0" }}>
                        <tr style={{width:"100%" , wordBreak:"break-word", }}>
                        <th className='adminTheadTh'>顧客名</th>
                        <th className='adminTheadTh'>振込額</th>
                        <th className='adminTheadTh'>入金日チェック</th>
                        <th className='adminTheadTh'>詳細</th>
                        </tr>
                    </thead>
                    {invoicesList.map((data:any) => (
                        <tbody className='adminTbody' style={{position:"inherit"}}>
                            <tr>
                                <td>{data.company_name}</td>
                                <td>¥{(data.total_price).toLocaleString()}</td>
                                <td>{depositYear + '年'+ depositMonth + '月' + depositDate + '日'}
                                    <input checked={data.is_paid} value={data.invoice_id} type="checkbox" onChange={checkboxHandler}/>
                                </td>
                                {/* <td> <button className='invoiceBtn'  onClick={()=> navigate('/admin/invoice_detail/' + data.invoice_id)}>詳細</button></td> */}
                                <td> <button className='invoiceBtn'  onClick={()=> navigate('/admin/invoice_plan/' + data.invoice_id)}>詳細</button></td>
                            </tr>   
                        </tbody>
                    ))} 
                </table>
                <br />
                <div style={{textAlign:"center"}}>
                <button className='scoutsBtn' onClick={putlist}>入金チェック完了</button>
                </div>
            </div>
      </section>
    </main>
  )
}

export default AdminInvoiceCheck
